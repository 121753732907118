import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { constants } from "@qgiv/core-js";
import {
    StandardPaymentMethods,
    getStandardPaymentMethodProps,
} from "@qgiv/core-donor";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectHasActiveFulfillment } from "../../../../redux/slices/promiseTransactionSlice";
import { selectDonorDetails } from "../../../../redux/slices/donationDetailsSlice";
import ConnectedCCFieldsDisplay from "./ConnectedCCFieldsDisplay";
import ConnectedBankFields from "./ConnectedBankFields";

const ConnectedStandardPaymentMethods = ({
    controlId,
    hasToggledPaymentMethodView,
}) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
            PaymentType,
        },
    } = constants;

    const formikContext = useFormikContext();

    const {
        values: { Payment_Type },
    } = formikContext;

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );

    const {
        options: { creditTabLabel = "Credit Card", echeckTabLabel = "Bank" },
    } = control;

    const formSettings = useSelector(selectAllFormSettings);
    const { paymentData = {} } = formSettings;
    const { enableAchPayments, enableCCPayments } = paymentData;

    const donorDetails = useSelector(selectDonorDetails);
    const { Email = "" } = donorDetails;

    const acceptedPayments = {};
    const creditTab = <ConnectedCCFieldsDisplay />;
    const echeckTab = <ConnectedBankFields controlId={controlId} />;

    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    if (enableCCPayments) {
        acceptedPayments.credit = {
            label: creditTabLabel,
            content: creditTab,
            active: Payment_Type === PaymentType.CREDIT,
        };
    }

    if (enableAchPayments) {
        acceptedPayments.echeck = {
            label: echeckTabLabel,
            content: echeckTab,
            active: Payment_Type === PaymentType.ECHECK,
        };
    }

    const standardPaymentMethodPropsOptions = {
        acceptedPayments,
        control,
        formikContext,
        formSettings,
        hasToggledPaymentMethodView,
        registrationDetails: { Email },
        hasActiveFulfillment,
        paymentData,
    };

    const { instantPaymentToggleProps, numberOfStandardPaymentMethods } =
        getStandardPaymentMethodProps(standardPaymentMethodPropsOptions);

    return (
        <StandardPaymentMethods
            instantPaymentToggleProps={instantPaymentToggleProps}
            numberOfStandardPaymentMethods={numberOfStandardPaymentMethods}
        />
    );
};

ConnectedStandardPaymentMethods.propTypes = {
    controlId: PropTypes.number.isRequired,
    hasToggledPaymentMethodView: PropTypes.bool.isRequired,
};

export default ConnectedStandardPaymentMethods;
