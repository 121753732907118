import { constants } from "@qgiv/core-js";
import { createSelector } from "@reduxjs/toolkit";
import { selectAllEventSettingsSelector } from "../eventSettingsSelectors/eventSettingsSelectors";

export const selectAllDonationSettingsSelector = (state) =>
    state.donationSettings;

export const selectEnableAbandonedGiftsSelector = (state) =>
    state.donationSettings.enableAbandonedGifts;

export const selectRecurringFrequencyByValueSelector = (state, value) => {
    const { allFrequencies } = selectAllDonationSettingsSelector(state);
    const selectedRecurringFrequency =
        allFrequencies.find((frequency) => frequency.value === value) || {};
    return selectedRecurringFrequency;
};

// Manual Matching is currently limited to P2P only. These settings live in hn_eventsettings which will not have properties
// when Donation Form is available on other products (Auctions may be the exception)
export const selectMatchingGiftSettingsSelector = createSelector(
    [
        (state) => selectAllDonationSettingsSelector(state),
        (state) => selectAllEventSettingsSelector(state),
    ],
    (donationSettings, eventSettings) => {
        const {
            ENUMS: { MatchingType },
        } = constants;
        const {
            enableMatching = false,
            matchingTokens = {},
            thirdPartyMatching,
        } = donationSettings;

        const matchingGiftSettings = {
            enableMatching,
            matchingTokens,
            thirdPartyMatching,
            matchingRatio: eventSettings?.matchingRatio || "",
            matchingType: eventSettings?.matchingType || MatchingType.AUTOMATIC,
        };

        return matchingGiftSettings;
    },
);
