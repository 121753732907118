import { configureStore } from "@reduxjs/toolkit";
import abandonedGiftReducer from "./slices/abandonedGiftSlice";
import activeInjectionsReducer from "./slices/activeInjectionsSlice";
import appSettingsReducer from "./slices/appSettingsSlice";
import cmsSettingsReducer from "./slices/cmsSettingsSlice";
import configReducer from "./slices/configSettingsSlice";
import currentReceiptReducer from "./slices/currentReceipt";
import dedicationSettingsReducer from "./slices/dedicationSettingsSlice";
import donationDetailsReducer from "./slices/donationDetailsSlice";
import donationSettingsReducer from "./slices/donationSettingsSlice";
import donorAccountReducer from "./slices/donorAccountSlice";
import eventSettingsReducer from "./slices/eventSettingsSlice";
import eventListSettingsReducer from "./slices/eventListSettingsSlice";
import fieldsReducer from "./slices/fieldsSlice";
import formSettingsReducer from "./slices/formSettingsSlice";
import internalSettingsReducer from "./slices/internalSettingsSlice";
import promiseTransactionReducer from "./slices/promiseTransactionSlice";
import receiptsReducer from "./slices/receiptsSlice";
import restrictionSettingsReducer from "./slices/restrictionSettingsSlice";
import skinSettingsReducer from "./slices/skinSettingsSlice";
import smsDataReducer from "./slices/smsDataSlice";
import urlShortcutsReducer from "./slices/urlShortcutsSlice";

const enableDevtools = window.QGIV && window.QGIV.ENABLE_REDUX_DEVTOOLS;

const devTools = enableDevtools
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : false;

const storeConfigObject = {
    devTools,
    reducer: {
        abandonedGift: abandonedGiftReducer,
        activeInjections: activeInjectionsReducer,
        appSettings: appSettingsReducer,
        cmsSettings: cmsSettingsReducer,
        config: configReducer,
        currentReceipt: currentReceiptReducer,
        dedicationSettings: dedicationSettingsReducer,
        donationDetails: donationDetailsReducer,
        donationSettings: donationSettingsReducer,
        donorAccount: donorAccountReducer,
        eventSettings: eventSettingsReducer,
        eventListSettings: eventListSettingsReducer,
        fields: fieldsReducer,
        formSettings: formSettingsReducer,
        internalSettings: internalSettingsReducer,
        promiseTransaction: promiseTransactionReducer,
        receipts: receiptsReducer,
        restrictionSettings: restrictionSettingsReducer,
        skinSettings: skinSettingsReducer,
        smsData: smsDataReducer,
        urlShortcuts: urlShortcutsReducer,
    },
};

export default configureStore({
    ...storeConfigObject,
});
