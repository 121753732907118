import { createSlice } from "@reduxjs/toolkit";
import { abandonedGift as initialState } from "../initialState";

const getAbandonedGiftInitialState = () => ({
    ...initialState,
    acceptedReminder: false,
    closedElementFromApp: false,
    First_Name: "",
    otherAmountData: {},
    reminderData: {},
});

const slice = createSlice({
    name: "abandonedGift",
    initialState: getAbandonedGiftInitialState(),
    reducers: {
        setAbandonedGiftReminderData: (state, action) => ({
            ...state,
            ...action.payload,
        }),
        setAbandonedGiftOtherAmountData: (state, action) => ({
            ...state,
            otherAmountData: {
                ...action.payload,
            },
        }),
        setAbandonedGiftDataFirstName: (state, action) => ({
            ...state,
            First_Name: action.payload,
        }),
        setAbandonedGiftDataClosedElementFromApp: (state, action) => ({
            ...state,
            closedElementFromApp: action.payload,
        }),
    },
});

export const {
    setAbandonedGiftDataClosedElementFromApp,
    setAbandonedGiftDataFirstName,
    setAbandonedGiftOtherAmountData,
    setAbandonedGiftReminderData,
} = slice.actions;

export const selectAbandonedGiftData = (state) => state.abandonedGift;

export const selectAbandonedGiftOtherAmountData = (state) =>
    state.abandonedGift.otherAmountData;

export const selectAbandonedGiftToken = (state) =>
    state.abandonedGift.qgiv_abandoned_gift;

export const selectAbandonedGiftClosedElementFromApp = (state) =>
    state?.abandonedGift?.closedElementFromApp;

export default slice.reducer;
