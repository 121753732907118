import React from "react";
import InnerHTML from "dangerously-set-html-content";

/**
 * @param {object} props - The component props.
 * @param {boolean} props.allowRerender - InnerHTML prop to allow a rerender. The default is false.
 * @param {string} props.skinInjection - The HTML content to be injected.
 * @returns {React.ReactElement|null} The rendered component or null if no content is provided.
 */
const SkinInjection = ({ allowRerender = true, skinInjection }) =>
    skinInjection ? (
        <InnerHTML html={skinInjection} allowRerender={allowRerender} />
    ) : null;

export default SkinInjection;
