import React from "react";
import { useSelector } from "react-redux";
import { StepChangeAnalyticsEvents } from "@qgiv/core-donor";
import { getDataLayerOptions } from "@qgiv/donation-form";
import {
    selectCurrentPage,
    selectHasSentStartAnalyticsEvents,
    selectHasSubmittedForm,
} from "../../../redux/slices/appSettingsSlice";
import { selectConfig } from "../../../redux/slices/configSettingsSlice";
import { selectDedicationSettings } from "../../../redux/slices/dedicationSettingsSlice";
import { selectAllDonationSettings } from "../../../redux/slices/donationSettingsSlice";
import {
    selectAllDonationDetails,
    selectSubtotalGift,
} from "../../../redux/slices/donationDetailsSlice";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import {
    selectAllDisplayableCustomFields,
    selectDetailsPageFieldsData,
} from "../../../redux/slices/fieldsSlice";
import { selectReceipt } from "../../../redux/slices/receiptsSlice";
import { selectRestrictionSettings } from "../../../redux/slices/restrictionSettingsSlice";
import { selectSmsData } from "../../../redux/slices/smsDataSlice";
import { sendStepChangeAnalyticsEvents } from "../../../utility";

const ConnectedStepChangeAnalyticsEvents = () => {
    // Get data from state
    const allDisplayableCustomFields = useSelector(
        selectAllDisplayableCustomFields,
    );
    const config = useSelector(selectConfig);
    const currentPage = useSelector(selectCurrentPage);
    const dedicationSettings = useSelector(selectDedicationSettings);
    const donationDetails = useSelector(selectAllDonationDetails);
    const donationSettings = useSelector(selectAllDonationSettings);
    const formSettings = useSelector(selectAllFormSettings);
    const hasSentStartAnalyticsEvents = useSelector(
        selectHasSentStartAnalyticsEvents,
    );
    const hasSubmittedForm = useSelector(selectHasSubmittedForm);
    const receipt = useSelector(selectReceipt);
    const subtotal = useSelector(selectSubtotalGift);
    const restrictionSettings = useSelector(selectRestrictionSettings);
    const smsData = useSelector(selectSmsData);
    const detailsPageFieldsData = useSelector((state) =>
        selectDetailsPageFieldsData(state, subtotal),
    );

    // Convert data into keys that are needed
    const dataLayerOptions = getDataLayerOptions({
        allDisplayableCustomFields,
        config,
        dedicationSettings,
        detailsPageFieldsData,
        donationDetails,
        donationSettings,
        formSettings,
        receipt,
        restrictionSettings,
        smsData,
    });

    const props = {
        currentPage,
        dataLayerOptions,
        hasSentStartAnalyticsEvents,
        hasSubmittedForm,
        sendStepChangeAnalyticsEvents,
    };

    return <StepChangeAnalyticsEvents {...props} />;
};

export default ConnectedStepChangeAnalyticsEvents;
