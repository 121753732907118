import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { isDisplayable, constants } from "@qgiv/core-js";
import { isVisible, getFieldIsVisibleBasedOnConditionals } from "../../utility";
import QFieldGroup from "../QField/QFieldGroup";

const WidgetFieldGroup = (props) => {
    const {
        control: {
            options: { id },
        },
        dispatchSetAbandonedGiftDataFirstName,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setValues,
        settings,
        touched,
        values,
        privacyFieldGroup = {},
    } = props;
    const {
        cms,
        ENUMS: {
            EntityType: { FIELD_GROUP },
        },
    } = constants;
    const {
        activePromiseTransaction,
        allFieldsAndGroups,
        config: { currentDisplay },
        conditionalLogicOptions = {},
        useNewConditionalLogicFunctions = false,
        donationAmountFromRedux = 0,
    } = settings;
    const field = allFieldsAndGroups.find((field) => field.id === id);
    const qFieldGroupProps = {
        donationAmountFromRedux,
        dispatchSetAbandonedGiftDataFirstName,
        errors,
        field,
        handleBlur,
        handleChange,
        setFieldValue,
        setValues,
        settings,
        touched,
        values,
        cms,
        privacyFieldGroup,
    };
    const { options } = field;
    // Grab conditions safely if data from BE is missing.
    const conditions = field?.conditions?.[0] || {};

    const displayable = isDisplayable(options, currentDisplay);
    let visible = true;
    // Prevent displaying custom field groups on invoice fulfillment forms
    if (activePromiseTransaction && Number(field.type) === FIELD_GROUP) {
        visible = false;
    } else if (Object.keys(conditions).length > 0) {
        visible = useNewConditionalLogicFunctions
            ? getFieldIsVisibleBasedOnConditionals({
                  condition: conditions,
                  allFieldsAndGroups,
                  options: conditionalLogicOptions,
                  values,
              })
            : isVisible(conditions, values, allFieldsAndGroups);
    }
    return cms || (displayable && visible) ? (
        <div
            className={cx(
                "qg-vendor-widget-field-group",
                "widget-field-group",
                "grid",
                !(displayable && visible) && "-disabled",
            )}
        >
            <div className="col col--12 col--no-top-gutter">
                <QFieldGroup {...qFieldGroupProps} />
            </div>
        </div>
    ) : null;
};

WidgetFieldGroup.propTypes = {
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    control: PropTypes.shape({
        options: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
        }),
    }),
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    settings: PropTypes.shape({
        activePromiseTransaction: PropTypes.bool,
        allFieldsAndGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
        config: PropTypes.shape({
            currentDisplay: PropTypes.number.isRequired,
        }),
    }).isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
};
export default WidgetFieldGroup;
