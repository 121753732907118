import React from "react";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { SkinInjection } from "@qgiv/core-donor";
import { selectSkinSettings } from "../../../redux/slices/skinSettingsSlice";
import { selectCurrentPage } from "../../../redux/slices/appSettingsSlice";

const ConnectedSkinInjection = () => {
    const {
        ENUMS: {
            CmsPageStandardizer: { CONFIRMATION },
        },
    } = constants;
    const skinSettings = useSelector(selectSkinSettings);
    const currentPage = useSelector(selectCurrentPage);

    const { formInj = "", receiptInj = "" } = skinSettings;

    const hasSkin = Object.keys(skinSettings).length > 0;

    const skinToLoadByPage =
        currentPage === CONFIRMATION ? receiptInj : formInj;

    const skinInjection = hasSkin ? skinToLoadByPage : null;

    return <SkinInjection skinInjection={skinInjection} allowRerender />;
};

export default ConnectedSkinInjection;
