import { constants, sortArrayAlphabeticallyByKey } from "@qgiv/core-js";

const {
    ENUMS: { EntityType },
} = constants;

const iconMap = {
    participant: "user-solid",
    team: "users-duotone",
    classification: "sitemap-duotone",
};

export const getRecipientIconGlyph = (type) => {
    const recipientIconGlyph = iconMap[type];

    return recipientIconGlyph;
};

export const reduceCaptainNamesToString = (captainsArray) => {
    const captainNamesString = captainsArray.reduce(
        (existingString, currentCaptain, index) => {
            const { firstName, lastName } = currentCaptain;
            const captainName = `${firstName} ${lastName}`;
            if (index === 0) {
                return captainName;
            }
            const newString = `${existingString}, ${captainName}`;
            return newString;
        },
        "",
    );
    return captainNamesString;
};

const getMappedParticipant = (participant) => ({
    ...participant,
    // need to ensure title is a string because we are going to be running
    // string methods on it.
    title: `${participant.firstName} ${participant.lastName}`,
    key: `participant-${participant.id}`,
    type: "participant",
    // value/type might change for this eval
    hasTeam: participant.teamId !== 0,
    iconGlyph: getRecipientIconGlyph("participant"),
});

const getMappedTeam = (team) => ({
    ...team,
    // need to ensure title is a string because we are going to be running
    // string methods on it.
    title: `${team.teamName}`,
    key: `team-${team.id}`,
    type: "team",
    iconGlyph: getRecipientIconGlyph("team"),
    hasCaptain: team?.captains?.length > 0,
    ...(team?.captains?.length > 0 && {
        // there can be more than one!
        captainName: reduceCaptainNamesToString(team.captains),
    }),
});

const getMappedClassification = (classification) => ({
    ...classification,
    // need to ensure title is a string because we are going to be running
    // string methods on it.
    title: `${classification.classificationName}`,
    key: `classification-${classification.id}`,
    type: "classification",
    iconGlyph: getRecipientIconGlyph("classification"),
    hasCaptain: classification?.captains?.length > 0,
    ...(classification?.captains?.length > 0 && {
        // there can be more than one!
        captainName: reduceCaptainNamesToString(classification.captains),
    }),
});

const getMappedEvent = (eventRecipientData) => eventRecipientData;

export const getMappedRecipient = (recipient) => {
    const { preselectedRecipientType } = recipient;
    switch (preselectedRecipientType) {
        case EntityType.REGISTRATION:
            return getMappedParticipant(recipient);
        case EntityType.TEAM:
            return getMappedTeam(recipient);
        case EntityType.CLASSIFICATION:
            return getMappedClassification(recipient);
        case EntityType.FORM:
            return getMappedEvent(recipient);
        default:
            return getMappedParticipant(recipient);
    }
};

export const mapParticipantsForList = (participants) =>
    participants.map(getMappedParticipant);

export const mapTeamsForList = (teams) => teams.map(getMappedTeam);

// I am assuming captain data will come in the same shape as it does for teams
export const mapClassificationsForList = (classifications) =>
    classifications.map(getMappedClassification);

export const handleSuccessfulSearchCall = ({
    data,
    donationRecipientSettings,
    setSearchList,
    setListLoading,
}) => {
    const {
        results: { classifications, participants, teams },
    } = data;

    const {
        enableClassificationForDonation,
        enableDonationToParticipant,
        enableDonationToTeam,
    } = donationRecipientSettings;

    // map through entities to make sure they have the correct keys
    const mappedParticipants =
        enableDonationToParticipant && participants
            ? mapParticipantsForList(participants)
            : [];

    const mappedTeams =
        enableDonationToTeam && teams ? mapTeamsForList(teams) : [];

    const mappedClassifications =
        enableClassificationForDonation && classifications
            ? mapClassificationsForList(classifications)
            : [];

    // combine output to one array
    const fullList = [
        ...mappedParticipants,
        ...mappedTeams,
        ...mappedClassifications,
    ];

    // sort by title key alphabetically
    const sortedFullList = sortArrayAlphabeticallyByKey(fullList, "title");

    setSearchList(sortedFullList);
    setListLoading(false);
};

// -------------------------------------------------------------------------
// MARK: Selected Recipient Helpers
// -------------------------------------------------------------------------

export const getPercentOfGoalWithoutDonation = ({
    totalRaised,
    fundraisingGoal,
}) => Math.round((totalRaised / fundraisingGoal) * 100);

export const getPercentOfDonationTowardsGoal = ({ total, fundraisingGoal }) =>
    Math.floor((total / fundraisingGoal) * 100);

export const getPercentOfGoalWithDonation = ({
    percentOfGoalWithoutDonation,
    percentOfDonationTowardsGoal,
}) => percentOfGoalWithoutDonation + percentOfDonationTowardsGoal;

export const getHasReachedGoal = ({ total, totalRaised, fundraisingGoal }) =>
    total + totalRaised >= fundraisingGoal;

export const getHasReachedGoalOnPageLoad = ({ totalRaised, fundraisingGoal }) =>
    totalRaised >= fundraisingGoal;

export const getPercentOfGoalToDisplayOnThermometer = ({
    percentOfGoalWithDonation,
    isSmallScreen,
}) => {
    if (percentOfGoalWithDonation >= 100) {
        return 100;
    }
    // We want to have a starting thermometer width even if the totalRaised is 0
    // this means we have to use a ratio of what the donation amount will be to
    // update the width of thermometer. Mobile is going to use a 94 / 100 ratio.
    // Desktop is going to use a 96 / 100 ratio.
    const ratioBasis = isSmallScreen ? 94 : 96;
    const remainder = 100 - ratioBasis;
    const magicRatio = ratioBasis / 100;
    const percentageToFill = percentOfGoalWithDonation * magicRatio;
    return percentageToFill + remainder;
};

export const getGoalAndDonationDataForSelectedRecipient = ({
    isSmallScreen,
    recipient,
    total,
}) => {
    const { fundraisingGoal, totalRaised } = recipient;
    const hasValidFundraisingGoal = fundraisingGoal > 0;

    const percentOfGoalWithoutDonation = hasValidFundraisingGoal
        ? getPercentOfGoalWithoutDonation({ totalRaised, fundraisingGoal })
        : 0;

    const percentOfDonationTowardsGoal = hasValidFundraisingGoal
        ? getPercentOfDonationTowardsGoal({ total, fundraisingGoal })
        : 0;

    const percentOfGoalWithDonation = getPercentOfGoalWithDonation({
        percentOfGoalWithoutDonation,
        percentOfDonationTowardsGoal,
    });

    const hasReachedGoal = hasValidFundraisingGoal
        ? getHasReachedGoal({
              total,
              totalRaised,
              fundraisingGoal,
          })
        : false;

    const hasReachedGoalOnPageLoad = hasValidFundraisingGoal
        ? getHasReachedGoalOnPageLoad({ totalRaised, fundraisingGoal })
        : false;

    const percentOfGoalToDisplayOnThermometer =
        getPercentOfGoalToDisplayOnThermometer({
            isSmallScreen,
            percentOfGoalWithDonation,
        });

    return {
        hasValidFundraisingGoal,
        percentOfGoalWithoutDonation,
        percentOfDonationTowardsGoal,
        percentOfGoalWithDonation,
        hasReachedGoal,
        hasReachedGoalOnPageLoad,
        percentOfGoalToDisplayOnThermometer,
    };
};

export const getDonationMessageWithSelectedRecipient = ({
    helpText = "",
    selectedRecipient = {},
    eventTitle,
    replaceTag,
}) => {
    if (
        Object.keys(selectedRecipient).length === 0 ||
        !helpText ||
        helpText === ""
    ) {
        return "";
    }
    if (!helpText.includes(replaceTag)) {
        return helpText;
    }
    // classifications, teams, and participants should all have title key as selected recipient
    // default text has the possessive 's after the replace tag, so we shouldn't have to worry about that here.
    if (selectedRecipient?.title) {
        return helpText.replace(replaceTag, `${selectedRecipient.title}`);
    }
    return helpText.replace(replaceTag, `${eventTitle}`);
};

// -------------------------------------------------------------------------
// MARK: Filter Button and Result Message Helpers
// -------------------------------------------------------------------------

/**
 * @function
 * @param {string} classificationLabel - The classification label from event settings.
 * @param {object} options - The cms options object.
 * @returns {object} Formatted classification label or "Other".
 */
export const getFormattedClassificationLabel = (
    classificationLabel,
    options,
) => {
    // Options for the search filter classifications label needs to be formatted
    // with the classification label from the event settings. All other options
    // are passed through as is.
    const { searchFilterClassificationsLabel = "%ClassificationLabel%" } =
        options;

    const formattedFilterClassificationLabel =
        searchFilterClassificationsLabel && classificationLabel
            ? searchFilterClassificationsLabel.replace(
                  "%ClassificationLabel%",
                  classificationLabel,
              )
            : "Other";

    return formattedFilterClassificationLabel;
};

/**
 * @function
 * @param {object} params - The parameters object.
 * @param {string} params.classificationLabel - The classification label from event settings.
 * @param {object} params.donationRecipientSettings - The donation recipient settings.
 * @param {object} params.options - The cms options object.
 * @returns {Array} A map of filter button with types, label and iconGlyph.
 */
export const getFilterButtonTypeMap = ({
    classificationLabel,
    donationRecipientSettings,
    options,
}) => {
    const {
        searchFilterParticipantsLabel = "People",
        searchFilterTeamsLabel = "Teams",
    } = options;

    const formattedFilterClassificationLabel = getFormattedClassificationLabel(
        classificationLabel,
        options,
    );

    const filterButtonTypeMap = [];
    if (donationRecipientSettings.enableDonationToParticipant) {
        filterButtonTypeMap.push({
            type: EntityType.REGISTRATION,
            label: searchFilterParticipantsLabel,
            iconGlyph: "user-solid",
        });
    }
    if (donationRecipientSettings.enableDonationToTeam) {
        filterButtonTypeMap.push({
            type: EntityType.TEAM,
            label: searchFilterTeamsLabel,
            iconGlyph: "users-duotone",
        });
    }
    if (donationRecipientSettings.enableClassificationForDonation) {
        filterButtonTypeMap.push({
            type: EntityType.CLASSIFICATION,
            label: formattedFilterClassificationLabel,
            iconGlyph: "sitemap-duotone",
        });
    }
    return filterButtonTypeMap;
};

/**
 * @function
 * @param {Array} activeFilterButtons - An array of all filter buttons that are currently active.
 * @param {Array} defaultAllSearchCategories - An array of all enabled categories. Represents all and none selected.
 * @param {Array} filterButtonTypeMap - An array of objects representing the filter button types, iconGlyphs and labels.
 * @returns {Array} Array with the labels of the active filter buttons.
 */
export const getFilterButtonsLabels = (
    activeFilterButtons,
    defaultAllSearchCategories,
    filterButtonTypeMap,
) => {
    // Note: All and no search filters active should display the
    // all enabled search categories. These arrays are just the types.
    const currentActiveFilterCategories =
        activeFilterButtons.length > 0
            ? activeFilterButtons
            : defaultAllSearchCategories;

    // Use the active filter buttons to filter the button data map
    const updatedFilterButtonTypeMap = filterButtonTypeMap.filter(
        (filterButton) =>
            currentActiveFilterCategories.includes(filterButton.type),
    );

    const filterButtonLabels = updatedFilterButtonTypeMap.map((filterButton) =>
        filterButton?.label?.toLowerCase(),
    );

    return filterButtonLabels;
};

/**
 * @function
 * @param {Array} activeFilterButtons - An array of all filter buttons that are currently active.
 * @param {Array} defaultAllSearchCategories - An array of all enabled categories. Represents all and none selected.
 * @param {Array} filterButtonTypeMap - An array of objects representing the filter button types, iconGlyphs and labels.
 * @returns {string} A string representing the results currently being filtered.
 */
export const getResultsString = (
    activeFilterButtons,
    defaultAllSearchCategories,
    filterButtonTypeMap,
) => {
    const filterButtonLabels = getFilterButtonsLabels(
        activeFilterButtons,
        defaultAllSearchCategories,
        filterButtonTypeMap,
    );

    const resultString = filterButtonLabels.reduce(
        (accumulatedLabel, currentLabel, currentLabelIndex) => {
            const numberOfLabels = filterButtonLabels.length;
            let totalLabel = accumulatedLabel;

            if (currentLabelIndex === 0) {
                totalLabel += currentLabel;
                return totalLabel;
            }
            if (currentLabelIndex === numberOfLabels - 1) {
                totalLabel += `${numberOfLabels > 2 ? "," : ""} and ${currentLabel}`;
            } else {
                totalLabel += `, ${currentLabel}`;
            }
            return totalLabel;
        },
        "",
    );

    return resultString;
};

/**
 * @function
 * @param {object} donationRecipientSettings
 * @returns {Array} An array of default search categories. Since all and none should
 * both be an array of all enabled categories, this function will return an array of
 * all enabled categories.
 */
export const getDefaultAllSearchCategories = (donationRecipientSettings) => {
    const {
        enableClassificationForDonation,
        enableDonationToParticipant,
        enableDonationToTeam,
    } = donationRecipientSettings;

    const defaultCategories = [];
    if (enableDonationToParticipant) {
        defaultCategories.push(EntityType.REGISTRATION);
    }
    if (enableDonationToTeam) {
        defaultCategories.push(EntityType.TEAM);
    }
    if (enableClassificationForDonation) {
        defaultCategories.push(EntityType.CLASSIFICATION);
    }
    return defaultCategories;
};
