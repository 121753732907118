/**
 * @typedef DonorAccountActionsSettings
 * @property {boolean} enableDonorLogins form setting for allowing donor accounts to be used on the form
 * @property {object} [donorAccount]
 * @property {boolean} hasSelectedRecurringDonation bool that is true if user selects recurring
 * @property {boolean} isLoggedIn bool that let's us know if a donor is logged in
 */

/**
 * @public
 * @function getDonorAccountActionsInitialValues
 * @description function that returns initial values for create donor account password fields
 * @param {DonorAccountActionsSettings} settings settings from state to determine initial values
 * @returns {object} initial values for creating donor accounts
 */
const getDonorAccountActionsInitialValues = ({
    enableDonorLogins,
    donorAccount,
    hasSelectedRecurringDonation,
    isLoggedIn,
}) => {
    // Recurring donations that are not logged in automatically store the payment
    // method.  This check is hidden but need a value of true
    // Recurring donations also have Create_Account_Password checked by default. This
    // input only displays if donor is not logged in
    const isRecurringAndNotLoggedIn = !!(
        hasSelectedRecurringDonation && !isLoggedIn
    );

    const hasStoredPayments = donorAccount?.paymentInfo?.length > 0;

    if (enableDonorLogins) {
        const returnDonorAccountActionsData = {
            Save_Payment: isRecurringAndNotLoggedIn,
            Create_Account_Password: isRecurringAndNotLoggedIn,
            Password: "",
            Password_Confirm: "",
            Donor_Account_Id: donorAccount.userId || "",
            ...(hasStoredPayments && {
                ...{ Stored_Payment: true, Stored_Payment_ID: "" },
            }),
        };

        return returnDonorAccountActionsData;
    }
    return {};
};

export default getDonorAccountActionsInitialValues;
