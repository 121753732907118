import { constants } from "@qgiv/core-js";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getPaymentTypeAllowsRecurringUpgrade,
    getShouldDisplayRecurringUpgrade,
} from "@qgiv/donation-form";
import {
    selectGiftDetails,
    selectSubmitDonation,
    updateSubmitDonation,
} from "../../redux/slices/donationDetailsSlice";
import {
    selectAllFormSettings,
    selectPaymentData,
} from "../../redux/slices/formSettingsSlice";
import {
    selectDonorAccount,
    selectIsLoggedIn,
} from "../../redux/slices/donorAccountSlice";
import {
    selectAddressGroupIsDisplayable,
    selectDefaultCountry,
    selectDisplayableBillingFields,
    selectHasBillingNameField,
} from "../../redux/slices/fieldsSlice";
import { selectAllDonationSettings } from "../../redux/slices/donationSettingsSlice";
import {
    selectContentToDisplay,
    setShouldScrollToTop,
    updateContentToDisplay,
} from "../../redux/slices/appSettingsSlice";
import { ModalContentReplacerEnums } from "../common/ModalContentReplacer";
import { selectHasActiveFulfillment } from "../../redux/slices/promiseTransactionSlice";
import { buildPaymentPageFormikSettings } from "./pageDataHelper";
import { selectIsDisplayTypeModal } from "../../redux/slices/configSettingsSlice";
import { scrollTop } from "../PostMessage/postMessage";

const usePaymentPageData = () => {
    const {
        ENUMS: {
            EntityType: {
                SYSTEM_FIELD_GROUP_BILLING_ADDRESS,
                SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
            },
        },
    } = constants;

    const dispatch = useDispatch();

    const submitDonation = useSelector(selectSubmitDonation);

    const paymentData = useSelector(selectPaymentData);
    const donorAccount = useSelector(selectDonorAccount);
    const formSettings = useSelector(selectAllFormSettings);
    const { captcha, enableDonorLogins, VisaAmexDisc, enableRecur } =
        formSettings;
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const giftDetails = useSelector(selectGiftDetails);
    const { hasSelectedRecurringDonation, total, subtotal } = giftDetails;

    const displayableBillingFields = useSelector(
        selectDisplayableBillingFields,
    );

    const displayNameFieldData = useSelector(selectHasBillingNameField);
    const billingDefaultCountry = useSelector((state) =>
        selectDefaultCountry(state, SYSTEM_FIELD_GROUP_BILLING_ADDRESS),
    );

    const mailingAddressFieldsData = useSelector((state) =>
        selectAddressGroupIsDisplayable(
            state,
            SYSTEM_FIELD_GROUP_MAILING_ADDRESS,
        ),
    );

    const donationSettings = useSelector(selectAllDonationSettings);
    const {
        enableRecurringCtaLimit = false,
        recurringCtaAfter = false,
        recurringCtaLimitAmount = 0,
    } = donationSettings;

    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    const shouldDisplayRecurringUpgrade = getShouldDisplayRecurringUpgrade({
        enableRecur,
        enableRecurringCtaLimit,
        hasActiveFulfillment,
        hasSelectedRecurringDonation,
        recurringCtaAfter,
        recurringCtaLimitAmount,
        subtotal,
    });

    const selectedContentToDisplay = useSelector(selectContentToDisplay);
    const isDisplayTypeModal = useSelector(selectIsDisplayTypeModal);

    const paymentPageData = useMemo(() => {
        const handleUpdateSubmitDonation = (Payment_Type) => {
            const paymentTypeAllowsRecurringUpgrade =
                getPaymentTypeAllowsRecurringUpgrade(Payment_Type);

            if (
                shouldDisplayRecurringUpgrade &&
                paymentTypeAllowsRecurringUpgrade
            ) {
                // show recurring upgrade UI
                if (isDisplayTypeModal) {
                    dispatch(setShouldScrollToTop(true));
                } else {
                    scrollTop();
                }
                setTimeout(() => {
                    dispatch(
                        updateContentToDisplay(
                            ModalContentReplacerEnums.UPGRADE_RECURRING_UI,
                        ),
                    );
                }, 50);
                return;
            }
            dispatch(setShouldScrollToTop(true));
            // submit form
            dispatch(updateSubmitDonation(true));
        };

        const formikSettings = buildPaymentPageFormikSettings({
            billingDefaultCountry,
            captcha,
            displayableBillingFields,
            displayNameFieldData,
            donorAccount,
            enableDonorLogins,
            hasSelectedRecurringDonation,
            isLoggedIn,
            mailingAddressFieldsData,
            paymentData,
            total,
            VisaAmexDisc,
        });

        return {
            documentTitle: "Payment",
            formikSettings,
            handleUpdateSubmitDonation,
            selectedContentToDisplay,
            shouldDisplayRecurringUpgrade,
            submitDonation,
        };
    }, [
        VisaAmexDisc,
        billingDefaultCountry,
        captcha,
        dispatch,
        displayNameFieldData,
        displayableBillingFields,
        donorAccount,
        enableDonorLogins,
        hasSelectedRecurringDonation,
        isDisplayTypeModal,
        isLoggedIn,
        mailingAddressFieldsData,
        paymentData,
        selectedContentToDisplay,
        shouldDisplayRecurringUpgrade,
        submitDonation,
        total,
    ]);

    return paymentPageData;
};

export default usePaymentPageData;
