import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { detectCCTypes, getAcceptedCCs, stringToType } from "@qgiv/core-js";
import { Icon } from "@qgiv/core-react";
import { getQMaskConfig } from "../QMaskField";
import TextField from "../TextField";

import "./CreditCard.scss";
import useTooltip from "../../../hooks/useTooltip";

const CCFields = ({
    VisaAmexDisc,
    showBillingNameField = true,
    billingNameLabel = "Name on Card",
    cardNumberLabel = "Card Number",
    expirationDateLabel = "Exp. Date",
    cvvLabel = "CVV",
    billingDescText = "",
}) => {
    const { errors, handleBlur, handleChange, setValues, touched, values } =
        useFormikContext();
    const { Billing_Name, Card_Number, Card_Exp_Date, Card_CVV } = values;

    const _handleChange = (e, QMask) => {
        const currentTarget = e.currentTarget || QMask.el.input;
        const { name } = currentTarget;
        let value = "";
        if (QMask) {
            value = stringToType(QMask.unmaskedValue);
        } else {
            value = stringToType(currentTarget.value);
        }
        switch (name) {
            default:
                setValues({
                    ...values,
                    [name]: value,
                });
                break;
        }
        if (typeof handleChange === "function") {
            handleChange(e);
        }
    };

    const tooltipMessage =
        "For most cards, your CVV is the 3-digit security code on the back of your card. For American Express, your CVV is the 4-digit security code on the front of your card.";

    const renderMobileTooltipMessage = () => {
        return (
            <>
                For most cards, your CVV is the 3-digit security code on the
                back of your card.
                <div className="-margin-top--10">
                    For American Express, your CVV is the 4-digit security code
                    on the front of your card.
                </div>
            </>
        );
    };

    const getTooltip = useTooltip({
        message: tooltipMessage,
        mobileMessage: renderMobileTooltipMessage(),
    });

    // Get settings for CC QMaskComponent
    let config = getQMaskConfig("credit");

    // Checking entered CC number against accepted CC types and valid CC numbers
    const acceptedCCs = getAcceptedCCs(VisaAmexDisc);
    const ccTypes = detectCCTypes(Card_Number);
    const ccMasks = {
        visa: "0000 0000 0000 0000 000",
        mastercard: "0000 0000 0000 0000",
        amex: "0000 000000 00000",
        discover: "0000 0000 0000 0000 000",
    };

    // If card is not accepted, show error immediately, otherwise wait for blur
    const showCCError =
        (Card_Number.length > 0 && ccTypes.length === 0) || touched.Card_Number;

    const buildCCIconList = () => {
        const showAll =
            acceptedCCs.filter((x) => ccTypes.includes(x)).length === 0;

        return acceptedCCs.map((type, i) => {
            const typeMatches = ccTypes.includes(type);
            let iconClasses = ["icon--" + type];
            if (typeMatches) {
                iconClasses.push("icon--matched");
            }

            return (
                (showAll || typeMatches) && (
                    <Icon
                        classNames={iconClasses}
                        glyph={"cc-" + type + "-brands"}
                        type="FontAwesome"
                        key={i}
                    />
                )
            );
        });
    };

    if (ccTypes.length) {
        config["mask"] = ccMasks[ccTypes[0]];
    }

    return (
        <div className="-callout -padding-top--0 -margin-top--15 credit-input-container">
            <div className="qg-vendor-credit-input credit-input grid">
                {showBillingNameField && (
                    <div className="col col--12">
                        <TextField
                            type="text"
                            name="Billing_Name"
                            label={billingNameLabel}
                            autoComplete="cc-name"
                            handleBlur={handleBlur}
                            handleChange={_handleChange}
                            value={Billing_Name || ""}
                            error={
                                touched.Billing_Name &&
                                errors.Billing_Name &&
                                errors.Billing_Name.length > 0
                            }
                            errorText={errors.Billing_Name}
                            descText={billingDescText}
                        />
                    </div>
                )}

                <div className="col col--12 card-input">
                    <TextField
                        type="credit"
                        name="Card_Number"
                        label={cardNumberLabel}
                        autoComplete="cc-number"
                        handleBlur={handleBlur}
                        handleChange={_handleChange}
                        value={Card_Number}
                        cursor="text"
                        error={
                            showCCError &&
                            errors.Card_Number &&
                            errors.Card_Number.length > 0
                        }
                        errorText={errors.Card_Number}
                        config={config}
                        ariaLabel={cardNumberLabel}
                        ariaRequired="true"
                    />
                    <span className="qg-vendor-credit-input__icons credit-input__icons -padding-right--15">
                        {buildCCIconList()}
                    </span>
                </div>

                <div className="col col--6">
                    <TextField
                        type="dateMY"
                        name="Card_Exp_Date"
                        label={expirationDateLabel}
                        autoComplete="cc-exp"
                        handleBlur={handleBlur}
                        handleChange={_handleChange}
                        value={Card_Exp_Date}
                        cursor="text"
                        error={
                            touched.Card_Exp_Date &&
                            errors.Card_Exp_Date &&
                            errors.Card_Exp_Date.length > 0
                        }
                        errorText={errors.Card_Exp_Date}
                        ariaLabel={expirationDateLabel}
                        ariaRequired="true"
                    />
                </div>

                <div className="col col--6 qg-vendor-cvv-input cvv-input">
                    <TextField
                        type={ccTypes.includes("amex") ? "cvv4" : "cvv3"}
                        name="Card_CVV"
                        label={cvvLabel}
                        autoComplete="cc-csc"
                        handleBlur={handleBlur}
                        handleChange={_handleChange}
                        value={Card_CVV}
                        cursor="text"
                        error={
                            touched.Card_CVV &&
                            errors.Card_CVV &&
                            errors.Card_CVV.length > 0
                        }
                        errorText={errors.Card_CVV}
                        ariaLabel={cvvLabel}
                        ariaRequired="true"
                    />
                    <span className="qg-vendor-cvv-input__tooltip cvv-input__tooltip">
                        {getTooltip}
                    </span>
                </div>
            </div>
        </div>
    );
};

CCFields.propTypes = {
    VisaAmexDisc: PropTypes.string.isRequired,
    showBillingNameField: PropTypes.bool,
    billingNameLabel: PropTypes.string,
    cardNumberLabel: PropTypes.string,
    expirationDateLabel: PropTypes.string,
    cvvLabel: PropTypes.string,
};

export default CCFields;
