import { createSlice } from "@reduxjs/toolkit";
import { skinSettings as initialState } from "../initialState";

const getSkinSettingsInitialState = () => ({
    ...initialState,
});

export const skinSettingsInitialState = getSkinSettingsInitialState();

const slice = createSlice({
    name: "skinSettings",
    initialState,
    reducers: {
        setSkinSettings: (state, action) => action.payload,
    },
});

// actions
export const { setSkinSettings } = slice.actions;

// selectors
export const selectSkinSettings = (state) => state.skinSettings;

export default slice.reducer;
