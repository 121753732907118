import { createSlice } from "@reduxjs/toolkit";
import { internalSettings as initialState } from "../initialState";

const getInternalSettingsInitialState = () => initialState;
export const internalSettingsInitialState = getInternalSettingsInitialState();

export const slice = createSlice({
    name: "internalSettings",
    initialState: internalSettingsInitialState,
    reducers: {},
});

// selectors
export const selectInternalSettings = (state) => state.internalSettings;

export const selectEnableAnalyticsOutsideIframe = (state) =>
    state.internalSettings.enableAnalyticsOutsideIframe;

export const selectDisplayPaymentIframe = (state) =>
    state.internalSettings.displayPaymentIframe;

export default slice.reducer;
