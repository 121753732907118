import { getConfirmationFieldsInitialValues } from "@qgiv/donation-form";
import { getFieldValue } from "@qgiv/core-donor";
import { constants } from "@qgiv/core-js";

const {
    ENUMS: { FieldType },
} = constants;

/**
 * @function getCustomFieldsInitialValues
 * @param {object} displayableCustomFields
 * @description Gets the initial values for any displayable custom fields.
 * @returns {object}
 */
export const getCustomFieldsInitialValues = (displayableCustomFields) => {
    // return values object with key of custom field id
    const customFieldsInitialValues = displayableCustomFields.reduce(
        (valuesObj, currentField) => {
            const { type } = currentField;

            /**
             * Hidden Field data, if present, is included with
             * general submission data prior to the initialization of
             * Confirmation Fields. As such, data for these fields
             * should not be included in Confirmation Fields.
             */
            if (type === FieldType.HIDDEN_FIELD) {
                return valuesObj;
            }

            const fieldId = currentField.id;
            const value = getFieldValue(currentField);
            return {
                ...valuesObj,
                [fieldId]: value,
            };
        },
        {},
    );
    return customFieldsInitialValues;
};

/**
 * @private
 * @function getInitialValues
 * @description gets the initial values for Confirmation Field system fields and custom fields
 * @param {object} _settings settings needed to get system field and custom field initial values
 * @param {object} savedValues any saved values that should overwrite the initial values from settings
 * @returns {object} initial values for confirmation fields
 */
const getInitialValues = (_settings, savedValues = {}) => {
    const { displayableCustomFields } = _settings;
    const confirmationFieldsInitialValues =
        getConfirmationFieldsInitialValues(_settings);
    const customFieldsInitialValues = getCustomFieldsInitialValues(
        displayableCustomFields,
    );
    const initialValuesFromSettings = {
        ...confirmationFieldsInitialValues,
        ...customFieldsInitialValues,
    };

    // Override any existing data with user-input data that has been stored
    const values = {
        ...initialValuesFromSettings,
        ...savedValues,
    };
    return values;
};

export default getInitialValues;
