import React from "react";
import useImageSliderContext from "../../hooks/useImageSliderContext";
import Icon from "../Icon";

// ⬇️ Grandfathered in from before Airbnb rules
// eslint-disable-next-line default-param-last
const useArrowClick = (imageKey, onClick = () => {}, imageCount, direction) => {
    const { onToggle } = useImageSliderContext();
    // Go back to 0 if reach end of image array
    const toggleRight = imageKey + 1 === imageCount ? 0 : imageKey + 1;
    // Go to last item if at 0 and hit left arrow
    const toggleLeft = imageKey === 0 ? imageCount - 1 : imageKey - 1;
    const toggleDirection = direction === "right" ? toggleRight : toggleLeft;

    return (event) => {
        onToggle(toggleDirection);

        if (onClick) {
            onClick(event);
        }
    };
};

/**
 * @param {object} props
 * @param {string|Function} [props.element]
 * @param {number} props.imageCount
 * @param {string|number} props.imageKey
 * @param {React.ReactNode} props.children
 * @param {Function} [props.onClick]
 * @param {boolean} [props.hideArrowButtons]
 * @param {string} [props.className]
 * @returns {React.ReactNode}
 */
const ImageSliderContent = ({
    element: Component = "div",
    imageCount,
    imageKey,
    children,
    onClick = () => {},
    hideArrowButtons = false,
    className = "image-slider__content--img",
}) => {
    const { activeImageKey } = useImageSliderContext();

    const arrowRightClick = useArrowClick(
        imageKey,
        onClick,
        imageCount,
        "right",
    );
    const rightArrow = hideArrowButtons ? null : (
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="icon-holder" onClick={arrowRightClick}>
            <Icon
                glyph="chevron-right-solid"
                type="FontAwesome"
                classNames={[""]}
            />
        </div>
    );
    const arrowLeftClick = useArrowClick(imageKey, onClick, imageCount, "left");
    const leftArrow = hideArrowButtons ? null : (
        // ⬇️ Grandfathered in from before Airbnb rules
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="icon-holder" onClick={arrowLeftClick}>
            <Icon
                glyph="chevron-left-solid"
                type="FontAwesome"
                classNames={[""]}
            />
        </div>
    );

    return activeImageKey !== null && activeImageKey === imageKey ? (
        <Component>
            <div className="image-slider__content">
                {leftArrow}
                <div className={className}>{children}</div>
                {rightArrow}
            </div>
        </Component>
    ) : null;
};

export default ImageSliderContent;
