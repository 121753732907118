import React from "react";
import { useDispatch } from "react-redux";
import { sortSystemAndCustomFieldsFromValues } from "@qgiv/donation-form";
import {
    updateDonationCustomFields,
    updateDonationDedication,
    updateDonationMatching,
} from "../../../redux/slices/donationDetailsSlice";
import { setCurrentPageNextPage } from "../../../redux/slices/appSettingsSlice";
import { useAdditionalDetailsPageData } from "../../PageDataHooks";
import {
    getInitialTouched,
    getInitialValues,
    getValidationSchema,
} from "./formik";
import AdditionalDetailsPage from "./AdditionalDetailsPage";

const ConnectedAdditionalDetailsPage = () => {
    // -------------------------------------------------------------------------
    // NOTE: Single Step form uses a different component set than these Connected Pages.
    // It does share the custom getPageData hooks, but if you make changes in the Connected Pages
    // make sure to make the same changes in the ConnectedSinglePage.js file.
    // -------------------------------------------------------------------------
    const additionalDetailsPageData = useAdditionalDetailsPageData();
    const {
        formikSettings,
        displayCaptcha,
        documentTitle,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnThisPage,
        savedValues,
    } = additionalDetailsPageData;

    const dispatch = useDispatch();

    const onSubmit = (values, actions) => {
        setTimeout(() => {
            // Store values in redux store via dispatching an action

            // Determine where to navigate user
            // will need to check for additional details page
            // when custom fields are introduced
            const { systemFieldValues, customFieldValues } =
                sortSystemAndCustomFieldsFromValues(values);

            dispatch(updateDonationCustomFields(customFieldValues));

            if (hasDisplayableMatchingGiftOnThisPage) {
                dispatch(updateDonationMatching(systemFieldValues));
            }
            if (hasDisplayableDedicationOnThisPage) {
                dispatch(updateDonationDedication(systemFieldValues));
            }
            // wait to make sure redux is updated
            setTimeout(() => {
                actions.setSubmitting(false);
                dispatch(setCurrentPageNextPage());
            }, 250);
        }, 350);
    };

    const {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    } = formikSettings;

    const formikProps = {
        initialTouched: getInitialTouched(initialTouchedSettings),
        initialValues: getInitialValues(initialValuesSettings, savedValues),
        validationSchema: getValidationSchema(validationSettings),
        onSubmit,
    };

    const detailsPageProps = {
        displayCaptcha,
        documentTitle,
        formikProps,
    };
    return <AdditionalDetailsPage {...detailsPageProps} />;
};

export default ConnectedAdditionalDetailsPage;
