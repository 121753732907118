import { constants } from "@qgiv/core-js";
import receiptDefaultValues, {
    DEFAULT_RECEIPT_ID,
} from "../components/DonationFormControl/receiptDefaultValues";

const { cms } = constants;
const isCms = cms === "1";

const QGIV = window.QGIV || {};

const initialState =
    Object.keys(QGIV).length > 0 && QGIV.initialState ? QGIV.initialState : {};

const validInitialStateObject = Object.keys(initialState).length > 0;

// prettier-ignore
export const activeInjections =
    validInitialStateObject && initialState.activeInjections
        ? initialState.activeInjections
        : {};

// prettier-ignore
export const appSettings =
    validInitialStateObject && initialState.appSettings
        ? initialState.appSettings
        : {};

// prettier-ignore
export const config =
    validInitialStateObject && initialState.config
        ? initialState.config
        : {};

export const cmsSettings =
    validInitialStateObject && initialState.cms ? initialState.cms : {};

export const donationSettings =
    validInitialStateObject && initialState.donationSettings
        ? initialState.donationSettings
        : {};

// This is a temp band aid until key names are changed for
// system fields coming in on initial state
// prettier-ignore
export const systemFields =
    validInitialStateObject && initialState?.fieldSettings?.systemFields
        ? initialState.fieldSettings.systemFields
        : [];

// prettier-ignore
export const customFields =
    validInitialStateObject && initialState?.fieldSettings?.customFields
        ? initialState.fieldSettings.customFields
        : [];

// Not sure if we are going to want to use this to set field settings of the
// segmented options above. This is more of a note to come back to this for
// the fields epic
export const fieldSettings =
    validInitialStateObject && initialState.fieldSettings
        ? initialState.fieldSettings
        : { systemFields: [], customFields: [] };

// prettier-ignore
export const formSettings =
    validInitialStateObject && initialState.formSettings
        ? initialState.formSettings
        : {};

// prettier-ignore
export const internalSettings =
    validInitialStateObject && initialState.internalSettings
        ? initialState.internalSettings
        : {};

// prettier-ignore
export const dedicationSettings =
    validInitialStateObject && initialState.dedicationSettings
        ? initialState.dedicationSettings
        : {};

// prettier-ignore
export const eventSettings =
    validInitialStateObject && initialState.eventSettings
        ? initialState.eventSettings
        : {};

// prettier-ignore
export const eventListSettings =
    validInitialStateObject && initialState.eventListSettings
        ? initialState.eventListSettings
        : {};

// prettier-ignore
export const restrictionSettings =
validInitialStateObject && initialState.restrictionSettings
    ? initialState.restrictionSettings :
    {};

// prettier-ignore
export const donorAccount =
validInitialStateObject && initialState.donorAccount
    ? initialState.donorAccount
    : {};

const createReceipts = () => {
    if (isCms) {
        return {
            [DEFAULT_RECEIPT_ID]: {
                ...receiptDefaultValues,
            },
        };
    }
    if (
        validInitialStateObject &&
        initialState.currentReceipt &&
        initialState.receipt
    ) {
        return {
            [initialState.currentReceipt]: {
                ...initialState.receipt,
            },
        };
    }
    return {};
};

const createCurrentReceipt = () => {
    if (isCms) {
        return DEFAULT_RECEIPT_ID;
    }
    if (validInitialStateObject && initialState.currentReceipt) {
        return initialState.currentReceipt;
    }
    return "";
};

export const currentReceipt = createCurrentReceipt();

export const receipts = createReceipts();

// prettier-ignore
export const promiseTransaction =
validInitialStateObject && initialState.promiseTransaction
    ? initialState.promiseTransaction
    : {};

// prettier-ignore
export const smsData =
validInitialStateObject && initialState.smsData
    ? initialState.smsData
    : {};

// prettier-ignore
export const urlShortcuts =
validInitialStateObject && initialState.urlShortcuts
    ? initialState.urlShortcuts
    : {};

export const preselectedRecipient =
    validInitialStateObject && initialState.preselectedRecipient
        ? initialState.preselectedRecipient
        : {};

// refers to the page being view in CMS. If the form is being viewed
// normally, this value is not sent with initial state
export const defaultView =
    validInitialStateObject && initialState.defaultView
        ? initialState.defaultView
        : 0;

export const abandonedGift =
    validInitialStateObject && initialState.abandonedGift
        ? initialState.abandonedGift
        : {};

export const cookieSettings =
    validInitialStateObject && initialState.cookieSettings
        ? initialState.cookieSettings
        : {};

export const skinSettings =
    validInitialStateObject && initialState.skinSettings
        ? initialState.skinSettings
        : {};
