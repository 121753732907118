import {
    Yup,
    getCCValidations,
    getBankFieldValidations,
    getBillingSameAsMailingValidations,
    getPaymentTypeValidations,
    getPaypalValidations,
    getBillingAddressValidations,
    getBillingNameValidation,
    getCaptchaFieldValidation,
} from "@qgiv/core-validation";

// -------------------------------------------------------------------------
// MARK: Form level validation schema
// -------------------------------------------------------------------------
const getPaymentPageValidations = (settings) => {
    const {
        VisaAmexDisc,
        displayableBillingFields,
        displayNameFieldData,
        shouldCaptchaBeRequired,
    } = settings;

    const paymentSchema = {
        ...getCCValidations(VisaAmexDisc),
        ...getBankFieldValidations(),
        ...getPaypalValidations(),
        ...getPaymentTypeValidations(),
        ...getBillingAddressValidations(displayableBillingFields),
        ...getBillingSameAsMailingValidations(),
        ...getBillingNameValidation(displayNameFieldData),
        ...getCaptchaFieldValidation(shouldCaptchaBeRequired),
    };

    return paymentSchema;
};

// Password Validations
export const getPasswordValidations = () => ({
    Password: Yup.string().when("Password_Confirm", {
        is: (Password_Confirm) => Password_Confirm,
        then: Yup.string().validatePasswordString(),
        otherwise: Yup.string().notRequired(),
    }),
});

// Password_Confirm Validations
export const getConfirmPasswordValidations = () => ({
    Password_Confirm: Yup.string().when(["Password"], {
        is: (Password) => Password,
        then: Yup.string().validateConfirmPasswordString(),
        otherwise: Yup.string().notRequired(),
    }),
});

const getCreateDonorAccountValidations = () =>
    // Generate combined form level validation schema
    ({
        ...getPasswordValidations(),
        ...getConfirmPasswordValidations(),
    });

export const getAllPaymentPageValidations = (settings) => ({
    ...getPaymentPageValidations(settings),
    ...getCreateDonorAccountValidations(),
});

const getValidationSchema = (settings) => {
    const validationShape = {
        ...getPaymentPageValidations(settings),
        ...getCreateDonorAccountValidations(),
    };
    const schema = Yup.object().shape(validationShape, [
        // prevent cyclic reference in Password/Password_Confirm fields validation
        // if more cross referencing fields are needed, just add another array here
        ["Password", "Password_Confirm"],
    ]);

    return schema;
};

export default getValidationSchema;
