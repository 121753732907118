import { useEffect } from "react";
import PropTypes from "prop-types";

const CompleteAnalyticsEvents = ({
    dataLayerOptions,
    hasSubmittedForm,
    isConfirmationPage,
    sendCompleteAnalyticsEvents,
}) => {
    // Should run during both a traditional and an early registration
    useEffect(() => {
        if (isConfirmationPage && hasSubmittedForm) {
            sendCompleteAnalyticsEvents(dataLayerOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfirmationPage]);
    return null;
};

CompleteAnalyticsEvents.propTypes = {
    dataLayerOptions: PropTypes.shape({}).isRequired,
    hasSubmittedForm: PropTypes.bool.isRequired,
    isConfirmationPage: PropTypes.bool.isRequired,
    sendCompleteAnalyticsEvents: PropTypes.func.isRequired,
};

export default CompleteAnalyticsEvents;
