import { constants } from "@qgiv/core-js";

/**
 * @public
 * @function formattedRecipientNameForPrivacy
 * @param {object} selectedRecipient data from redux that has all of the selected recipient specific data
 * @returns {string} returns string of either the participant name or captain content
 */
export const formattedRecipientNameForPrivacy = (selectedRecipient) => {
    const {
        ENUMS: { EntityType },
    } = constants;
    // Exit early if no Selected Recipient data
    if (!selectedRecipient || Object.keys(selectedRecipient).length === 0)
        return "";

    const { title, entityType, captains = [] } = selectedRecipient;

    const captainCount = captains ? captains.length : 0;

    // Account form missing title
    let formattedTitle = "";
    if (title) {
        formattedTitle = title;
    }

    if (
        (entityType === EntityType.TEAM ||
            entityType === EntityType.CLASSIFICATION) &&
        title
    ) {
        formattedTitle =
            captainCount > 1
                ? `${title}'s multiple captains`
                : `${title}'s captain`;
    }

    return formattedTitle;
};

/**
 * @public
 * @function getPrivacyCalloutMessage
 * @param {object} props Props
 * @param {number} props.fieldType Enum representation of type of field displayed
 * @param {object} props.selectedRecipient data from redux that has all of the selected recipient specific data
 * @param {boolean} props.displayAnonymousDonorInformation Event setting used to exit early if false
 * @returns {string} returns string of either the callout for name or amount callout with formatted title
 */
export const getPrivacyCalloutMessage = ({
    fieldType,
    selectedRecipient,
    displayAnonymousDonorInformation,
}) => {
    const {
        ENUMS: { FieldType, EntityType },
    } = constants;
    let calloutMessage = "";

    const hasSelectedEvent = selectedRecipient?.entityType === EntityType.FORM;

    // Exit early if no Selected Recipient data or
    // displayAnonymousDonorInformation is disabled or
    // recipient is event
    if (
        !displayAnonymousDonorInformation ||
        !selectedRecipient ||
        Object.keys(selectedRecipient).length === 0 ||
        hasSelectedEvent
    )
        return calloutMessage;

    switch (fieldType) {
        case FieldType.SHOW_NAME:
            calloutMessage = `Keep in mind that <strong>${formattedRecipientNameForPrivacy(
                selectedRecipient,
            )}</strong> will still see your name.`;
            break;
        case FieldType.SHOW_AMOUNT:
            calloutMessage = `Keep in mind that <strong>${formattedRecipientNameForPrivacy(
                selectedRecipient,
            )}</strong> will still see the amount.`;
            break;
        default:
            calloutMessage = "";
            break;
    }

    return calloutMessage;
};

/**
 * @public
 * @function getPrivacyTooltipMessage
 * @param {object} props Props
 * @param {number} props.fieldType Enum representation of type of field displayed
 * @param {object} props.selectedRecipient data from redux that has all of the selected recipient specific data
 * @param {boolean} props.displayAnonymousDonorInformation Event setting used to exit early if false
 * @returns {string} returns string of either the callout for name or amount tooltip with formatted title
 */
export const getPrivacyTooltipMessage = ({
    fieldType,
    selectedRecipient,
    displayAnonymousDonorInformation,
}) => {
    const {
        ENUMS: { FieldType, EntityType },
    } = constants;

    const defaultNameTooltip =
        "Keep in mind that your name will still be visible to the organization and payment processor.";

    const defaultAmountTooltip =
        "Keep in mind that the amount of your gift will still be visible to the organization and payment processor.";

    // Exit early if no Selected Recipient data
    if (!selectedRecipient || Object.keys(selectedRecipient).length === 0)
        return fieldType === FieldType.SHOW_NAME
            ? defaultNameTooltip
            : defaultAmountTooltip;

    const hasSelectedEvent = selectedRecipient?.entityType === EntityType.FORM;

    let tooltipMessage = "";

    switch (fieldType) {
        case FieldType.SHOW_NAME:
            if (displayAnonymousDonorInformation && !hasSelectedEvent) {
                tooltipMessage = `Keep in mind that your name will still be visible to the organization and payment processor, as well as ${formattedRecipientNameForPrivacy(
                    selectedRecipient,
                )}.`;
            } else {
                tooltipMessage = defaultNameTooltip;
            }

            break;
        case FieldType.SHOW_AMOUNT:
            if (displayAnonymousDonorInformation && !hasSelectedEvent) {
                tooltipMessage = `Keep in mind that the amount of your gift will still be visible to the organization and payment processor, as well as ${formattedRecipientNameForPrivacy(
                    selectedRecipient,
                )}.`;
            } else {
                tooltipMessage = defaultAmountTooltip;
            }

            break;
        default:
            // NOTE: This default will likely never be hit but added as a fail safe
            tooltipMessage =
                "Keep in mind that your details will still be visible to the organization and payment processor.";
            break;
    }

    return tooltipMessage;
};

/**
 * @description Because we have not brought the privacy options field group to
 * the year round form we need to change the option that we are paying attention
 * to here based on product type.
 * @param {object} props
 * @param {object} props.options
 * @param {boolean} props.shouldShowAmount
 * @param {boolean} props.shouldShowName
 * @param {number} props.type
 * @returns {string}
 */
export const getAnonymousCallout = ({
    options,
    shouldShowAmount,
    shouldShowName,
    type,
}) => {
    const {
        ENUMS: { ProductType },
    } = constants;

    // Exit early if no options
    if (!options) return "";

    const {
        anonymousCallOut = "We won't show your name publicly.",
        hideNameCallout = "We won't show your name publicly.",
        hideAmountCallout = "We won't show the amount of your gift publicly.",
        hideNameAndAmountCallout = "We won't show your name or the amount of your gift publicly.",
    } = options;

    if (type === ProductType.QGIV) {
        return anonymousCallOut;
    }

    if (!shouldShowName && !shouldShowAmount) {
        return hideNameAndAmountCallout;
    }

    if (!shouldShowName && shouldShowAmount) {
        return hideNameCallout;
    }

    if (!shouldShowAmount && shouldShowName) {
        return hideAmountCallout;
    }

    return "";
};

/**
 * @public
 * @function getPrivacyProps
 * @param {object} props Props
 * @param {object} props.field system field data
 * @param {object} props.values Formik values
 * @param {object} props.selectedRecipient data from redux that has all of the selected recipient specific data
 * @param {boolean} props.displayAnonymousDonorInformation event setting
 * @returns {object} returns object with props needed
 */
export const getPrivacyProps = ({
    field,
    values,
    selectedRecipient,
    displayAnonymousDonorInformation,
}) => {
    const {
        ENUMS: {
            FieldType,
            EntityType: { FORM },
        },
    } = constants;

    if (!field || Object.keys(field).length === 0 || !values)
        return {
            calloutMessage: "",
            privacyCheckboxProps: {
                inputId: "",
                inputName: "",
                inputValue: false,
                tooltipMessage: "",
            },
            showCallout: false,
        };

    const { Do_Not_Show_Amount = false, Do_Not_Show_Name = false } = values;

    const fieldType = Number(field?.type);
    const isShowName = fieldType === FieldType.SHOW_NAME;
    const isShowAmount = fieldType === FieldType.SHOW_AMOUNT;

    const tooltipMessage = getPrivacyTooltipMessage({
        fieldType,
        selectedRecipient,
        displayAnonymousDonorInformation,
    });
    const fieldLabel =
        fieldType === FieldType.SHOW_NAME
            ? "Do_Not_Show_Name"
            : "Do_Not_Show_Amount";

    const inputValue =
        fieldType === FieldType.SHOW_NAME
            ? Do_Not_Show_Name
            : Do_Not_Show_Amount;

    const privacyCheckboxProps = {
        tooltipMessage,
        inputId: fieldLabel,
        inputName: fieldLabel,
        inputValue,
    };

    const calloutMessage = getPrivacyCalloutMessage({
        fieldType,
        selectedRecipient,
        displayAnonymousDonorInformation,
    });

    const showCallout =
        !!displayAnonymousDonorInformation &&
        !!calloutMessage &&
        ((isShowName && values.Do_Not_Show_Name) ||
            (isShowAmount && values.Do_Not_Show_Amount)) &&
        selectedRecipient?.entityType !== FORM;

    return {
        calloutMessage,
        privacyCheckboxProps,
        showCallout,
    };
};
