import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { constants } from "@qgiv/core-js";
import { sendAmountChangeEvent } from "@qgiv/donation-form";
import {
    selectCurrentPage,
    selectFormStatusType,
    selectIsSinglePageForm,
    selectShouldScrollToTop,
    setShouldScrollToTop,
} from "../../../redux/slices/appSettingsSlice";
import {
    getResizeMessageToSend,
    scrollTop,
} from "../../PostMessage/postMessage";
import DonationFormClosedPage from "../../Pages/DonationFormClosedPage/DonationFormClosedPage";
import ConnectedUpdateFormDataFromSessionStorage from "../../ConnectedUpdateFormDataFromSessionStorage";
import ConnectedAnalyticsEvents from "../../DonationFormSharedContent/AnalyticsEvents";
import ConnectedUpdateShouldDisplayApplePay from "../../DonationFormSharedContent/ConnectedUpdateShouldDisplayApplePay";
import ConnectedDonationFormPageSwitch from "../../DonationFormSharedContent/ConnectedDonationFormPageSwitch";
import ConnectedDonationFormSinglePageSwitch from "../../DonationFormSharedContent/ConnectedDonationFormSinglePageSwitch";
import {
    selectDonorDetails,
    selectHasSelectedRecurringDonation,
    selectTotalGift,
} from "../../../redux/slices/donationDetailsSlice";

const DonationFormEmbedContent = () => {
    const {
        ENUMS: { DonationStatusType },
    } = constants;
    const ref = useRef();
    const dispatch = useDispatch();
    const isSinglePageForm = useSelector(selectIsSinglePageForm);
    const currentPage = useSelector(selectCurrentPage);
    const formStatusType = useSelector(selectFormStatusType);
    const shouldScrollToTop = useSelector(selectShouldScrollToTop);
    const hasSelectedRecurringDonation = useSelector(
        selectHasSelectedRecurringDonation,
    );
    const total = useSelector(selectTotalGift);
    const donorDetails = useSelector(selectDonorDetails);
    const { Company_Donation = false } = donorDetails;

    // fire qg-output-amountChange event so vendor scripts can listen
    useEffect(() => {
        sendAmountChangeEvent({
            Total: total,
            Recurring_Donation: hasSelectedRecurringDonation,
            Company_Donation,
        });
    }, [total, hasSelectedRecurringDonation, Company_Donation]);

    useEffect(() => {
        const stats = ref?.current?.getBoundingClientRect();
        if (stats !== undefined) {
            const { height, top } = stats;
            const totalHeight = height + top;
            getResizeMessageToSend(totalHeight);
        }
    }, [currentPage]);

    // shouldScrollToTop in appSettings can be used to trigger a scroll to top
    // from anywhere in the app
    useEffect(() => {
        if (shouldScrollToTop) {
            scrollTop();
            dispatch(setShouldScrollToTop(false));
        }
    }, [dispatch, shouldScrollToTop]);

    const isDonationFormClosed =
        formStatusType !== DonationStatusType.DONATIONS_AVAILABLE;

    return (
        <main
            className={cx(
                "qg-vendor-page__content donation-form-content__embed-view",
            )}
            ref={ref}
        >
            {/* if Form State is Closed load Closed Component else load below */}
            {isDonationFormClosed ? (
                <DonationFormClosedPage />
            ) : (
                <>
                    {isSinglePageForm ? (
                        <ConnectedDonationFormSinglePageSwitch />
                    ) : (
                        <ConnectedDonationFormPageSwitch />
                    )}

                    {/* <ConnectedUpdateCmsSettings /> */}

                    {/* Used for DataDome rerender */}
                    <ConnectedUpdateFormDataFromSessionStorage />
                    <ConnectedAnalyticsEvents />
                    <ConnectedUpdateShouldDisplayApplePay />
                </>
            )}
        </main>
    );
};

export default DonationFormEmbedContent;
