import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { PoweredByQgiv, DigiCert } from "@qgiv/core-react";

import "./DonationFormFooter.scss";

const DonationFormFooter = ({ showDigiCert }) => (
    <div className="qg-vendor-form-footer form-footer">
        <div
            className={cx(
                "form-footer__col",
                showDigiCert ? "-text--left" : "-text--center",
            )}
        >
            <PoweredByQgiv dark classNames={["form-footer__powered-by-qgiv"]} />
        </div>
        {/* The digicert logo is only rendered when payment fields are visible */}
        {showDigiCert && (
            <div className="form-footer__col -text--right">
                <div className="form-footer__digi-cert ">
                    <DigiCert />
                </div>
            </div>
        )}
    </div>
);

DonationFormFooter.propTypes = {
    showDigiCert: PropTypes.bool.isRequired,
};

export default DonationFormFooter;
