import React from "react";
import { useSelector } from "react-redux";
import { selectDisplayPaymentIframe } from "../../../../redux/slices/internalSettingsSlice";
import CCFieldsDisplay from "./CCFieldsDisplay";

/**
 *
 * @returns {React.ReactElement}
 */
const ConnectedCCFieldsDisplay = () => {
    const displayPaymentIframe = useSelector(selectDisplayPaymentIframe);

    const ccFieldsDisplayProps = {
        displayPaymentIframe,
    };

    return <CCFieldsDisplay {...ccFieldsDisplayProps} />;
};

export default ConnectedCCFieldsDisplay;
