import React from "react";
import PropTypes from "prop-types";
import QFieldInput from "../QFieldInput";
import { PrivacyOptionsCallout } from "../../PrivacyOptions";
import { getPrivacyProps } from "../../../utility/privacyOptionsHelper";

const SystemFieldGroupPrivacyOptions = (props) => {
    const {
        privacyFieldGroup = {},
        settings = {
            displayAnonymousDonorInformation: false,
        },
        values,
        selectedRecipient = {},
        headingProps = {},
    } = props;

    const { displayAnonymousDonorInformation } = settings;
    const { fields } = privacyFieldGroup;
    const {
        displayHeading = false,
        heading = "",
        additionalText = "",
    } = headingProps;

    return (
        <>
            {displayHeading && heading ? (
                // eslint-disable-next-line tailwindcss/classnames-order
                <div className="privacy-options-heading grid -margin-top--15">
                    <div className="col col--12">
                        <h3 className="qg-vendor-privacy-options-heading">
                            {heading}
                        </h3>
                        {additionalText && <small>{additionalText}</small>}
                    </div>
                </div>
            ) : null}
            <div className="grid">
                <div className="col col--12">
                    {fields.map((field) => {
                        const {
                            calloutMessage,
                            privacyCheckboxProps,
                            showCallout,
                        } = getPrivacyProps({
                            field,
                            values,
                            selectedRecipient,
                            displayAnonymousDonorInformation,
                        });

                        return (
                            <div key={field.id} className="-padding-bottom--15">
                                <QFieldInput
                                    {...props}
                                    field={field}
                                    privacyCheckboxProps={privacyCheckboxProps}
                                />
                                {displayAnonymousDonorInformation &&
                                showCallout ? (
                                    <PrivacyOptionsCallout
                                        classNames="col--no-left-gutter"
                                        message={calloutMessage}
                                    />
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

SystemFieldGroupPrivacyOptions.propTypes = {
    headingProps: PropTypes.shape({
        heading: PropTypes.string,
        displayHeading: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        additionalText: PropTypes.string,
    }),
    privacyFieldGroup: PropTypes.PropTypes.shape({}).isRequired,
    selectedRecipient: PropTypes.shape({}),
    settings: PropTypes.shape({
        displayAnonymousDonorInformation: PropTypes.bool,
    }),
    values: PropTypes.shape({}).isRequired,
};

export default SystemFieldGroupPrivacyOptions;
