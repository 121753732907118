import { Yup } from "@qgiv/core-validation";
import { getDetailsPageValidations } from "../../Pages/DetailsPage/formik/validation";
import { getAdditionalDetailsPageValidations } from "../../Pages/AdditionalDetailsPage/formik/validation";
import { getAllPaymentPageValidations } from "../../Pages/PaymentPage/formik/validation";
import { getGiftPageValidations } from "../../Pages/GiftPage/formik/validation";

const getValidationSchema = (hasActiveFulfillment, validationSettings) => {
    const {
        giftPageValidationSettings = {},
        donorDetailPageValidationSettings = {},
        additionalDetailPageValidationSettings = {},
        paymentPageValidationSettings = {},
    } = validationSettings;
    const validationShape = {
        ...getGiftPageValidations(giftPageValidationSettings),
        ...getDetailsPageValidations(donorDetailPageValidationSettings),
        ...(!hasActiveFulfillment && {
            ...getAdditionalDetailsPageValidations(
                additionalDetailPageValidationSettings,
            ),
        }),
        ...getAllPaymentPageValidations(paymentPageValidationSettings),
    };
    const schema = Yup.object().shape(validationShape, [
        // prevent cyclic reference in Password/Password_Confirm fields validation
        // if more cross referencing fields are needed, just add another array here
        ["Password", "Password_Confirm"],
    ]);

    return schema;
};

export default getValidationSchema;
