import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    getFormSpecificPathForSettingCookie,
    useElements,
    useSetAbandonedGiftCookie,
} from "@qgiv/core-donor";
import { getShouldRenderMultiRestriction } from "@qgiv/donation-form";
import {
    selectConfig,
    selectIsCms,
} from "../../redux/slices/configSettingsSlice";
import {
    selectAllFormSettings,
    selectFormThemeColor,
    selectFormThemeFontStack,
} from "../../redux/slices/formSettingsSlice";
import {
    selectGiftDetails,
    selectSelectedRecipient,
} from "../../redux/slices/donationDetailsSlice";
import {
    selectAbandonedGiftData,
    setAbandonedGiftReminderData,
} from "../../redux/slices/abandonedGiftSlice";
import {
    selectAllDonationSettings,
    selectEnableAbandonedGifts,
} from "../../redux/slices/donationSettingsSlice";
import {
    selectIsTextToDonate,
    selectSmsData,
} from "../../redux/slices/smsDataSlice";
import { selectHasActiveFulfillment } from "../../redux/slices/promiseTransactionSlice";
import {
    selectReceivedHandshake,
    selectHasSubmittedForm,
} from "../../redux/slices/appSettingsSlice";
import { sendPostMessage } from "../PostMessage/postMessage";
import DonationFormWrappers from "./DonationFormWrappers";
import { selectCmsPageOrder } from "../../redux/slices/cmsSettingsSlice";
import { selectAllEventSettings } from "../../redux/slices/eventSettingsSlice";

/**
 * ConnectedDonationFormWrappers component that gathers data needed for useElements hook and which
 * type of the donationForm logic/styles should be applied (Embed vs. Modal)
 * @returns {React.ReactElement} DonationFormWrappers
 */
const ConnectedDonationFormWrappers = () => {
    const {
        ENUMS: { CmsPageStandardizer },
    } = constants;
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const { isDisplayTypeModal, embed, path } = config;
    const color = useSelector(selectFormThemeColor);
    const fontFamilyStack = useSelector(selectFormThemeFontStack);
    const giftDetails = useSelector(selectGiftDetails);
    const {
        subtotal = 0,
        hasSelectedRecurringDonation = false,
        Recurring_Frequency,
        Gift_Assist = false,
    } = giftDetails;
    const abandonedGiftData = useSelector(selectAbandonedGiftData);
    const donationSettings = useSelector(selectAllDonationSettings);
    const { restrictionGivingType, pledgeActive } = donationSettings;
    const smsData = useSelector(selectSmsData);

    const selectedRecipientData = useSelector(selectSelectedRecipient);

    // need to prevent cookie setting on multi-restriction, giving plans, invoice, t2d forms
    const enableAbandonedGifts = useSelector(selectEnableAbandonedGifts);

    const isMultiRestriction = getShouldRenderMultiRestriction(
        restrictionGivingType,
        smsData,
    );

    const isGivingPlans = pledgeActive;

    const isTextToDonate = useSelector(selectIsTextToDonate);

    const hasActiveFulfillment = useSelector(selectHasActiveFulfillment);

    const receivedHandshake = useSelector(selectReceivedHandshake);

    const isCms = useSelector(selectIsCms);
    const pageOrder = useSelector(selectCmsPageOrder);
    const firstPageInPageOrder = pageOrder[0];
    const isEventListWidget =
        firstPageInPageOrder === CmsPageStandardizer.QGIV_EVENTS;

    const formSettings = useSelector(selectAllFormSettings);
    const eventSettings = useSelector(selectAllEventSettings);

    const shouldAllowCookieSetting = useMemo(
        () =>
            enableAbandonedGifts &&
            !isMultiRestriction &&
            !isGivingPlans &&
            !isTextToDonate &&
            !hasActiveFulfillment &&
            !isCms &&
            !isEventListWidget,
        [
            enableAbandonedGifts,
            isMultiRestriction,
            isGivingPlans,
            isTextToDonate,
            hasActiveFulfillment,
            isCms,
            isEventListWidget,
        ],
    );

    const {
        uiCustomizationOptions = {},
        qgiv_abandoned_gift = "",
        First_Name = "",
    } = abandonedGiftData;

    const firstName = First_Name;

    const {
        acceptReminderButtonText = "Give now",
        cancelReminderButtonText = "Not today",
        defaultHeader = "Your %Amount% gift will make an impact!",
        personalizedHeader = "%DonorFirstName%, your %Amount% gift will make an impact!",
    } = uiCustomizationOptions;

    // we have to be careful with PII in selected recipient data
    // we only want entity, entityType. Non-PDR forms can have
    // selectedRecipient come back from the selector as undefined
    const selectedRecipient = useMemo(() => {
        const hasSelectedRecipient =
            selectedRecipientData &&
            Object.keys(selectedRecipientData).length > 0;
        if (hasSelectedRecipient) {
            const { entity, entityType } = selectedRecipientData;
            return { entity, entityType };
        }
        return {};
    }, [selectedRecipientData]);

    const handleReminderAcceptPostMessage = useCallback(
        (reminderData) => {
            dispatch(
                setAbandonedGiftReminderData({
                    acceptedReminder: true,
                    reminderData,
                }),
            );
        },
        [dispatch],
    );

    // embed data could be null or object with id, url keys
    const embedId = embed?.id || 0;
    const ongoing = hasSelectedRecurringDonation;

    // Need to explicitly set cookie Path value
    const formSpecificPath = getFormSpecificPathForSettingCookie({
        embedId,
        eventSettings,
        formSettings,
        path,
    });

    const hasSubmittedForm = useSelector(selectHasSubmittedForm);

    // -------------------------------------------------------------------------
    // NOTE: useElements and useSetAbandonedGift were placed here
    // with consideration for single-step forms
    // -------------------------------------------------------------------------
    useElements({
        acceptReminderButtonText,
        acceptedGiftAssist: Gift_Assist,
        amount: subtotal,
        appSpecificPostMessage: sendPostMessage,
        cancelReminderButtonText,
        color,
        defaultHeader,
        embedId,
        firstName,
        fontFamilyStack,
        formSpecificPath,
        handleReminderAcceptPostMessage,
        hasSubmittedForm,
        ongoing,
        personalizedHeader,
        qgiv_abandoned_gift,
        receivedHandshake,
        recurringFrequency: Recurring_Frequency,
        selectedRecipient,
        shouldAllowCookieSetting,
    });

    const shouldAllowAbandonedGiftCookieSetting = useMemo(() => {
        let allowAbandonedGiftCookieSetting = true;
        if (isCms || isEventListWidget) {
            allowAbandonedGiftCookieSetting = false;
        }
        return allowAbandonedGiftCookieSetting;
    }, [isCms, isEventListWidget]);

    useSetAbandonedGiftCookie({
        enableAbandonedGifts,
        hasSubmittedForm,
        token: qgiv_abandoned_gift,
        shouldAllowAbandonedGiftCookieSetting,
    });

    return <DonationFormWrappers isDisplayTypeModal={isDisplayTypeModal} />;
};

export default ConnectedDonationFormWrappers;
