import { constants } from "@qgiv/core-js";

/**
 * @public
 * @function selectDirectLinkLoginUrlSelector
 * @description Builds Direct Link login URL (E.g. Qgiv Form https://secure.qgiv.com/for/formpath/login  )
 * @param {object} config config object from state
 * @returns {string} The base url with /login appended. Currently only used for Year Round Forms.
 *      This may need to be adjusted for other form types once the One Form can be embedded for other products.
 */
export const selectDirectLinkLoginUrlSelector = (config) => {
    const { defaultDonationUrl } = config;

    return defaultDonationUrl ? `${defaultDonationUrl}/login` : "";
};

/**
 * @public
 * @function selectWidgetThermometerUrlSelector
 * @description Builds thermometer widget for URL that is used in donation form for each specific product
 * @param {object} config config object from state
 * @param {object} control control object from state for thermometer widget
 * @param {object} eventSettings eventSettings object from state
 * @param {object} formSettings formSettings object from state
 * @param {number} formTemplate is the donation form being displayed on P2P, Form, Auctions etc.
 * @returns {string} The Thermometer widget url
 */
export const selectWidgetThermometerUrlSelector = (
    config,
    control,
    eventSettings,
    formSettings,
    formTemplate,
) => {
    const {
        ENUMS: {
            FormTemplate: { P2P_STANDARD, NEW_STANDARD_FORM },
            ProductType: { AUCTION },
        },
    } = constants;
    const { HTTP_HOST, path } = config;
    const { alias } = eventSettings;
    const { id: formId, type } = formSettings;
    const { id: controlId, options = {} } = control;

    // -------------------------------------------------------------------------
    // Note: Discussed with Fleming and chose to leave it here for now. If this
    // gets more complicated in the future, we may need to move this to the BE.
    // -------------------------------------------------------------------------
    const isSingleEventForm = type === AUCTION;

    if (formTemplate === P2P_STANDARD || isSingleEventForm) {
        return `${HTTP_HOST}/event/${alias}/widget/${controlId}/?etype=event&entity=${formId}`;
    }
    if (formTemplate === NEW_STANDARD_FORM) {
        const hasOptions = !!Object.keys(options).length > 0;
        const goal = hasOptions && options?.goal ? options.goal : "";

        return `${HTTP_HOST}/for/${path}/widgetize/${controlId}/?form=${formId}&etype=form&entity=${goal}`;
    }

    return "";
};
