import React from "react";
import ConnectedCCFields from "./ConnectedCCFields";
import ConnectedPaymentIframe from "./ConnectedPaymentIframe";

/**
 *
 * @param {object} props
 * @param {boolean} props.displayPaymentIframe
 * @returns {React.ReactElement}
 */
const CCFieldsDisplay = ({ displayPaymentIframe }) =>
    displayPaymentIframe ? <ConnectedPaymentIframe /> : <ConnectedCCFields />;

export default CCFieldsDisplay;
