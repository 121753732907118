import { useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { defaultFormThemeColor } from "../utility";

/**
 * @param {object} props
 * @param {string} props.acceptReminderButtonText
 * @param {boolean} [props.acceptedGiftAssist]
 * @param {number} [props.amount] - subtotal value
 * @param {Function} [props.appSpecificPostMessage]
 * @param {string} props.cancelReminderButtonText
 * @param {string} [props.color]
 * @param {string} props.defaultHeader
 * @param {number} [props.embedId]
 * @param {string} [props.firstName]
 * @param {string} [props.fontFamilyStack]
 * @param {string} props.formSpecificPath
 * @param {Function} [props.handleReminderAcceptPostMessage]
 * @param {boolean} props.hasSubmittedForm
 * @param {boolean} props.ongoing
 * @param {string} props.personalizedHeader
 * @param {boolean} [props.receivedHandshake]
 * @param {string} [props.recurringFrequency]
 * @param {object} props.selectedRecipient
 * @param {boolean} props.shouldAllowCookieSetting
 * @returns {void}
 */
const useElements = ({
    acceptReminderButtonText,
    acceptedGiftAssist = false,
    amount = 0,
    appSpecificPostMessage = () => {},
    cancelReminderButtonText,
    color = defaultFormThemeColor,
    defaultHeader,
    embedId = 0,
    firstName = "",
    fontFamilyStack,
    formSpecificPath,
    handleReminderAcceptPostMessage = () => {},
    hasSubmittedForm,
    ongoing,
    personalizedHeader,
    receivedHandshake = false,
    recurringFrequency = "m",
    selectedRecipient = {},
    shouldAllowCookieSetting,
}) => {
    const handleIncomingPostMessage = useCallback(
        (message = {}) => {
            const { data = "" } = message;
            if (typeof data !== "string" || data === "recaptcha-setup") {
                return;
            }

            // ensure that the string coming in from the message is valid JSON
            let validJson = false;
            let parsedData = {};
            try {
                parsedData = JSON.parse(data);
                validJson = true;
            } catch {
                validJson = false;
            }

            if (
                !validJson ||
                typeof parsedData !== "object" ||
                Object.keys(parsedData).length === 0
            ) {
                return;
            }

            const { event = "" } = parsedData;
            if (event === "reminder-accept") {
                const messageData = parsedData?.data;
                const messageAmount = messageData?.amount;
                const messageOngoing = messageData?.ongoing;
                const messageRecurringFrequency =
                    messageData?.recurringFrequency;
                const messageAcceptedGiftAssist =
                    messageData?.acceptedGiftAssist;
                const reminderData = {
                    amount: messageAmount,
                    ongoing: messageOngoing,
                    recurringFrequency: messageRecurringFrequency,
                    acceptedGiftAssist: messageAcceptedGiftAssist,
                };
                handleReminderAcceptPostMessage(reminderData);
            }
        },
        [handleReminderAcceptPostMessage],
    );

    // listen for post message to update amount selected
    useEffect(() => {
        window.addEventListener("message", handleIncomingPostMessage);

        return () =>
            window.removeEventListener("message", handleIncomingPostMessage);
    }, [handleIncomingPostMessage]);

    // clear elements cookie when form has been submitted
    useEffect(() => {
        if (hasSubmittedForm) {
            appSpecificPostMessage("clearElementCookie");
        }
    }, [appSpecificPostMessage, hasSubmittedForm]);

    // these parameters need to be renamed so that we are only pulling from the params
    // and we are not pulling from the previously defined props
    const sendCookieSettingPostMessage = (
        _acceptReminderButtonText,
        _acceptedGiftAssist,
        _amount,
        _appSpecificPostMessage,
        _cancelReminderButtonText,
        _color,
        _defaultHeader,
        _embedId,
        _firstName,
        _fontFamilyStack,
        _formSpecificPath,
        _ongoing,
        _personalizedHeader,
        _recurringFrequency,
        _selectedRecipient,
    ) => {
        let amountToStore = _amount;
        const stringAmount = `${_amount}`;
        const hasDecimal = stringAmount.includes(".");
        if (hasDecimal) {
            amountToStore = _amount.toFixed(2);
        }
        const data = {
            acceptReminderButtonText: _acceptReminderButtonText,
            acceptedGiftAssist: _acceptedGiftAssist,
            amount: amountToStore,
            cancelReminderButtonText: _cancelReminderButtonText,
            color: _color,
            defaultHeader: _defaultHeader,
            embedId: _embedId,
            firstName: _firstName,
            fontFamilyStack: _fontFamilyStack,
            formSpecificPath: _formSpecificPath,
            ongoing: _ongoing,
            personalizedHeader: _personalizedHeader,
            recurringFrequency: _recurringFrequency,
            selectedRecipient: _selectedRecipient,
        };
        appSpecificPostMessage("setElementAmount", data);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSendCookieSettingPostMessage = useCallback(
        debounce(sendCookieSettingPostMessage, 500),
        [],
    );

    // send data to create cookie when an amount is set but updates
    // when other values are set/changed
    useEffect(() => {
        if (
            receivedHandshake &&
            Number(amount) > 0 &&
            shouldAllowCookieSetting
        ) {
            debounceSendCookieSettingPostMessage(
                acceptReminderButtonText,
                acceptedGiftAssist,
                amount,
                appSpecificPostMessage,
                cancelReminderButtonText,
                color,
                defaultHeader,
                embedId,
                firstName,
                fontFamilyStack,
                formSpecificPath,
                ongoing,
                personalizedHeader,
                recurringFrequency,
                selectedRecipient,
            );
        }
    }, [
        acceptReminderButtonText,
        acceptedGiftAssist,
        amount,
        appSpecificPostMessage,
        cancelReminderButtonText,
        color,
        debounceSendCookieSettingPostMessage,
        defaultHeader,
        embedId,
        firstName,
        fontFamilyStack,
        formSpecificPath,
        ongoing,
        personalizedHeader,
        receivedHandshake,
        recurringFrequency,
        selectedRecipient,
        shouldAllowCookieSetting,
    ]);
};

export default useElements;
