import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "@qgiv/core-react";
import "./RecipientSearchItem.scss";

const RecipientSearchItem = ({
    handleSelectRecipient,
    isMediumScreen,
    recipient,
    searchCaptainLabel,
    searchTeamLabel,
}) => {
    const {
        captainName = "",
        classificationName = "",
        firstName = "",
        hasCaptain = false,
        hasTeam = false,
        iconGlyph = "",
        lastName = "",
        teamName = "",
        title = "",
        type = "",
    } = recipient;

    const recipientDoesNotHaveDescription = !hasTeam && !hasCaptain;

    const titleIconContainerClassnames = cx(
        "recipient-details__title-icon-container",
        recipientDoesNotHaveDescription &&
            "recipient-details__title-icon-container--vertically-center",
    );

    const recipientSearchItemClassnames = cx(
        "recipient-search-item",
        isMediumScreen && "recipient-search-item--mobile-padding",
    );

    const supportButtonClassnames = cx(
        "button button--primary",
        isMediumScreen && "button--regular",
    );

    const getSupportButtonAriaLabel = useCallback(() => {
        switch (type) {
            case "participant":
                return `Support ${firstName} ${lastName}`;
            case "team":
                return `Support ${teamName}`;
            case "classification":
                return `Support ${classificationName}`;
            default:
                return "Support";
        }
    }, [classificationName, firstName, lastName, teamName, type]);

    return (
        <div className={recipientSearchItemClassnames}>
            <div className={titleIconContainerClassnames}>
                <Icon
                    classNames={[
                        "-margin-right--10",
                        "recipient-details__icon",
                    ]}
                    ariaHidden
                    glyph={iconGlyph}
                    type="FontAwesome"
                />
            </div>
            <div className="recipient-details">
                <div>
                    <div className={titleIconContainerClassnames}>
                        <p className="recipient-details__title ignore-typography">
                            {title}
                        </p>
                    </div>
                    {/* Type Specific Details */}
                    {type === "participant" && hasTeam ? (
                        <p className="recipient-details__description ignore-typography ignore-apply-spacing">
                            <small>
                                {searchTeamLabel}: {` ${teamName}`}
                            </small>
                        </p>
                    ) : null}
                    {hasCaptain &&
                    (type === "team" || type === "classification") ? (
                        <p className="recipient-details__description ignore-typography ignore-apply-spacing">
                            <small>
                                {searchCaptainLabel}: {` ${captainName}`}
                            </small>
                        </p>
                    ) : null}
                </div>
            </div>
            <div className="recipient-actions">
                <button
                    className={supportButtonClassnames}
                    type="button"
                    onClick={() => handleSelectRecipient(recipient)}
                    aria-label={getSupportButtonAriaLabel()}
                >
                    Support
                </button>
            </div>
        </div>
    );
};

RecipientSearchItem.propTypes = {
    handleSelectRecipient: PropTypes.func.isRequired,
    isMediumScreen: PropTypes.bool.isRequired,
    recipient: PropTypes.shape({
        captainName: PropTypes.string,
        classificationName: PropTypes.string,
        firstName: PropTypes.string,
        hasCaptain: PropTypes.bool,
        hasTeam: PropTypes.bool,
        iconGlyph: PropTypes.string,
        lastName: PropTypes.string,
        teamName: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    searchCaptainLabel: PropTypes.string.isRequired,
    searchTeamLabel: PropTypes.string.isRequired,
};

export default RecipientSearchItem;
