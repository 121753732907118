import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import {
    PayPalSDKScriptProvider,
    getPayPalSDKScriptProviderProps,
    getDisplayPayPalButtonsFlags,
    getPaymentTypeInitialValue,
} from "@qgiv/core-donor";
import { useFormikContext } from "formik";
import { getValuesForCreateOrderAPI } from "@qgiv/donation-form/source/utility/paypalHelpers";
import {
    selectDonationRecipientControlExistsInCms,
    selectGiftDetails,
    selectHasSelectedRecurringDonation,
    selectSelectedRecipient,
    updateDonationPayment,
    updateShouldDisplayRecipientValidationError,
} from "../../../../redux/slices/donationDetailsSlice";
import {
    incrementForceReRenderCount,
    selectForceReRenderCount,
    selectHasCompletedPayPalInitialRender,
    selectPaymentMethodResetCount,
    setHasCompletedPayPalAuthorizationFlow,
    setHasCompletedPayPalInitialRender,
    setHasInitiatedPayPalAuthorizationFlow,
    setIsSubmittingUsingExpressCheckout,
    setPayPalPaymentSource,
} from "../../../../redux/slices/appSettingsSlice";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { selectDonorAccount } from "../../../../redux/slices/donorAccountSlice";

const ConnectedPayPalSDKScriptProvider = () => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const formikContext = useFormikContext();
    const dispatch = useDispatch();
    const formSettings = useSelector((state) => selectAllFormSettings(state));
    const giftDetails = useSelector(selectGiftDetails);
    const { subtotal, total } = giftDetails;
    const { paymentData = {}, isStandardDonationForm } = formSettings;
    const {
        enableAchPayments,
        enableCCPayments,
        enablePaypalPayments,
        payPalSDK,
    } = paymentData;
    const donorAccount = useSelector(selectDonorAccount);
    const paymentTypeInitialValue = getPaymentTypeInitialValue(
        {
            enableCCPayments,
            enableAchPayments,
            enablePaypalPayments,
        },
        donorAccount,
    );
    const {
        errors,
        setFieldValue = () => {},
        submitForm = () => {},
    } = formikContext;
    const values = getValuesForCreateOrderAPI(giftDetails);
    const orderSource = isStandardDonationForm ? "Standard Donation Form" : "";
    const platformSpecificDataForCreateOrderAPI = {
        payPalSDK,
        values,
        orderSource,
    };
    const paymentMethodResetCount = useSelector((state) =>
        selectPaymentMethodResetCount(state),
    );
    const forceReRenderCount = useSelector(selectForceReRenderCount);
    const {
        displayPayPalSDKScriptProvider,
        shouldLoadPayPalSDKScriptProvider,
    } = getDisplayPayPalButtonsFlags(payPalSDK);
    // Check if we're on a recurring donation — if yes, hide Venmo button.
    const isRecurringDonation = useSelector(selectHasSelectedRecurringDonation);
    // Make the button appear enabled in CMS
    const isCms = useSelector(selectIsCms);
    const donationRecipientExistsInCms = useSelector(
        selectDonationRecipientControlExistsInCms,
    );
    const selectedRecipient = useSelector(selectSelectedRecipient);
    const hasSelectedRecipient =
        // Products without donation recipient control in CMS
        // will have selected recipient come back as undefined
        // and we want to allow those donations to move forward
        // in the form.
        selectedRecipient !== undefined &&
        Object.keys(selectedRecipient).length > 0;
    const passesFormikValidation = Object.keys(errors).length === 0;
    // If on P2P form, check for donation recipient
    const hasValidSelectedRecipient =
        !donationRecipientExistsInCms ||
        (donationRecipientExistsInCms && hasSelectedRecipient);
    // Only enable the PayPal button when there is a donation amount to process
    const hasValidDonationAmount = subtotal > 0;
    // -------------------------------------------------------------------------
    // NOTE: hasAnAmountToSendToPayPal is responsible for enabling and disabling the PayPal
    // button functionality within PayPalSDKScriptProvider. While in other locations, this value
    // is dependent solely on donation amount, we need it to depend on additional values
    // in this specific use case. Since this use case is an outlier, we have decided to
    // keep the name 'hasAnAmountToSendToPayPal' for now, even though it does not
    // accurately describe the value as it exists within this file.
    // -------------------------------------------------------------------------
    const hasAnAmountToSendToPayPal =
        hasValidDonationAmount &&
        passesFormikValidation &&
        hasValidSelectedRecipient;
    // Always show disabled state when there is no amount to send to PayPal
    // -------------------------------------------------------------------------
    // NOTE: In other ConnectedPayPalSDKScriptProvider implementations,
    // the showDisabledStateWhenNoAmountIsSelected is a simple boolean.
    // Here, we need the value to be dynamic so that the disabled styling
    // is ONLY applied when we're missing an amount, and not when we're
    // failing Formik Validation or SelectedRecipient validation.
    // Like hasAnAmountToSendToPayPal above, we have decided not to change the
    // name of this value for now, so as to avoid conflicts with other components
    // that depend on showDisabledStateWhenNoAmountIsSelected as it was originally
    // implemented.
    // -------------------------------------------------------------------------
    const showDisabledStateWhenNoAmountIsSelected = subtotal === 0;

    // Assemble the data needed by the update component. The
    // displayUpdateIncrementForceReRenderCount flag can be removed once this
    // component has been integrated into every instance of the PayPal SDK
    const displayUpdateIncrementForceReRenderCount = true;
    const dispatchIncrementForceReRenderCount = () => {
        dispatch(incrementForceReRenderCount());
    };
    // -------------------------------------------------------------------------
    // NOTE:  Ideally, this component should also rerender based on Recurring_Frequency,
    // but it seems that for Recurring Donations, this value does not force a rerender,
    // due to the differences in how we handle one-time and recurring PayPal transactions.
    // This appears to occur on Form, as well.
    // -------------------------------------------------------------------------
    const valuesThatShouldForceAReRender = [
        hasValidSelectedRecipient,
        passesFormikValidation,
        total,
    ];
    const hasCompletedInitialRender = useSelector(
        selectHasCompletedPayPalInitialRender,
    );
    const dispatchSetHasCompletedPayPalInitialRender = () => {
        dispatch(setHasCompletedPayPalInitialRender());
    };
    const forceReRender = [forceReRenderCount];
    const paypalSDKScriptProviderPropsOptions = {
        dispatchIncrementForceReRenderCount,
        dispatchSetHasCompletedPayPalInitialRender,
        displayPayPalSDKScriptProvider,
        displayUpdateIncrementForceReRenderCount,
        forceReRender,
        hasAnAmountToSendToPayPal,
        hasCompletedInitialRender,
        isCms,
        isRecurringDonation,
        paymentMethodResetCount,
        payPalSDK,
        platformSpecificDataForCreateOrderAPI,
        shouldLoadPayPalSDKScriptProvider,
        valuesThatShouldForceAReRender,
    };
    const paypalSDKScriptProviderProps = getPayPalSDKScriptProviderProps(
        paypalSDKScriptProviderPropsOptions,
    );

    const handleCompletePayPalAuthorizationFlow = ({
        payPalValues = {},
        // eslint-disable-next-line no-shadow
        payPalPaymentSource = "paypal",
    }) => {
        const {
            PayPal_Agreement_ID = "",
            PayPal_Is_Venmo = false,
            PayPal_Payer_ID = "",
            PayPal_Token = "",
        } = payPalValues;

        // Since we're on the Gift Step, this is an Express Checkout transaction.
        // Therefore, we need to set the value for the relevant PayPal fields in Redux,
        // including the billing agreement ID, which is required for recurring donations.
        const paymentBucket = {
            Payment_Type: PaymentType.PAYPAL,
            PayPal_Payer_ID,
            PayPal_Token,
        };

        if (PayPal_Agreement_ID) {
            paymentBucket.PayPal_Agreement_ID = PayPal_Agreement_ID;
        }

        if (PayPal_Is_Venmo) {
            paymentBucket.PayPal_Is_Venmo = PayPal_Is_Venmo;
        }

        dispatch(updateDonationPayment(paymentBucket));

        // -------------------------------------------------------------------------
        // NOTE: We wait until the authorization to set isSubmittingUsingExpressCheckout
        // to true so that we only have to reset it in the error handling logic if
        // submission fails. If we don't do this, then we would also have to pass down
        // onCancel logic to reset isSubmittingUsingExpressCheckout if the user aborts
        // an attempt to use one of the Express Donate buttons prior to authorization.
        // -------------------------------------------------------------------------
        dispatch(setIsSubmittingUsingExpressCheckout(true));

        // Update the relevant values in the Redux store. Incrementing the step
        // submit count matches the behavior on the continue button and enables
        // an error message to be displayed if there is one
        dispatch(setHasCompletedPayPalAuthorizationFlow(true));
        dispatch(setPayPalPaymentSource(payPalPaymentSource));
    };

    // Display the use a different payment method button
    const handleInitiatePayPalAuthorizationFlow = (
        updatedHasInitiatedPayPalAuthorizationFlow,
    ) => {
        dispatch(
            setHasInitiatedPayPalAuthorizationFlow(
                updatedHasInitiatedPayPalAuthorizationFlow,
            ),
        );
    };
    const handleHasAnAmountToSendToPayPalError = () => {
        if (!passesFormikValidation) {
            // When called, submitForm will display any Formik errors.
            submitForm();
        }

        if (!hasValidSelectedRecipient) {
            dispatch(updateShouldDisplayRecipientValidationError(true));
        }
    };

    // Reset the values that were set when the donor clicked the PayPal button
    // as once an error is thrown we are no longer attempting to process a
    // PayPal transaction
    const handlePayPalAuthorizationError = (
        hasInitiatedPayPalAuthorizationFlow,
    ) => {
        // Replace with whatever the default value is
        setFieldValue("Payment_Type", paymentTypeInitialValue);
        dispatch(
            setHasInitiatedPayPalAuthorizationFlow({
                hasInitiatedPayPalAuthorizationFlow,
            }),
        );
        dispatch(setIsSubmittingUsingExpressCheckout(false));
    };

    const modifiedPayPalSDKScriptProviderProps = {
        ...paypalSDKScriptProviderProps,
        handleCompletePayPalAuthorizationFlow,
        handleHasAnAmountToSendToPayPalError,
        handleInitiatePayPalAuthorizationFlow,
        handlePayPalAuthorizationError,
        showDisabledStateWhenNoAmountIsSelected,
    };

    return (
        <PayPalSDKScriptProvider {...modifiedPayPalSDKScriptProviderProps} />
    );
};

export default ConnectedPayPalSDKScriptProvider;
