import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import cx from "classnames";
import { constants } from "@qgiv/core-js";
import { useBreakpoints } from "@qgiv/core-react";
import {
    getDefaultAllSearchCategories,
    getFilterButtonTypeMap,
    getRecipientList,
    getResultsString,
    handleSuccessfulSearchCall,
} from "@qgiv/donation-form";
import {
    selectContentToDisplay,
    updateContentToDisplay,
} from "../../../redux/slices/appSettingsSlice";
import {
    updateSelectedRecipient,
    updateShouldDisplayRecipientValidationError,
} from "../../../redux/slices/donationDetailsSlice";
import { ModalContentReplacerEnums } from "../ModalContentReplacer";
import {
    debounceSendResizeMessage,
    sendPostMessage,
} from "../../PostMessage/postMessage";
import { selectAllFormSettings } from "../../../redux/slices/formSettingsSlice";
import { selectCmsControlByTypeByPage } from "../../../redux/slices/cmsSettingsSlice";
import {
    selectDonationRecipientSettings,
    selectEventClassificationLabel,
} from "../../../redux/slices/eventSettingsSlice";
import RecipientSearch from "./RecipientSearch";

const ConnectedRecipientSearch = () => {
    const {
        ENUMS: {
            EntityType,
            FormTemplate: { P2P_STANDARD },
            CMS_Control: { STANDARD_DONATION_RECIPIENT },
            CmsPageStandardizer: { DONATION_AMOUNTS },
        },
    } = constants;
    const { isMediumScreen } = useBreakpoints();
    const dispatch = useDispatch();
    const selectedContentToDisplay = useSelector(selectContentToDisplay);
    const formSettings = useSelector(selectAllFormSettings);
    const donationRecipientSettings = useSelector(
        selectDonationRecipientSettings,
    );
    const classificationLabel = useSelector(selectEventClassificationLabel);

    const formId = formSettings.id;

    const [activeFilterButtons, setActiveFilterButtons] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchList, setSearchList] = useState([]);
    const [searchListHeight, setSearchListHeight] = useState("0px");
    const [listLoading, setListLoading] = useState(false);

    const headingRef = useRef();
    const searchRef = useRef();
    const footerRef = useRef();

    const defaultAllSearchCategories = getDefaultAllSearchCategories(
        donationRecipientSettings,
    );

    // remove bottom padding from list page footer on desktop view
    const footerClassnames = cx(
        "col col--12 -text--center",
        isMediumScreen ? "-padding--30" : "-padding-top--30",
    );

    const getUpdatedSearchList = (
        currentSearchTerm,
        currentSearchCategories,
    ) => {
        // Since all searchCategories and none [] are the same, we can just use the default with
        // all search categories on the api call for consistency. The
        // api call should never have an empty array even though the BE is
        // prepared to handle an empty array.
        const searchCategories =
            currentSearchCategories.length === 0
                ? defaultAllSearchCategories
                : currentSearchCategories;

        getRecipientList({
            searchTerm: currentSearchTerm,
            searchCategories,
            donationRecipientSettings,
            formId,
        })
            .then((response) => {
                const { status, data } = response;

                // do we have success: true in the new api standards?
                // I would prefer to use actual status codes if we can
                if (status === 200) {
                    handleSuccessfulSearchCall({
                        data,
                        donationRecipientSettings,
                        setSearchList,
                        setListLoading,
                    });
                    return;
                }
                setListLoading(false);
            })
            // TODO: error handling for search api
            // eslint-disable-next-line no-console
            .catch((err) => console.log({ err }));
    };

    // -------------------------------------------------------------------------
    // NOTE: Wrapping debounce in useCallback
    //       This will make sure the function returned from debounce is the same every render
    //       and not something that gets re-generated each time.
    // -------------------------------------------------------------------------
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceGetUpdatedSearchList = useCallback(
        debounce(getUpdatedSearchList, 500),
        [],
    );

    const handleSearchChange = (e) => {
        const value = e?.currentTarget?.value;
        if (value || value === "") {
            setListLoading(true);
            setSearchTerm(value);
            debounceGetUpdatedSearchList(value, activeFilterButtons);
        }
    };

    const handleSearchClearBtnClick = () => {
        setListLoading(true);
        setSearchTerm("");
        debounceGetUpdatedSearchList("", activeFilterButtons);
    };

    const setModalContentToDefault = () => {
        dispatch(
            updateContentToDisplay(ModalContentReplacerEnums.DEFAULT_CONTENT),
        );
        setTimeout(() => {
            debounceSendResizeMessage();
            sendPostMessage("top", { formTemplate: P2P_STANDARD });
        }, 10);
    };

    const eventRecipient = {
        type: "event",
        id: formId,
    };

    const recipientEntityTypeMap = {
        team: EntityType.TEAM,
        participant: EntityType.REGISTRATION,
        classification: EntityType.CLASSIFICATION,
        event: EntityType.FORM,
    };

    const handleSelectRecipient = (recipient) => {
        const newRecipient = {
            ...recipient,
            entityType: recipientEntityTypeMap[recipient.type],
            entity: recipient.id,
        };
        dispatch(updateSelectedRecipient(newRecipient));
        dispatch(updateShouldDisplayRecipientValidationError(false));
        setModalContentToDefault();
    };

    // This useEffect will run every time the search list ui opens,
    // but will not run again while the search list ui is open.
    // We want to make sure we are starting with an empty list and search term
    // before making the api call. Since the debounceGetUpdatedSearchList is wrapped
    // in useCallback, we want to use that to make the api call. Otherwise this useEffect
    // runs on every render, which is bad.
    useEffect(() => {
        let apiMounted = true;

        if (
            apiMounted &&
            selectedContentToDisplay ===
                ModalContentReplacerEnums.RECIPIENT_SEARCH_UI
        ) {
            setListLoading(true);
            setSearchList([]);
            setSearchTerm("");
            debounceGetUpdatedSearchList("", []);
        }
        return () => {
            apiMounted = false;
        };
    }, [debounceGetUpdatedSearchList, selectedContentToDisplay]);

    // -------------------------------------------------------------------------
    // NOTE: Set height of the Search List dynamically based on parent window's height
    //       Because we are setting the height of the iframe container in the embed logic
    //       to 100vh of the (this windows's parent) window when on this view, we are
    //       going to handle the height of the search list dynamically. The idea is to
    //       get the height of everything rendered, subtract that from the parent window,
    //       and the remainder is to what we set the list height.
    // -------------------------------------------------------------------------
    useEffect(() => {
        if (headingRef.current && searchRef.current && footerRef.current) {
            // list of elements we need to get dynamic heights for.
            // -------------------------------------------------------------------------
            // NOTE: IF more content is added to this page, we will need to add a ref for it.
            // -------------------------------------------------------------------------

            const refElements = [
                headingRef.current,
                searchRef.current,
                footerRef.current,
            ];
            const usedHeightFromOtherContent = refElements.reduce(
                (totalHeight, currentElement) =>
                    totalHeight + currentElement.offsetHeight,
                0,
            );
            // We want to set the percent of screen used vertically based on horizontal breakpoint
            // Since this view is always embedded into a parent window, we need to access
            // that parent to get the actual window height
            const percentageOfWindow = isMediumScreen ? 1 : 0.85;
            const windowMeasuredHeight =
                window.parent.innerHeight * percentageOfWindow;
            // this number can change if changes to spacing are made
            // but will not be dynamic form to form. These are things like
            // template padding and margin
            const spacingOutsideOfCalculatedRefs = 90;
            const difference = `${
                windowMeasuredHeight -
                usedHeightFromOtherContent -
                spacingOutsideOfCalculatedRefs
            }px`;
            setSearchListHeight(difference);
        }
    }, [
        headingRef,
        searchRef,
        footerRef,
        isMediumScreen, // want this to fire when breakpoints change
        selectedContentToDisplay, // want this to fire when content selected is changed
    ]);

    // CMS data
    const control = useSelector((state) =>
        selectCmsControlByTypeByPage(
            state,
            STANDARD_DONATION_RECIPIENT,
            DONATION_AMOUNTS,
        ),
    );

    const { options = {} } = control;

    const filterButtonTypeMap = getFilterButtonTypeMap({
        classificationLabel,
        donationRecipientSettings,
        options,
    });

    const resultString = getResultsString(
        activeFilterButtons,
        defaultAllSearchCategories,
        filterButtonTypeMap,
    );

    const shouldShowFilterButtons = filterButtonTypeMap.length > 1;

    const handleFilterButtonClick = (entityClicked) => {
        // If the entity is already in the active filter buttons, remove it
        // otherwise add it to the active filter buttons. Since activeFilterButtons
        // is also used to determine if a filter button is active, we do not
        // want to utilize the defaultAllSearchCategories array.  Instead we want
        // to use the activeFilterButtons array to be empty if none are active.
        const clickedFilterIsCurrentlyActive =
            activeFilterButtons?.includes(entityClicked);

        const newActiveFilterButtonArray = clickedFilterIsCurrentlyActive
            ? activeFilterButtons.filter((button) => button !== entityClicked)
            : [...activeFilterButtons, entityClicked];

        setActiveFilterButtons(newActiveFilterButtonArray);
        setListLoading(true);

        debounceGetUpdatedSearchList(searchTerm, newActiveFilterButtonArray);
    };

    return (
        <RecipientSearch
            activeFilterButtons={activeFilterButtons}
            donationRecipientSettings={donationRecipientSettings}
            eventRecipient={eventRecipient}
            filterButtons={filterButtonTypeMap}
            footerRef={footerRef}
            footerClassnames={footerClassnames}
            handleFilterButtonClick={handleFilterButtonClick}
            handleSearchChange={handleSearchChange}
            handleSearchClearBtnClick={handleSearchClearBtnClick}
            handleSelectRecipient={handleSelectRecipient}
            headingRef={headingRef}
            isMediumScreen={isMediumScreen}
            listLoading={listLoading}
            resultString={resultString}
            searchRef={searchRef}
            searchList={searchList}
            searchListHeight={searchListHeight}
            searchTerm={searchTerm}
            setModalContentToDefault={setModalContentToDefault}
            shouldShowFilterButtons={shouldShowFilterButtons}
            {...options}
        />
    );
};

export default ConnectedRecipientSearch;
