import React from "react";

// -------------------------------------------------------------------------
// TODO:
// 1. Add postMessage logic that dynamically sets the height of the iframe
// based on the height of the payment application that needs to be rendered.
// Because if we don't do this the iframe will either take up too much or
// too little space. Will want to memoize this function in order to prevent
// any unnecessary re-renders.
// -------------------------------------------------------------------------
/**
 *
 * @param {object} props
 * @param {string} props.src
 * @returns {React.ReactElement}
 */
const PaymentIframe = ({ src }) => {
    const style = { width: "100%" };

    // Ensure the spacing on top of the iframe matches the spacing above the
    // other payment UI element. Callout background is added here as well to
    // ensure that the background color does not flicker when the application
    // is fully loaded.
    return (
        <div className="-callout -margin-top--15 -padding--0">
            <iframe src={src} style={style} title="qgiv-payment-iframe" />
        </div>
    );
};

export default PaymentIframe;
