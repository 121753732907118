import { useEffect, useState, useCallback } from "react";
import { sendEmbedError } from "../api";

/**
 * @description hook which sends handshake postmessage to embed logic. If handshake is not returned,
 * sends error to the embed crash log #alerts-fundraising-form-embed-errors
 * @param {object} props
 * @param {boolean} props.allowHandshakeToReachElements
 * @param {Function} props.appSpecificPostMessage
 * @param {string} props.color form theme color
 * @param {boolean} props.enableAbandonedGifts
 * @param {number|string} props.entity form id
 * @param {number} props.entityType
 * @param {number} props.embedId
 * @param {string} props.fontFamilyStack
 * @param {string} props.HTTP_HOST
 * @returns {boolean} receivedHandshake
 */
const useHandshake = ({
    allowHandshakeToReachElements = false,
    appSpecificPostMessage,
    color,
    embedId = 0,
    enableAbandonedGifts = false,
    entity,
    entityType,
    fontFamilyStack = "",
    HTTP_HOST,
}) => {
    const [receivedHandshake, setReceivedHandshake] = useState(false);
    const warningMessageForHandshake =
        "Attention website owner: The embedded iframe for this Qgiv form is not communicating with your website as expected, which may be affecting its appearance. Please contact Qgiv's support team for assistance.";

    const handleIncomingPostMessage = useCallback((message = {}) => {
        const { data = "" } = message;
        if (typeof data !== "string" || data === "recaptcha-setup") {
            return;
        }

        // ensure that the string coming in from the message is valid JSON
        let validJson = false;
        let parsedData = {};
        try {
            parsedData = JSON.parse(data);
            validJson = true;
        } catch {
            validJson = false;
        }

        if (
            !validJson ||
            typeof parsedData !== "object" ||
            Object.keys(parsedData).length === 0
        ) {
            return;
        }

        const { event = "" } = parsedData;
        if (event === "handshake") {
            setReceivedHandshake(true);
        }
    }, []);

    useEffect(() => {
        const handshakeTimer = setTimeout(() => {
            // eslint-disable-next-line no-console
            console.warn(warningMessageForHandshake);
            sendEmbedError({ entity, entityType, HTTP_HOST });
        }, 5000);
        if (handshakeTimer && receivedHandshake) {
            clearTimeout(handshakeTimer);
        }
        return () => clearTimeout(handshakeTimer);
    }, [entity, entityType, HTTP_HOST, receivedHandshake]);

    useEffect(() => {
        // Send a handshake message to the embed logic so that the expected
        // styles for the app controlling its own resizing can be used.
        const data = {
            allowHandshakeToReachElements,
            color,
            enableAbandonedGifts,
            embedId,
            fontFamilyStack,
        };
        appSpecificPostMessage("handshake", data);

        // The embed logic will send a return handshake message
        // which we can use to cancel a timeout which would send to the BE
        // an instance of when the communication between parent window and iframe failed.
        window.addEventListener("message", handleIncomingPostMessage);

        return () =>
            window.removeEventListener("message", handleIncomingPostMessage);
    }, [
        allowHandshakeToReachElements,
        appSpecificPostMessage,
        color,
        enableAbandonedGifts,
        embedId,
        fontFamilyStack,
        handleIncomingPostMessage,
    ]);

    return receivedHandshake;
};

export default useHandshake;
