import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@qgiv/core-react";
import { BackButtonButton } from "@qgiv/core-donor";
import RecipientSearchBar from "./RecipientSearchBar";
import RecipientSearchList from "./RecipientSearchList";
import RecipientSearchFilterButtons from "./RecipientSearchFilterButtons/RecipientSearchFilterButtons";
import "./RecipientSearch.scss";

const RecipientSearch = ({
    activeFilterButtons = [],
    donationRecipientSettings = {},
    eventRecipient = {},
    filterButtons,
    footerClassnames = "",
    footerRef,
    handleFilterButtonClick = () => {},
    handleSearchChange = () => {},
    handleSearchClearBtnClick = () => {},
    handleSelectRecipient = () => {},
    headingRef,
    isMediumScreen = true,
    listLoading = false,
    resultString = "results",
    searchBackLabel = "Back",
    searchCaptainLabel = "Captain",
    searchClassificationLabel = "%ClassificationLabel%",
    searchHeading = "Whose fundraising efforts would you like to support?",
    searchList = [],
    searchListHeight = "0px",
    searchNoFundraisersLink = "Support Overall Campaign Instead",
    searchNoFundraisersYetMessage = "Looks like you're here a little early... we're still waiting for our first fundraiser!",
    searchNoResultsLink = "Support Overall Campaign Instead",
    searchNoResultsMessage = "Hmm... We can't find any specific fundraisers that match your search.",
    searchParticipantLabel = "Participant",
    searchPlaceholderLabel = "Find someone...",
    searchRef,
    searchTeamLabel = "Team",
    searchTerm = "",
    setModalContentToDefault = () => {},
    shouldShowFilterButtons = true,
}) => (
    <div className="grid">
        <div className="col col--12 col--no-top-gutter" ref={headingRef}>
            <h2 className="-text--center">{searchHeading}</h2>
        </div>
        <div className="col col--12" ref={searchRef}>
            <RecipientSearchBar
                handleSearchChange={handleSearchChange}
                handleSearchClearBtnClick={handleSearchClearBtnClick}
                searchTerm={searchTerm}
                placeholder={searchPlaceholderLabel}
            />

            {shouldShowFilterButtons && (
                <div className="-padding-top--15">
                    <RecipientSearchFilterButtons
                        activeFilterButtons={activeFilterButtons}
                        handleFilterButtonClick={handleFilterButtonClick}
                        filterButtons={filterButtons}
                    />
                </div>
            )}
        </div>

        <div className="col col--12">
            {/* Search List Header */}
            <div className="recipient-search__header">
                <p>
                    <Icon glyph="users-solid" type="FontAwesome" />
                    <small>
                        <span className="recipient-search__results-number">
                            {searchList.length}
                        </span>
                        <span>{resultString}</span>
                    </small>
                </p>
            </div>
            {/* Search List */}
            <RecipientSearchList
                donationRecipientSettings={donationRecipientSettings}
                eventRecipient={eventRecipient}
                handleSelectRecipient={handleSelectRecipient}
                listLoading={listLoading}
                isMediumScreen={isMediumScreen}
                searchListHeight={searchListHeight}
                searchList={searchList}
                searchTerm={searchTerm}
                searchCaptainLabel={searchCaptainLabel}
                searchParticipantLabel={searchParticipantLabel}
                searchTeamLabel={searchTeamLabel}
                searchClassificationLabel={searchClassificationLabel}
                searchNoFundraisersLink={searchNoFundraisersLink}
                searchNoFundraisersYetMessage={searchNoFundraisersYetMessage}
                searchNoResultsLink={searchNoResultsLink}
                searchNoResultsMessage={searchNoResultsMessage}
            />
        </div>
        <div className={footerClassnames} ref={footerRef}>
            <BackButtonButton
                ariaLabel="Back"
                ariaHidden={false}
                backLinkLabel={searchBackLabel}
                handleClick={setModalContentToDefault}
            />
        </div>
    </div>
);

RecipientSearch.propTypes = {
    donationRecipientSettings: PropTypes.shape({
        enableClassificationForDonation: PropTypes.bool.isRequired,
        enableDonationToEvent: PropTypes.bool.isRequired,
        enableDonationToParticipant: PropTypes.bool.isRequired,
        enableDonationToTeam: PropTypes.bool.isRequired,
    }).isRequired,
    eventRecipient: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
    }).isRequired,
    footerClassnames: PropTypes.string.isRequired,
    footerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    handleSearchClearBtnClick: PropTypes.func,
    handleSearchChange: PropTypes.func,
    handleSelectRecipient: PropTypes.func,
    headingRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    isMediumScreen: PropTypes.bool,
    listLoading: PropTypes.bool,
    resultString: PropTypes.string,
    searchList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
        }),
    ),
    searchListHeight: PropTypes.string,
    searchRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    searchTerm: PropTypes.string,
    setModalContentToDefault: PropTypes.func,
    searchBackLabel: PropTypes.string,
    searchCaptainLabel: PropTypes.string,
    searchClassificationLabel: PropTypes.string,
    searchHeading: PropTypes.string,
    searchNoFundraisersLink: PropTypes.string,
    searchNoFundraisersYetMessage: PropTypes.string,
    searchNoResultsLink: PropTypes.string,
    searchNoResultsMessage: PropTypes.string,
    searchParticipantLabel: PropTypes.string,
    searchPlaceholderLabel: PropTypes.string,
    searchTeamLabel: PropTypes.string,
};

export default RecipientSearch;
