import { constants } from "@qgiv/core-js";
import { createDataLayerPayload } from "@qgiv/core-donor";
import { sendStepChangeEvent } from "@qgiv/donation-form";
import { sendEmbedDataLayerMessage } from "../components/PostMessage/postMessage";

// -------------------------------------------------------------------------
// NOTE: If you need to wait on GTM to load before running these events
// refer to the example here:
// https://www.simoahava.com/analytics/notify-page-google-tag-manager-loaded/
// -------------------------------------------------------------------------
/**
 * @param {object} dataLayerOptions All of the potential objects that can
 *                 potentially be passed to the data layer.
 */
export const sendStartAnalyticsEvents = (dataLayerOptions = {}) => {
    const {
        ENUMS: { DataLayerEvent },
    } = constants;

    // Gather and format payloads
    const donationStartPayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_START,
        dataLayerOptions,
    );
    const pageViewPayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_PAGE_VIEW,
        dataLayerOptions,
    );

    // Fire events
    sendEmbedDataLayerMessage(donationStartPayload);
    sendEmbedDataLayerMessage(pageViewPayload);
};

/**
 * @param {object} dataLayerOptions All of the potential objects that can
 *                 potentially be passed to the data layer.
 * @param {number} currentPage The current page that the donor is on.
 * @returns {undefined}
 */
export const sendStepChangeAnalyticsEvents = (
    dataLayerOptions = {},
    currentPage = 1,
) => {
    const {
        ENUMS: { DataLayerEvent },
    } = constants;

    // Gather and format payloads
    const donationStepChangePayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_STEP_CHANGE,
        dataLayerOptions,
    );
    const pageViewPayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_PAGE_VIEW,
        dataLayerOptions,
    );

    // Fire events
    sendStepChangeEvent(currentPage);
    sendEmbedDataLayerMessage(donationStepChangePayload);
    sendEmbedDataLayerMessage(pageViewPayload);
};

/**
 * @param {object} dataLayerOptions All of the potential objects that can
 *                 potentially be passed to the data layer.
 * @returns {undefined}
 */
export const sendCompleteAnalyticsEvents = (dataLayerOptions = {}) => {
    const {
        ENUMS: { DataLayerEvent },
    } = constants;

    // Gather and format payloads
    const donationStepChangePayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_STEP_CHANGE,
        dataLayerOptions,
    );
    const pageViewPayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_PAGE_VIEW,
        dataLayerOptions,
    );
    const donationCompletePayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_COMPLETE,
        dataLayerOptions,
    );
    const ga4PurchasePayloadPayload = createDataLayerPayload(
        DataLayerEvent.QGIV_GA4_PURCHASE,
        dataLayerOptions,
    );

    // Fire events
    sendEmbedDataLayerMessage(donationStepChangePayload);
    sendEmbedDataLayerMessage(pageViewPayload);
    sendEmbedDataLayerMessage(donationCompletePayload);
    sendEmbedDataLayerMessage(ga4PurchasePayloadPayload);
};

/**
 * @param {object} dataLayerOptions All of the potential objects that can
 *                 potentially be passed to the data layer.
 * @returns {Function} Handler that runs when the registrant leaves the
 *                     registration.
 */
export const getClosedAnalyticsEvents = (dataLayerOptions = {}) => {
    const {
        ENUMS: { DataLayerEvent },
    } = constants;

    // Gather and format payloads
    const donationClosedPayload = createDataLayerPayload(
        DataLayerEvent.QGIV_DONATION_CLOSE,
        dataLayerOptions,
    );

    // Return the event handler to ensure that it can be added and removed
    // to the DOM within the React component. If the handler is never
    // dismounted then it will fire when the donor leaves the receipt page
    // after they submitted a registration.
    const sendClosedAnalyticsEvents = () => {
        sendEmbedDataLayerMessage(donationClosedPayload);
    };

    return sendClosedAnalyticsEvents;
};
