import { Icon } from "@qgiv/core-react";
import React from "react";
import "./RecipientSearchFilterButtons.scss";
import { Button } from "@qgiv/core-donor";

const RecipientSearchFilterButtons = ({
    activeFilterButtons = [],
    handleFilterButtonClick = () => {},
    filterButtons = [],
}) => (
    <div className="recipient-search__filter-button-group">
        {filterButtons.map((filterButton) => {
            const isActive = activeFilterButtons.includes(filterButton.type);
            return (
                <div
                    className="recipient-search__filter-button"
                    key={filterButton.type}
                >
                    <Button
                        btnStyle="tertiary"
                        size="small"
                        classes={`${
                            isActive
                                ? "recipient-search__filter-button--active"
                                : "recipient-search__filter-button--inactive"
                        }`}
                        onClick={() =>
                            handleFilterButtonClick(filterButton.type)
                        }
                    >
                        <Icon
                            glyph={filterButton.iconGlyph}
                            type="FontAwesome"
                            classNames={[
                                "-color--theme-primary",
                                "-type--regular",
                                "-margin-right--5",
                                "-vertical-align--middle",
                            ]}
                        />
                        {filterButton.label}
                    </Button>
                </div>
            );
        })}
    </div>
);

export default RecipientSearchFilterButtons;
