import { Yup } from "@qgiv/core-validation";
import { getAllCustomFieldValidation } from "@qgiv/core-donor";

const getPhoneFieldValidation = ({ phoneFieldIsRequired, country }) => {
    const { exists } = phoneFieldIsRequired;
    if (exists) {
        return {
            Phone:
                country === "US"
                    ? Yup.string().validatePhoneString()
                    : Yup.string().validateInternationalPhoneString(),
        };
    }
    return {};
};

const getEmployerFieldValidation = ({ employerFieldIsRequired }) => {
    const { exists } = employerFieldIsRequired;
    if (exists) {
        return { Employer: Yup.string() };
    }
    return {};
};

// -------------------------------------------------------------------------
// MARK: Form level validation schema.
// -------------------------------------------------------------------------
const getConfirmationFieldsValidations = (settings) => {
    const {
        allFieldsAndGroups,
        conditionalLogicOptions,
        detailsPageFieldsData = {},
        displayableCustomFields,
        subtotal = 0,
        total = 0,
        transaction = {},
    } = settings;
    const { country } = transaction;

    const { employerFieldIsRequired, phoneFieldIsRequired } =
        detailsPageFieldsData;

    // Treat all fields as optional for purposes of validation.
    const optionalDisplayableCustomFields = displayableCustomFields.map(
        (field) => {
            const newField = {
                ...field,
                required: false,
                amtReq: 0,
            };
            return newField;
        },
    );

    // -------------------------------------------------------------------------
    // NOTE: Though Opt-in, Privacy Options, and Anonymity fields are all possible
    // system fields to be included in Confirmation Fields, they are all checkboxes,
    // and therefore all responses are automatically valid.
    // -------------------------------------------------------------------------
    const confirmationFieldsSchema = {
        ...getAllCustomFieldValidation({
            allFieldsAndGroups,
            allCustomFields: optionalDisplayableCustomFields,
            conditionalLogicOptions,
            savedValues: {},
            subtotal,
        }),
        ...getPhoneFieldValidation({ phoneFieldIsRequired, total, country }),
        ...getEmployerFieldValidation({
            employerFieldIsRequired,
            total,
        }),
    };

    return confirmationFieldsSchema;
};

const getValidationSchema = (settings) => {
    const validationShape = {
        ...getConfirmationFieldsValidations(settings),
    };
    const schema = Yup.object().shape(validationShape);

    return schema;
};

export default getValidationSchema;
