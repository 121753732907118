import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { constants } from "@qgiv/core-js";
import { useFormikContext } from "formik";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import { selectGiftDetails } from "../../../../redux/slices/donationDetailsSlice";
import { selectIsSinglePageForm } from "../../../../redux/slices/appSettingsSlice";
import DonorAccountCreation from "./DonorAccountCreation";

const ConnectedDonorAccountCreation = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { PAYMENT_DETAILS },
        },
    } = constants;

    const giftDetails = useSelector(selectGiftDetails);
    const { hasSelectedRecurringDonation } = giftDetails;
    const isSinglePageForm = useSelector(selectIsSinglePageForm);

    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, PAYMENT_DETAILS),
    );
    const { handleChange, handleBlur, values, errors, touched, setFieldValue } =
        useFormikContext();

    const {
        Create_Account_Password = false,
        Password = "",
        Password_Confirm = "",
    } = values;

    const {
        options: {
            oneTimeGiftSectionHeading = "Remember Me Next Time",
            oneTimeGiftAccountCreateLabel = "I'll add a password to save my details",
            recurringGiftSectionHeading = "Manage My Gift Online",
            recurringGiftAccountCreateLabel = "I'll add a password to edit my gift online",
        },
    } = control;

    const sectionHeading = hasSelectedRecurringDonation
        ? recurringGiftSectionHeading
        : oneTimeGiftSectionHeading;

    const checkboxLabel = hasSelectedRecurringDonation
        ? recurringGiftAccountCreateLabel
        : oneTimeGiftAccountCreateLabel;

    const isContentExpanded = Create_Account_Password;

    const handleCheckboxChange = (e) => {
        handleChange(e);

        if (!Create_Account_Password) {
            setFieldValue("Password", "");
            setFieldValue("Password_Confirm", "");
        }
        // -------------------------------------------------------------------------
        // NOTE: Payment is automatically saved if a recurring donation is made and
        // the donor adds a password
        // -------------------------------------------------------------------------
        if (hasSelectedRecurringDonation) {
            if (Create_Account_Password) {
                setFieldValue("Save_Payment", true);
            } else {
                setFieldValue("Save_Payment", false);
            }
        }
    };

    useEffect(() => {
        // -------------------------------------------------------------------------
        // NOTE: On Single Page Form, if a recurring donation is selected, the initial
        // value for the Create_Account_Password field should be true. If a one-time
        // donation is selected, the initial value should be false. This is only necessary
        // for Single Page Form because the frequency of the donation is selected on the
        // same page as the donor account creation checkbox. This will only run if the
        // user has not interacted with the any of the Donor Password fields.
        // -------------------------------------------------------------------------
        const donorAccountPasswordHasBeenTouched =
            touched.Create_Account_Password ||
            touched.Password ||
            touched.Password_Confirm;

        if (isSinglePageForm && !donorAccountPasswordHasBeenTouched) {
            if (hasSelectedRecurringDonation) {
                setFieldValue("Create_Account_Password", true);
            }
            if (!hasSelectedRecurringDonation) {
                setFieldValue("Create_Account_Password", false);
            }
        }
    }, [
        hasSelectedRecurringDonation,
        touched,
        setFieldValue,
        isSinglePageForm,
    ]);

    const passwordAndConfirmationProps = {
        handleChange,
        Password,
        Password_Confirm,
        handleBlur,
        touched,
        errors,
        noPadding: true,
    };

    const donorAccountCreationProps = {
        checkboxLabel,
        Create_Account_Password,
        handleBlur,
        handleChange: handleCheckboxChange,
        isContentExpanded,
        passwordAndConfirmationProps,
        sectionHeading,
    };

    return <DonorAccountCreation {...donorAccountCreationProps} />;
};

ConnectedDonorAccountCreation.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedDonorAccountCreation;
