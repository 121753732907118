import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
    getIndexOfOtherAmount,
    getNameOfOtherAmountField,
    getOtherAmountContainerClasses,
    getOtherAmountData,
    getOtherAmountLabel,
} from "@qgiv/donation-form";
import {
    selectAllDonationDetails,
    updateGiftDetails,
} from "../../../../../../../../redux/slices/donationDetailsSlice";
import OtherAmountRadioOption from "../../../../../../../common/StandardAmountOption/OtherAmountRadioOption";

/**
 * @typedef {import("../restrictionCardsAmountsTypes").PropsToShareAmongAmountsComponents} PropsToShareAmongAmountsComponents
 */

// -------------------------------------------------------------------------
// NOTE:
// The otherAmountInputFieldName value is used because it gets transformed
// into the name key inside of the OtherAmountRadioOption.js component and
// then passed into CurrencyInput.js where a rogue call to setFieldValue()
// tries to update whatever value you pass in here in Formik every time the
// currency value is changed. However, in this case we actually do not want
// to update the regular version of the other amount field, however I ended
// up deciding that passing in this value was probably the best way forward
// as in the MR implementation of the currency input the offending
// setFieldValue() function gets called with an empty string every time the
// value in the currency input is changed. And even though this is weird we
// were stuck between a rock and had place and this is exactly how the
// legacy version of the MR feature worked without causing any issues.
// -------------------------------------------------------------------------
// -------------------------------------------------------------------------
// NOTE:
// I have noticed some behavior that I think is weird related to how amount
// selections persist from one tab to another:
// 1. handleOtherInputButtonFocus()
// Currently the handleOtherInputButtonFocus() logic does not select the
// other amount on one tab when it is selected on another. I did this
// because that is how the other amount currently works, however I'm not
// sure if this is a bug with the other amount because:
// a. This seems inconsistent with how the amounts logic typically works.
// b. We are calling the determineCorrespondingEmptyValueToUpdate() function
// inside of the gift step implementation of ConnectedOtherAmountRadio.js.
// However we do not appear to be passing it the isOtherInput flag that it
// would appear to be what you need in order for the other amount to
// behave as expected in the other amount use case.
// 2.handleOtherInputButtonKeydown()
// If I move focus from the other amount to a standard amount using the
// arrow keys the selection of the standard amount does not persist on the
// other tab like how it would if I selected it using my mouse. This is what
// my implementation of the keydown logic currently does, but only because
// the default implementation of the gift step does this. This seems weird
// to me so I figured I would double check this as well.
// -------------------------------------------------------------------------
/**
 *
 * @param {PropsToShareAmongAmountsComponents} props
 * @returns {React.ReactElement}
 */
const ConnectedOtherAmountRadioOption = ({
    amountsToDisplayWithRestriction,
    buttonStyle,
    existingMultiRestrictionFieldValue,
    multiRestrictionAmountIdFieldName,
    multiRestrictionFieldName,
    nameOfAmountIdField,
    standardAndOtherAmountRefs,
}) => {
    // Triggers a focus event which is used to forcefully update the
    // placeholder value in the other amount
    const [shouldInputBeFocused, setShouldInputBeFocused] = useState(false);
    const dispatch = useDispatch();
    const donationDetails = useSelector(selectAllDonationDetails);
    const formikContext = useFormikContext();
    const { giftDetails = {} } = donationDetails;
    const {
        setFieldValue = () => {},
        setValues = () => {},
        values = {},
    } = formikContext;
    const { hasSelectedRecurringDonation = false } = giftDetails;

    // Create variables that are used by other logic
    const hasSelectedRestriction = existingMultiRestrictionFieldValue.Checked;
    const numberOfAmounts = amountsToDisplayWithRestriction.length;
    const otherAmountToDisplay = getOtherAmountData(
        amountsToDisplayWithRestriction,
    );
    const otherLabel = getOtherAmountLabel(
        otherAmountToDisplay,
        amountsToDisplayWithRestriction,
    );
    const nameOfOtherAmountField = getNameOfOtherAmountField(
        hasSelectedRecurringDonation,
    );

    /**
     *
     * @param {number} indexOfElement Index of amount where focus will be moved to.
     * @returns {undefined}
     */
    const moveFocusToElementAfterKeydown = (indexOfElement) => {
        const element = standardAndOtherAmountRefs[indexOfElement].current;
        const { value = "" } = element;
        const amountId = Number(value);

        const newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            [nameOfAmountIdField]: amountId,
        };

        element.focus();

        setFieldValue(multiRestrictionFieldName, newMultiRestrictionFieldValue);
        dispatch(
            updateGiftDetails({
                [multiRestrictionFieldName]: newMultiRestrictionFieldValue,
            }),
        );
    };

    // -------------------------------------------------------------------------
    // TODO: Figure out how to properly type the target so that you no longer
    // get a TS error there.
    // -------------------------------------------------------------------------
    /**
     *
     * @param {React.KeyboardEvent} e
     * @returns {undefined}
     * Ensures user can use the arrow keys to navigate from the other amount
     * to the standard amounts.
     */
    const handleOtherInputButtonKeydown = (e) => {
        const hasAnotherAmount = numberOfAmounts > 1;

        if (hasAnotherAmount) {
            const { key = "", target = {} } = e;
            // @ts-ignore
            const { selectionStart = 0, value = "" } = target;
            const isCursorAtStartOfValue = selectionStart === 0;
            const isCursorAtEndOfValue = selectionStart === value.length;

            if (
                isCursorAtEndOfValue &&
                (key === "ArrowDown" || key === "ArrowRight")
            ) {
                const indexOfFirstAmount = 0;
                moveFocusToElementAfterKeydown(indexOfFirstAmount);
            } else if (
                isCursorAtStartOfValue &&
                (key === "ArrowUp" || key === "ArrowLeft")
            ) {
                const indexOfLastAmount = numberOfAmounts - 1;
                const indexOfPreviousElement = indexOfLastAmount - 1;
                moveFocusToElementAfterKeydown(indexOfPreviousElement);
            }
        }
    };

    /**
     *
     * @param {React.BaseSyntheticEvent} e
     * @returns {undefined}
     * Placeholder text is modified in order to remove the other amount name
     * so that the donor can see what they are typing when the other amount
     * field is first clicked.
     */
    const handleOtherInputButtonFocus = (e) => {
        e.target.placeholder = "";
        setShouldInputBeFocused(true);

        const newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            [nameOfAmountIdField]: Number(otherAmountToDisplay.id),
        };

        setFieldValue(multiRestrictionFieldName, newMultiRestrictionFieldValue);
        dispatch(
            updateGiftDetails({
                [multiRestrictionFieldName]: newMultiRestrictionFieldValue,
            }),
        );
    };

    /**
     *
     * @param {React.BaseSyntheticEvent} unformattedCurrencyValue Value
     * generated by the CurrencyInput component.
     * @returns {undefined}
     * setValues() is used to prevent the rogue setFieldValue() call in
     * CurrencyInput.js from having any effect on the Formik values object
     * which is exactly how the Formik values object was left unaffected in the
     * legacy version of the donation form.
     */
    const handleChange = (unformattedCurrencyValue) => {
        const newAmountValue = unformattedCurrencyValue || "0.00";

        const newMultiRestrictionFieldValue = {
            ...existingMultiRestrictionFieldValue,
            [nameOfOtherAmountField]: newAmountValue,
        };
        const newValues = {
            ...values,
            [multiRestrictionFieldName]: newMultiRestrictionFieldValue,
        };

        // This function expects that an amount value will be passed in.
        // If the value is a Synthetic Event object, we should not update
        // redux with the full event object.
        const isExpectedAmountValueNotSyntheticEvent =
            typeof unformattedCurrencyValue === "string" ||
            typeof unformattedCurrencyValue === "number";

        if (isExpectedAmountValueNotSyntheticEvent) {
            // Update Other amount in Formik and Redux
            setValues(newValues);
            dispatch(
                updateGiftDetails({
                    [multiRestrictionFieldName]: newMultiRestrictionFieldValue,
                }),
            );
        } else {
            // If we are in this function and the value is a Synthetic Event object we are likely on iOS.
            // In this case we need to manually call the handleOtherInputButtonFocus function to set the
            // selected amount id and update the corresponding value in the formik form. iOS does not call the
            // regular onFocus event handler when the input is focused.
            handleOtherInputButtonFocus(unformattedCurrencyValue);
        }
    };

    /**
     *
     * @param {React.BaseSyntheticEvent} e
     * @returns {undefined}
     * Placeholder text is modified so the other amount name can be added back
     * to the field so that if the user never entered a value into the field
     * the other amount name is displayed again after blur.
     */
    const handleOtherInputButtonBlur = (e) => {
        e.target.placeholder = otherLabel;
        setShouldInputBeFocused(false);
    };

    // Reassign and create variables as needed
    const buttonSelectedFieldName = multiRestrictionAmountIdFieldName;
    const checked =
        existingMultiRestrictionFieldValue[nameOfAmountIdField] ===
        otherAmountToDisplay.id;
    const containerClasses = getOtherAmountContainerClasses(otherLabel);
    const idOfOtherAmount = otherAmountToDisplay.id;
    const index = getIndexOfOtherAmount(amountsToDisplayWithRestriction);
    // See note at top of file
    const otherAmountInputFieldName = getNameOfOtherAmountField(
        hasSelectedRecurringDonation,
    );

    // Need to pass this down in order for the CurrencyInput component to
    // reload the stored other amount value after toggling between tabs
    const multiRestrictionCurrencyInputProps = {
        isMultiRestrictionCustomAmount: true,
        relatedCheckboxName: multiRestrictionFieldName,
    };

    const otherAmountRadioOptionProps = {
        buttonSelectedFieldName,
        buttonStyle,
        checked,
        containerClasses,
        handleChange,
        handleOtherInputButtonBlur,
        handleOtherInputButtonFocus,
        handleOtherInputButtonKeydown,
        idOfOtherAmount,
        index,
        multiRestrictionCurrencyInputProps,
        otherAmountInputFieldName,
        otherLabel,
        shouldInputBeFocused,
        standardAndOtherAmountRefs,
    };

    // If the other amount is the only amount associated with a restriction
    // auto-focus on the other amount when that restriction is first selected
    useEffect(() => {
        const isOnlyAmount = numberOfAmounts === 1;
        if (hasSelectedRestriction && isOnlyAmount) {
            setShouldInputBeFocused(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSelectedRestriction]);

    return <OtherAmountRadioOption {...otherAmountRadioOptionProps} />;
};

export default ConnectedOtherAmountRadioOption;
