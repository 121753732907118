import React from "react";
import PropTypes from "prop-types";
import { LoadingDots } from "@qgiv/core-react";
import RecipientSearchItem from "../RecipientSearchItem";

const RecipientSearchList = ({
    donationRecipientSettings = {},
    eventRecipient = {},
    handleSelectRecipient = () => {},
    isMediumScreen = false,
    listLoading = false,
    searchCaptainLabel,
    searchList = [],
    searchListHeight = "0px",
    searchNoFundraisersLink = "",
    searchNoFundraisersYetMessage = "",
    searchNoResultsLink = "",
    searchNoResultsMessage = "",
    searchTeamLabel = "",
    searchTerm = "",
}) => {
    const hasItemsInList = searchList.length > 0 && !listLoading;
    const hasEmptyList = searchList.length === 0 && !listLoading;
    // there are not any results to display for this event yet
    const hasEmptyListFromStart = hasEmptyList && searchTerm === "";
    const displayEventLink = donationRecipientSettings.enableDonationToEvent;

    return (
        <div
            className="recipient-search__list"
            style={{
                height: searchListHeight,
                maxHeight: searchListHeight,
            }}
        >
            {/* Loading */}
            {listLoading ? <LoadingDots center /> : null}
            {/* Search List has recipients to display */}
            {hasItemsInList
                ? searchList.map((recipient) => (
                      <RecipientSearchItem
                          key={recipient.key}
                          recipient={recipient}
                          isMediumScreen={isMediumScreen}
                          handleSelectRecipient={handleSelectRecipient}
                          searchCaptainLabel={searchCaptainLabel}
                          searchTeamLabel={searchTeamLabel}
                      />
                  ))
                : null}
            {/* Search List does not have recipients to display */}
            {hasEmptyList ? (
                <div className="recipient-search__empty-list">
                    <p>
                        {hasEmptyListFromStart
                            ? searchNoFundraisersYetMessage
                            : searchNoResultsMessage}
                    </p>
                    {displayEventLink ? (
                        <button
                            onClick={() =>
                                handleSelectRecipient(eventRecipient)
                            }
                            type="button"
                            className="button back-button back-button__link recipient-search__event-button"
                        >
                            {hasEmptyListFromStart
                                ? searchNoFundraisersLink
                                : searchNoResultsLink}
                        </button>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

RecipientSearchList.propTypes = {
    donationRecipientSettings: PropTypes.shape({
        enableClassificationForDonation: PropTypes.bool.isRequired,
        enableDonationToEvent: PropTypes.bool.isRequired,
        enableDonationToParticipant: PropTypes.bool.isRequired,
        enableDonationToTeam: PropTypes.bool.isRequired,
    }).isRequired,
    eventRecipient: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
    }).isRequired,
    handleSelectRecipient: PropTypes.func,
    listLoading: PropTypes.bool,
    isMediumScreen: PropTypes.bool,
    searchListHeight: PropTypes.string,
    searchList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
        }),
    ),
    searchTerm: PropTypes.string,
    searchCaptainLabel: PropTypes.string.isRequired,
    searchTeamLabel: PropTypes.string.isRequired,
    searchNoResultsMessage: PropTypes.string.isRequired,
    searchNoFundraisersYetMessage: PropTypes.string.isRequired,
    searchNoFundraisersLink: PropTypes.string.isRequired,
    searchNoResultsLink: PropTypes.string.isRequired,
};

export default RecipientSearchList;
