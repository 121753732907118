import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { constants, currencyString } from "@qgiv/core-js";
import { useBreakpoints } from "@qgiv/core-react";
import {
    getGoalAndDonationDataForSelectedRecipient,
    getRecipientIconGlyph,
} from "@qgiv/donation-form";
import { selectCmsControlByIdByPage } from "../../../../redux/slices/cmsSettingsSlice";
import {
    selectGiftDetails,
    selectSelectedRecipient,
    selectSelectedRecipientIsPreset,
    selectSubtotalGift,
    selectTotalGift,
    updateSelectedRecipient,
} from "../../../../redux/slices/donationDetailsSlice";
import { updateContentToDisplay } from "../../../../redux/slices/appSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";
import { ModalContentReplacerEnums } from "../../../common/ModalContentReplacer";
import SelectedRecipient from "./SelectedRecipient";
import { selectIsCms } from "../../../../redux/slices/configSettingsSlice";

const ConnectedSelectedRecipient = ({ controlId }) => {
    const {
        ENUMS: {
            CmsPageStandardizer: { DONATION_AMOUNTS },
            DonationRecipientThermometerDisplay,
        },
    } = constants;
    const [displayLottie, setDisplayLottie] = useState(true);
    const { hasSelectedRecurringDonation } = useSelector(selectGiftDetails);
    const control = useSelector((state) =>
        selectCmsControlByIdByPage(state, controlId, DONATION_AMOUNTS),
    );
    const { options = {} } = control;
    const {
        selectedRecipientSupportingLabel = "Supporting",
        selectedRecipientOneTimeImpact = "Your gift gets them to %GoalPercentage% of their %GoalAmount% goal!",
        selectedRecipientRecurringImpact = "Your first gift gets them to %GoalPercentage% of their %GoalAmount% goal and your ongoing gift goes even further!",
        selectedRecipientOneTimeReached = "AMAZING! Your gift gets them to %GoalPercentage% of their %GoalAmount% goal. You're changing the world!",
        selectedRecipientRecurringReached = "AMAZING! Your first gift gets them to %GoalPercentage% of their %GoalAmount% goal and your ongoing gift goes even further. You're changing the world!",
        hideCelebrationAnimationsWhenGoalReached = false,
        recipientThermometerAndMessageViewType = DonationRecipientThermometerDisplay.GOAL_NOT_MET,
    } = options;

    // use subtotal for is someone has made a selection to display text below thermometer
    // there are settings related to gift assist being included in the thermometer (general) :(
    const total = useSelector(selectTotalGift);
    const subtotal = useSelector(selectSubtotalGift);

    const recipient = useSelector(selectSelectedRecipient);
    const isRecipientPreset = useSelector(selectSelectedRecipientIsPreset);
    const { isEditable = true } = recipient;

    const hideRecipientActions = isRecipientPreset && !isEditable;

    const { type, fundraisingGoal } = recipient;

    const { currency, theme } = useSelector(selectAllFormSettings);

    // default value for theme.color is an empty string.
    // If theme.color is empty string, we need to use the default primary color
    const themeColor = theme.color;
    const color = themeColor === "" ? "#1F6FD9" : themeColor;

    const formattedFundraisingGoal = currencyString(fundraisingGoal, currency);

    const recipientIconGlyph = getRecipientIconGlyph(type);

    const { isSmallScreen } = useBreakpoints();

    const isCms = useSelector(selectIsCms);

    const {
        hasReachedGoal,
        hasReachedGoalOnPageLoad,
        hasValidFundraisingGoal,
        percentOfDonationTowardsGoal,
        percentOfGoalToDisplayOnThermometer,
        percentOfGoalWithDonation,
    } = getGoalAndDonationDataForSelectedRecipient({
        isSmallScreen,
        recipient,
        total,
    });

    // Resets the displayLottie state to the default when the goal is reached
    // then the user makes a new selection.  This will allow the lottie animation
    // to play again when a new selection is made that exceeds goal.
    useEffect(() => {
        if (!hasReachedGoal && !displayLottie) {
            setDisplayLottie(true);
        }
    }, [displayLottie, hasReachedGoal]);

    const displayThermometerAndMessage =
        recipientThermometerAndMessageViewType ===
            DonationRecipientThermometerDisplay.ALWAYS ||
        (recipientThermometerAndMessageViewType ===
            DonationRecipientThermometerDisplay.GOAL_NOT_MET &&
            !hasReachedGoalOnPageLoad);

    const dispatch = useDispatch();

    const handleEdit = () => {
        // open search ui
        dispatch(
            updateContentToDisplay(
                ModalContentReplacerEnums.RECIPIENT_SEARCH_UI,
            ),
        );
    };

    const handleRemove = () => {
        // unset selected recipient
        dispatch(updateSelectedRecipient({}));
    };

    return (
        <SelectedRecipient
            color={color}
            displayLottie={displayLottie}
            displayThermometerAndMessage={displayThermometerAndMessage}
            formattedFundraisingGoal={formattedFundraisingGoal}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            hasReachedGoal={hasReachedGoal}
            hasSelectedRecurringDonation={hasSelectedRecurringDonation}
            hasValidFundraisingGoal={hasValidFundraisingGoal}
            hideCelebrationAnimationsWhenGoalReached={
                hideCelebrationAnimationsWhenGoalReached
            }
            isCms={isCms}
            isSmallScreen={isSmallScreen}
            hideRecipientActions={hideRecipientActions}
            recipient={recipient}
            recipientIconGlyph={recipientIconGlyph}
            percentOfDonationTowardsGoal={percentOfDonationTowardsGoal}
            percentOfGoalWithDonation={percentOfGoalWithDonation}
            percentOfGoalToDisplayOnThermometer={
                percentOfGoalToDisplayOnThermometer
            }
            selectedRecipientSupportingLabel={selectedRecipientSupportingLabel}
            selectedRecipientOneTimeImpact={selectedRecipientOneTimeImpact}
            selectedRecipientRecurringImpact={selectedRecipientRecurringImpact}
            selectedRecipientOneTimeReached={selectedRecipientOneTimeReached}
            selectedRecipientRecurringReached={
                selectedRecipientRecurringReached
            }
            setDisplayLottie={setDisplayLottie}
            subtotal={subtotal}
        />
    );
};

ConnectedSelectedRecipient.propTypes = {
    controlId: PropTypes.number.isRequired,
};

export default ConnectedSelectedRecipient;
