import React from "react";
import { useSelector } from "react-redux";
import { PaymentIframe } from "@qgiv/core-react";
import { selectConfig } from "../../../../redux/slices/configSettingsSlice";
import { selectAllFormSettings } from "../../../../redux/slices/formSettingsSlice";

// -------------------------------------------------------------------------
// TODO:
// 1. Once the proof-of-concept has been flushed out we need to figure out
// a way to ensure that the URL that is used for this component never
// changes, event when forms are deployed using custom domains. Will
// probably involve hardcoding what is typically the HTTP_HOST value to
// be https://secure.qgiv.com.
// -------------------------------------------------------------------------
/**
 *
 * @returns {React.ReactElement}
 */
const ConnectedPaymentIframe = () => {
    const config = useSelector(selectConfig);
    const formSettings = useSelector(selectAllFormSettings);
    const { HTTP_HOST = "" } = config;
    const { id = 0 } = formSettings;

    const formIdParam = `formId=${id}`;

    const src = `${HTTP_HOST}/api/v1/donationform/paymentInitialState?${formIdParam}`;

    return <PaymentIframe src={src} />;
};

export default ConnectedPaymentIframe;
