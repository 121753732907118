import * as Sentry from "@sentry/browser";

import constants from "../constants";
import env from "../env";
import log from "../log";

// Flag to enable/disable Sentry based on environment and host. Enabled for
// production builds on staging, main, and secure.
export const sentryIsEnabled =
    env.IS_PROD() && (env.IS_STAGING_HOST() || env.IS_PROD_HOST());

export const getDSNFromProject = (project) => {
    switch (project) {
        case "react-data-fe":
            return "https://fe515d7ca81dd12f42c6d6c337692e88@o1045681.ingest.us.sentry.io/4507289419644928";
        case "react-fundraising-fe":
            return "https://efd81e27c2f8647ef78c1e5b4ef99aeb@o1045681.ingest.us.sentry.io/4507289424953344";
        case "react-mobile-fe":
            return "https://e71ad1d0f22ee6e6e092405b29516070@o1045681.ingest.us.sentry.io/4507289423052800";

        default:
            return "";
    }
};

export const getFileNameFlagsFromStackTrace = (event) => {
    const fileNameFlagsFromStacktrace = {
        hasGTMFilenameInStacktrace: false,
        hasQgivAppFilenameInStacktrace: false,
    };
    const values = event?.exception?.values || [];

    const setFileNameFlagsFromStackTrace = (frame) => {
        const filename = frame?.filename || "";
        const hasGTMFileName =
            filename.includes("googletagmanager.com") ||
            filename.includes("gtm.js");
        const hasQgivFileName =
            filename.includes("/frontend/apps/") ||
            filename.includes("/frontend/packages");
        if (
            !fileNameFlagsFromStacktrace.hasGTMFilenameInStacktrace &&
            hasGTMFileName
        ) {
            fileNameFlagsFromStacktrace.hasGTMFilenameInStacktrace = true;
        }
        if (
            !fileNameFlagsFromStacktrace.hasQgivAppFilenameInStacktrace &&
            hasQgivFileName
        ) {
            fileNameFlagsFromStacktrace.hasQgivAppFilenameInStacktrace = true;
        }
    };

    values.forEach((value) => {
        const frames = value?.stacktrace?.frames || [];
        frames.forEach(setFileNameFlagsFromStackTrace);
    });

    return fileNameFlagsFromStacktrace;
};

// LINK - https://qgiv.atlassian.net/browse/QG-29641
// LINK - https://github.com/getsentry/sentry-javascript/issues/3440
const microsoftOutlookSafeLinkCrawlerError =
    "Non-Error promise rejection captured";

// The easiest way to make Sentry usable is to ignore the list of common errors
// in custom client code that we cannot remove
const customClientCodeErrors = [
    "'get' on proxy: property 'javaEnabled' is a read-only and non-configurable data property",
    "$ is not defined",
    "Can't find variable: $",
    "Can't find variable: gtag",
    "Can't find variable: jQuery",
    "Cannot read properties of null (reading 'addEventListener')",
    "Failed to execute 'appendChild' on 'Node': Unexpected token '<'",
    "gtag is not defined",
    "jQuery is not defined",
    "ReferenceError: jQuery is not defined",
    "Unexpected token '<'",
    `null is not an object (evaluating 'document.getElementById("fee-coverage").checked = true')`,
    `null is not an object (evaluating 'document.getElementById("one-time").hasAttribute')`,
];

const ignoreErrors = [
    microsoftOutlookSafeLinkCrawlerError,
    ...customClientCodeErrors,
];

export const beforeSend = (event) => {
    const newEvent = { ...event };
    const { hasGTMFilenameInStacktrace, hasQgivAppFilenameInStacktrace } =
        getFileNameFlagsFromStackTrace(event);

    if (!hasQgivAppFilenameInStacktrace) {
        newEvent.tags = newEvent.tags || {};
        newEvent.tags.noise = "No Qgiv filename found in stacktrace";
    }

    // Do not flood Sentry with Google Tag Manager errors from clients
    // whose GTM containers are configured incorrectly
    if (hasGTMFilenameInStacktrace) {
        return null;
    }

    return newEvent;
};

/**
 * Initialize Sentry
 * @param {object} tags Add tags to transactions
 * @param {string} tags.app Tells Sentry which app sent the transaction
 * @param {string} project Assign transactions to a Sentry project
 */
export const initSentry = (tags, project) => {
    if (!sentryIsEnabled) return;

    const environment = window.location.host.includes("qgiv")
        ? window.location.host
        : "secure.qgiv.com";

    Sentry.init({
        beforeSend,
        denyUrls: [/^chrome-extension:\/\//i],
        dsn: getDSNFromProject(project),
        environment,
        ignoreErrors,
        initialScope: { tags },
        integrations: [
            Sentry.browserTracingIntegration({
                enableInp: true,
            }),
            Sentry.breadcrumbsIntegration({
                // Disable sending browser console messages to Sentry. We
                // decided to do this to lower the possibility of accidentally
                // sending PII.
                console: false,
            }),
            Sentry.replayIntegration(),
        ],
        release: `${project}@${constants.version}`,
        replaysSessionSampleRate: 0,
        // Sample 0.5% of error replays in production. Sample all elsewhere.
        // Chose this number because we currently have a 100k error limit, and a
        // 500 replay limit. This means we can capture 0.5% of replay errors.
        replaysOnErrorSampleRate: env.IS_PROD_HOST() ? 0.005 : 1,
        // Sample 25% of fundraising team production errors. Sample all elsewhere.
        sampleRate:
            project === "react-fundraising-fe" && env.IS_PROD_HOST() ? 0.25 : 1,
        // Sample 5% of production transactions. Sample all elsewhere.
        tracesSampleRate: env.IS_PROD_HOST() ? 0.05 : 1,
    });

    // use getClient to verify that Sentry has been initialized
    if (Sentry.getClient()) {
        log("Sentry performance monitoring initialized");

        // if not on production, add a function to test Sentry error capturing
        if (!env.IS_PROD_HOST()) {
            // @ts-ignore
            window.QGIV = window.QGIV || {};
            // @ts-ignore
            window.QGIV.testSentry = (
                error = "This is a test error to make sure Sentry has been enabled correctly",
            ) => {
                Sentry.captureException(new Error(error));
            };
        }
    }
};
