import * as Yup from "yup";
import moment from "moment";
import {
    ccTypeAccepted,
    constants,
    cvvLength,
    isDisplayable,
    validateCCNum,
    validateRouting,
} from "@qgiv/core-js";

// -------------------------------------------------------------------------
// MARK: Common error messages
// -------------------------------------------------------------------------

// VALIDATION_ERROR_MESSAGE.REQUIRED is supposed to be combined with the field's label
// to compose a complete "<field> is Required" error message.
// The other properties in the VALIDATION_ERROR_MESSAGE object are meant to be used for
// other "field specific" validations, like email & phone, etc. Which don't need a label to be formed.
export const VALIDATION_ERROR_MESSAGE = {
    REQUIRED: "Required",
    EMAIL_FORMAT: "Please enter a valid email address.",
    PHONE_FORMAT: "Please enter a valid phone number.",
    WEBSITE_FORMAT: "Please enter a valid website url.",
    ZIP: "Please enter a valid zip.",
};

// -------------------------------------------------------------------------
// MARK: Local vars
// -------------------------------------------------------------------------
const phoneRegExp = /^[0-9]{10}$/; // Matches BE regex test for US phone numbers
const phoneInternationalRegExp = /^[0-9]{8,15}$/; // number between 8 and 15 digits long
const emailRegExp =
    /^[_A-z0-9-+']+(\.[_A-z0-9-+']+)*@[A-z0-9-]+(\.[A-z0-9-]+)*(\.[A-z]+)$/;
const passwordRegExp = /[!@#$%^&*()_\-=+{};:,<.?>]/;

const websiteRegExp =
    /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

// -------------------------------------------------------------------------
// MARK: Helper functions
// -------------------------------------------------------------------------
// Checks to see if the value in the field is true
export const evaluateIfFieldValueIsTrue = (fieldValue) => fieldValue === true;
export const evaluateIfFieldValueIsFalse = (fieldValue) => fieldValue === false;

// -------------------------------------------------------------------------
// MARK: Custom Field Level Validations
// -------------------------------------------------------------------------
export const validateRequired = (value) => {
    let error;
    if (!value) {
        error = VALIDATION_ERROR_MESSAGE.REQUIRED;
    }
    return error;
};

export const validateEmail = (emailAddress) => {
    const _emailAddress = emailAddress.replace(/\s/g, "");
    return emailRegExp.test(_emailAddress.toLowerCase());
};

export const validatePassword = (password) => {
    // Confirm that the input is not empty
    let groups = 0;
    // Confirm that it has > 8 characters
    if (password.length < 8) {
        return false;
    }
    // Uppercase characters
    if (/[A-Z]/.test(password)) {
        groups++;
    }
    // Lowercase characters
    if (/[a-z]/.test(password)) {
        groups++;
    }
    // Numbers
    if (/[0-9]/.test(password)) {
        groups++;
    }
    // Special characters
    if (passwordRegExp.test(password)) {
        groups++;
    }
    // If the password has at least 2 or more of the above groups
    if (groups >= 2) {
        return true;
    }
    return false;
};

export const validateConfirmPassword = (password, confirmedPassword) => {
    if (password === confirmedPassword) {
        return true;
    }
    return false;
};

export const validatePhone = (phone) => {
    if (phoneRegExp.test(phone)) {
        return true;
    }
    return false;
};

export const validateInternationalPhone = (phone) =>
    phoneInternationalRegExp.test(phone);

export const validateWebsite = (website) => {
    if (websiteRegExp.test(website)) {
        return true;
    }
    return false;
};

export const validateDonationAmount = (value) => {
    const numberValue = Number(value);
    // Confirm that when converted to a number the donation amount string is a positive number
    if (Number.isNaN(numberValue) || numberValue < 0) {
        return false;
    }
    return true;
};

export const validateMatchingGiftAmount = (
    donationAmount,
    matchingRatio,
    value,
) => {
    const donationAmountNumber = Number(donationAmount);
    const matchingAmountNumber = Number(value);
    const maxMatch = donationAmountNumber * matchingRatio;
    if (matchingAmountNumber <= maxMatch) {
        return true;
    }
    return false;
};

export const validateParticipantFundraisingGoal = (
    value,
    minParticipantGoal,
) => {
    const participantFundraisingGoalNumber = Number(value);
    if (participantFundraisingGoalNumber >= minParticipantGoal) {
        return true;
    }
    return false;
};

export const validateCommitmentAmount = (value, commitmentAmount) =>
    Number(value) >= Number(commitmentAmount) === true;

export const validateNewTeamFundraisingGoal = (value, minTeamGoal) => {
    const teamFundraisingGoalNumber = Number(value);
    if (teamFundraisingGoalNumber >= minTeamGoal) {
        return true;
    }
    return false;
};

// -------------------------------------------------------------------------
// NOTE: During our PCI (Payment Card Industry) Audit Qgiv was made aware in
// some instances, credit card data was being stored in our database in fields
// that were not supposed to hold credit card data. Even though we do not
// know exactly how this happened, it was hypothesized that on rare occasions
// autofill was filling some of our form fields with credit card data. Because
// of that this crude form of anti-credit card validation was developed as a
// means of preventing a user our autofill from adding credit card data into
// the First_Name, Last_Name, Address and Address_2 fields as these were the
// fields where this data was found. The idea is that if this issue has not
// been resolved by the next PCI audit, then we can modify this validation
// logic or expand the number of fields that it has been introduced to in
// order ensure that we have resolved this issue.
// -------------------------------------------------------------------------
export const validateIsNotACreditCardNumber = (value) => {
    const lengthOfValue = value && value.length;
    // If a value can be converted to a number then it is quite possible that
    // credit card data was mistakenly entered into this field
    const valueAsNumber = Number(value);
    const isValueANumber = !Number.isNaN(valueAsNumber);
    // Because the overwhelming majority of credit card numbers are between 15
    // and 17 characters we will also check that the value here is between 15
    // and 17 character before flagging it as a possible cc number
    const isLongEnoughToBeCCNumber = lengthOfValue >= 15;
    const isShortEnoughToBeCCNumber = lengthOfValue <= 17;
    let isAPotentialCCNumber = false;

    // Generate flag that we can use to throw the validation error
    if (
        isValueANumber &&
        isShortEnoughToBeCCNumber &&
        isLongEnoughToBeCCNumber
    ) {
        isAPotentialCCNumber = true;
    }

    if (isAPotentialCCNumber) {
        return false;
    }

    return true;
};

/**
 * Takes in a country code to valid the zip/postal code
 * @public
 * @param {string} value The zip/postal code to validate
 * @param {string} [country] The country code ex: US or CA
 * @returns {boolean} True if the value is valid zip/postal code based on the
 *      country
 */
export const validateZipCodeString = (value, country = "US") => {
    switch (country) {
        case "CA":
            return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
                value,
            );
        default:
            return /^\d{5}([-]|\s*)?(\d{4})?$/.test(value);
    }
};

// -------------------------------------------------------------------------
// MARK: Custom Yup Transforms
// -------------------------------------------------------------------------
export const getYupQPhone = () =>
    // Handling validation error: No "${fieldName}" attached in phone error messages to safeguard
    // against potentially wonky field labels. Instead, Let's reuse the standard phone error message.
    Yup.string().when("Country", {
        is: (Country) => Country === "US",
        then: Yup.string().validatePhoneString(),
        otherwise: Yup.string().validateInternationalPhoneString(),
    });

export const getYupQDate = (fieldName = "Date") =>
    Yup.date(`Please enter a valid ${fieldName}.`).transform(
        // eslint-disable-next-line func-names
        function (value, originalValue) {
            const parseFormats = ["MM-YYYY", "MM-DD-YYYY"];
            const invalidDate = new Date("");
            if (this.isType(value)) return value;
            // the default coercion transform failed so let's try it with Moment instead
            const _value = moment(originalValue, parseFormats);
            return _value.isValid() ? _value.toDate() : invalidDate;
        },
    );

export const getYupQNumber = (fieldName = "Number") =>
    Yup.number(`Please enter a number for ${fieldName}.`).transform(
        // eslint-disable-next-line func-names
        function (value, originalValue) {
            if (this.isType(value)) return value;
            const _value = parseFloat(originalValue);
            return _value || null;
        },
    );

export const getYupQAmount = (fieldName = "Amount") =>
    Yup.number(`Please enter a valid amount for ${fieldName}.`).transform(
        // eslint-disable-next-line func-names
        function (value) {
            if (this.isType(value)) return value;
            // Trigger validation as if for a 0 amount.
            return 0;
        },
    );

// -------------------------------------------------------------------------
// MARK: Custom Yup Validators
// -------------------------------------------------------------------------
/**
 * @public
 * @function validateCVV
 * @param {string} message Default invalid message
 * @description Validates a CVV so long as there's a parent key of "Card_Number"
 * @returns {Yup} Yup instance with the CVV test
 */
export function validateCVV(message = "Please enter a valid CVV.") {
    // eslint-disable-next-line func-names
    return this.test("is-valid-cvv", message, function (value) {
        // Cannot use an arrow function for the callback function as we need access
        // to the this object as that is what gives us access to the Password field
        // via this.parent
        const ccNumber = this.options.parent.Card_Number;
        if (value && value.length > 0 && ccNumber) {
            const targetLength = cvvLength(ccNumber);
            const isValidCVV = value.length === targetLength;
            return isValidCVV;
        }
        return false;
    });
}

/**
 * @public
 * @function validateCCType
 * @param {string} VisaAmexDisc The available cc methods string
 * @param {string} message The invalid cc message
 * @description Validates if a credit card is of an accepted type
 * @returns {Yup} Yup instance with the cc type test
 */
export function validateCCType(
    VisaAmexDisc,
    message = "This card is not accepted on this form.",
) {
    return this.test("is-valid-cc-type", message, (value) => {
        if (value && value.length > 0) {
            return ccTypeAccepted(VisaAmexDisc, value);
        }
        return false;
    });
}

/**
 * @public
 * @function validateCCNumber
 * @param {string} message The invalid cc number message
 * @description Validates if a credit card number is valid
 * @returns {Yup} Yup instance with the cc number test
 */
export function validateCCNumber(
    message = "Please enter a valid credit card number.",
) {
    return this.test("is-valid-cc-num", message, (value) => {
        if (value && value.length > 0) {
            return validateCCNum(value);
        }
        return false;
    });
}

/**
 * @public
 * @function validateRoutingNumber
 * @param {string} message The invalid routing number message
 * @description Validates if a routing number is valid
 * @returns {Yup} Yup instance with the routing number test
 */
export function validateRoutingNumber(
    message = "Please enter a valid routing number.",
) {
    return this.test("is-valid-routing", message, (value) => {
        if (value && value.length > 0) {
            return validateRouting(value);
        }
        return false;
    });
}

/**
 * @public
 * @function validatePhoneString
 * @param {string} message The invalid phone string message
 * @description Validates if a phone string is valid
 * @returns {Yup} Yup instance with the phone string test
 */
export function validatePhoneString(
    message = VALIDATION_ERROR_MESSAGE.PHONE_FORMAT,
) {
    return this.test("is-valid-phone", message, (value) => {
        // Assuming that a required() validation method has been run prior to
        // validatePhoneString() allows us to use this custom validation method
        // with optional strings
        if (value && value.length > 0) {
            return validatePhone(value);
        }
        return true;
    });
}

/**
 * @public
 * @function validateInternationalPhoneString
 * @param {string} message The invalid phone string message
 * @description Validates if a phone string is valid for international phones
 * @returns {Yup} Yup instance with the phone string test
 */
export function validateInternationalPhoneString(
    message = VALIDATION_ERROR_MESSAGE.PHONE_FORMAT,
) {
    return this.test("is-valid-international-phone", message, (value) => {
        if (value && value.length > 0) {
            return validateInternationalPhone(value);
        }
        return true;
    });
}

/**
 * @public
 * @function validateDonationAmountString
 * @param {string} message The invalid donation amount message
 * @description Validates if a string is valid donation amount. Valid donation
 *              amounts include strings that can be converted to decimal numbers
 *              that are 0 or greater
 * @returns {Yup} Yup instance with the donation amount test
 */
export function validateDonationAmountString(
    message = "Please enter a valid donation amount.",
) {
    return this.test("is-valid-donation-amount", message, (value) => {
        // Empty strings are valid as well as positive numbers with fractional
        // digits (decimals)
        if (value && value.length > 0) {
            return validateDonationAmount(value);
        }
        return true;
    });
}

/**
 * @function validateWebsiteString
 * @param {string} message The invalid website message
 * @description Validates if a website url is valid
 * @returns {Yup} Yup instance with the website url test
 */
export function validateWebsiteString(
    message = VALIDATION_ERROR_MESSAGE.WEBSITE_FORMAT,
) {
    return this.test("is-valid-website", message, (value) => {
        // Assuming that a required() validation method has been run prior to
        // validateWebsiteString() allows us to use this custom validation method
        // with optional strings
        if (value && value.length > 0) {
            return validateWebsite(value);
        }
        return true;
    });
}

/**
 * @public
 * @function validatePasswordString
 * @param {string} message The invalid password message
 * @description Validates if a password string is valid
 * @returns {Yup} Yup instance with the password string validation test
 */
export function validatePasswordString(
    message = "A valid password must have a minimum of 8 characters and contain at least two of the following: Special characters, upper case characters, lower case characters, and numbers.",
) {
    return this.test("is-valid-password", message, (value) => {
        if (value && value.length > 0) {
            return validatePassword(value);
        }
        return false;
    });
}

/**
 * @public
 * @function validateConfirmPasswordString
 * @param {string} message The invalid confirm password message
 * @description Validates if a confirm password string is valid
 * @returns {Yup} Yup instance with the confirm password string validation test
 */
export function validateConfirmPasswordString(
    message = "Your password and password confirmation do not match.",
) {
    // Cannot use an arrow function for the callback function as we need access
    // to the this object as that is what gives us access to the Password field
    // via this.parent
    // eslint-disable-next-line func-names
    return this.test("is-valid-confirm-password", message, function (value) {
        if (value && value.length > 0) {
            const {
                parent: { Password },
            } = this;
            // values contains the value of the confirm password input
            return validateConfirmPassword(Password, value);
        }
        return false;
    });
}

/**
 * @public
 * @function validateMatchingGiftAmountString
 * @param {number} matchingRatio The ratio of the maximum matching amount
 * @param {number} subtotal gift amount without gift assist ()
 * @description Validates if a matching gift amount string is valid
 * @returns {Yup} Yup instance with the maximum matching gift amount string
 * validation test
 */
export function validateMatchingGiftAmountString(matchingRatio, subtotal) {
    // Cannot use an arrow function for the callback function as we need access
    // to the this object as that is what gives us access to the Password field
    // via this.parent
    // Assuming that a required() validation method has been run prior to
    // validateMatchingGiftAmountString() allows us to use this custom validation
    // method with optional strings
    const _message = `Amount is more than ${matchingRatio} times the donation amount.`;
    return this.test(
        "is-valid-matching-gift-amount",
        _message,
        // eslint-disable-next-line func-names
        function (value) {
            if (value && value.length > 0) {
                const {
                    parent: { Donation_Amount },
                } = this;

                // -------------------------------------------------------------------------
                // NOTE: Donation_Amount is used in P2P Registration (Formik value)
                // PDR does not store the donation total as a Formik value since it is not an
                // input. Instead it is stored directly in Redux. Therefore we will use
                // Donation_Amount for P2P Registration and subtotal for P2P Donation forms.
                // One of these values should always be undefined.
                // -------------------------------------------------------------------------
                const donationAmount = Donation_Amount || subtotal;
                // value contains the value of the matching amount input
                return validateMatchingGiftAmount(
                    donationAmount,
                    matchingRatio,
                    value,
                );
            }
            return true;
        },
    );
}

/**
 * @public
 * @function validateParticipantFundraisingGoalString
 * @param {number} minParticipantGoal The minimum fundraising goal for a participant
 * @param {string} message The invalid participant fundraising goal message
 * @description Validates if a participant's fundraising goal meets the minimum goal
 * @returns {Yup} Yup instance with the participant fundraising goal string
 * validation test
 */
export function validateParticipantFundraisingGoalString(
    minParticipantGoal,
    message,
) {
    return this.test("is-valid-matching-gift-amount", message, (value) => {
        if (value && value.length > 0) {
            return validateParticipantFundraisingGoal(
                value,
                minParticipantGoal,
            );
        }
        return false;
    });
}

/**
 * @public
 * @function validateCategoryCategoryCommitmentAmount
 * @param {string} commitment commitment amount required for selected category
 * @param {string} message the error message to display
 * @description Validates if a participant's fundraising goal meets the minimum goal
 * @returns {Yup} Yup instance with the participant fundraising goal string
 * validation test
 */
export function validateCategoryCommitmentAmount(commitment, message) {
    return this.test("is-valid-category-with-commitment", message, (value) => {
        if (value && value.length > 0) {
            return validateCommitmentAmount(value, commitment);
        }
        return true;
    });
}

/**
 * @public
 * @function validateNewTeamFundraisingGoalString
 * @param {number} minTeamGoal The minimum fundraising goal for a team
 * @param {string} message The invalid team fundraising goal message
 * @description Validates if a team's fundraising goal meets the minimum team goal
 * @returns {Yup} Yup instance with the team fundraising goal string
 * validation test
 */
export function validateNewTeamFundraisingGoalString(minTeamGoal, message) {
    return this.test("is-valid-new-team-fundraising-goal", message, (value) => {
        // Because this field is not required, we only generate errors if a
        // user has entered a value for this field
        if (value && value.length > 0) {
            return validateNewTeamFundraisingGoal(value, minTeamGoal);
        }
        return true;
    });
}

/**
 * @public
 * @function validateIsNotACreditCardNumberString
 * @param {string} message The invalid value is a credit card number message
 * @description Validates to ensure that a credit card number has not been added into a non-credit card field
 * @returns {Yup} Yup instance with the not a credit card number string test
 */
export function validateIsNotACreditCardNumberString(
    message = "Oops! This looks like a credit card number. Please update with the appropriate information.",
) {
    // In order to ensure that this validation test does not unexpectedly throw
    // an error on optional fields, do not throw an error if this validation is
    // being run on a field that does not have any characters in it.
    return this.test("is-not-credit-card-number", message, (value) => {
        if (value && value.length > 0) {
            return validateIsNotACreditCardNumber(value);
        }
        return true;
    });
}

// -------------------------------------------------------------------------
// MARK: Configure Custom Yup Instance
// -------------------------------------------------------------------------
// CVV
Yup.addMethod(Yup.string, "validateCVV", validateCVV);

// Credit card type
Yup.addMethod(Yup.string, "validateCCType", validateCCType);

// Credit card number
Yup.addMethod(Yup.string, "validateCCNumber", validateCCNumber);

// Check routing number
Yup.addMethod(Yup.string, "validateRoutingNumber", validateRoutingNumber);

// Check phone number
Yup.addMethod(Yup.string, "validatePhoneString", validatePhoneString);

// Check International phone number
Yup.addMethod(
    Yup.string,
    "validateInternationalPhoneString",
    validateInternationalPhoneString,
);

// Check website
Yup.addMethod(Yup.string, "validateWebsiteString", validateWebsiteString);

// Check string as decimal number
Yup.addMethod(
    Yup.string,
    "validateDonationAmountString",
    validateDonationAmountString,
);

// Check password string
Yup.addMethod(Yup.string, "validatePasswordString", validatePasswordString);

// Check confirm password string
Yup.addMethod(
    Yup.string,
    "validateConfirmPasswordString",
    validateConfirmPasswordString,
);

// Check matching gift amount string
Yup.addMethod(
    Yup.string,
    "validateMatchingGiftAmountString",
    validateMatchingGiftAmountString,
);

// Check participant fundraising goal string
Yup.addMethod(
    Yup.string,
    "validateParticipantFundraisingGoalString",
    validateParticipantFundraisingGoalString,
);

// Check participant fundraising goal string
Yup.addMethod(
    Yup.string,
    "validateCategoryCommitmentAmount",
    validateCategoryCommitmentAmount,
);

// Check new team fundraising goal string
Yup.addMethod(
    Yup.string,
    "validateNewTeamFundraisingGoalString",
    validateNewTeamFundraisingGoalString,
);

// Check new team fundraising goal string
Yup.addMethod(
    Yup.string,
    "validateIsNotACreditCardNumberString",
    validateIsNotACreditCardNumberString,
);

// -------------------------------------------------------------------------
// MARK: Re-usable Validation Schema Chunks
// -------------------------------------------------------------------------

// Payment Type
export const getPaymentTypeValidations = () => ({
    Payment_Type: Yup.number().when("Stored_Payment", {
        is: false,
        then: Yup.number().required("Payment Type is required."),
        otherwise: Yup.number(),
    }),
});
// Payment Nickname
export const getPaymentNickNameValidations = () => ({
    Payment_Nickname: Yup.string(),
});
// Credit Card Validations
export const getCCValidations = (VisaAmexDisc) => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    const getCardCvvValidation = () => {
        const baseValidation = Yup.string();
        return {
            Card_CVV: baseValidation.when(["Stored_Payment", "Payment_Type"], {
                is: (storedPayment, paymentType) =>
                    !storedPayment && paymentType === PaymentType.CREDITCARD,
                then: baseValidation.required("CVV is required.").validateCVV(),
                otherwise: baseValidation,
            }),
        };
    };

    const getCardExpiryValidation = () => {
        const today = new Date();
        const lastDayOfLastMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            0,
        );
        const fieldName = "Exp. Date";
        const baseValidation = getYupQDate(fieldName).min(
            lastDayOfLastMonth,
            `Please enter a valid expiration date`,
        );
        return {
            Card_Exp_Date: baseValidation.when(
                ["Stored_Payment", "Payment_Type"],
                {
                    is: (storedPayment, paymentType) =>
                        !storedPayment &&
                        paymentType === PaymentType.CREDITCARD,
                    then: baseValidation.required(
                        `Expiration date is required.`,
                    ),
                    otherwise: baseValidation,
                },
            ),
        };
    };

    const getCardNumberValidation = () => {
        const baseValidation = Yup.string();
        return {
            Card_Number: baseValidation.when(
                ["Stored_Payment", "Payment_Type"],
                {
                    is: (storedPayment, paymentType) =>
                        !storedPayment &&
                        paymentType === PaymentType.CREDITCARD,
                    then: baseValidation
                        .required("Card Number is required.")
                        .validateCCType(VisaAmexDisc)
                        .validateCCNumber(),
                    otherwise: baseValidation,
                },
            ),
        };
    };

    return {
        // card cvv
        ...getCardCvvValidation(),
        // card expiry
        ...getCardExpiryValidation(),
        // card number
        ...getCardNumberValidation(),
    };
};
// Bank Field Validations
export const getBankFieldValidations = () => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    return {
        Routing_Number: Yup.string().when(["Stored_Payment", "Payment_Type"], {
            is: (storedPayment, paymentType) =>
                !storedPayment && paymentType === PaymentType.ECHECK,
            then: Yup.string()
                .required("Routing number is required.")
                .validateRoutingNumber(),
            otherwise: Yup.string(),
        }),
        Account_Number: Yup.string().when(["Stored_Payment", "Payment_Type"], {
            is: (storedPayment, paymentType) =>
                !storedPayment && paymentType === PaymentType.ECHECK,
            then: Yup.string().required("Account number is required."),
            otherwise: Yup.string(),
        }),
    };
};

// Paypal Validations
// Because a PayPal_Token needs to be set in order for legacy PayPal
// transactions or new PayPal transactions (one time or recurring) to be
// processed, this is the hidden field that we rely on for validation here.
export const getPaypalValidations = () => {
    const {
        ENUMS: { PaymentType },
    } = constants;
    return {
        PayPal_Payer_ID: Yup.string(),
        PayPal_Agreement_ID: Yup.string(),

        PayPal_Token: Yup.string().when(["Stored_Payment", "Payment_Type"], {
            is: (storedPayment, paymentType) =>
                !storedPayment && paymentType === PaymentType.PAYPAL,
            then: Yup.string().required("PayPal authorization is required."),
            otherwise: Yup.string(),
        }),
    };
};
// Billing Same As Mailing
export const getBillingSameAsMailingValidations = () => ({
    Billing_Address_Use_Mailing: Yup.bool().required(),
});

// Billing Address Validations
// Do not require Billing data if using Apple Pay as we run validation
// before displaying the payment sheet and the Billing data will be
// injected using Apple Pay data after payment sheet display.
// Forms and Events pass an object containing all billing address
// fields which are active (toggled on via field settings in CP)
// and are appropriate given the users display (desktop, mobile)
// P2P passes an empty object, and a flag of isP2P = true which
// bypasses this additional logic.
export const getBillingAddressValidations = (
    displayedBillingFields,
    isP2P = false,
) => {
    const {
        ENUMS: { PaymentType, FieldType },
    } = constants;

    const isFieldDisplayed = (fields, isDisplayed, fieldEnum) => {
        if (fields?.length > 0) {
            // eslint-disable-next-line no-param-reassign
            isDisplayed =
                fields.filter((field) => Number(field.type) === fieldEnum)
                    .length > 0;
        }
        return isDisplayed;
    };

    // eslint-disable-next-line no-shadow
    const getBillingAddressFieldValidation = (displayedBillingFields) => {
        const billingAddressValidation = Yup.string().when(
            ["Billing_Address_Use_Mailing", "Payment_Type", "Stored_Payment"],
            {
                is: (
                    billingAddressUseMailing,
                    paymentType,
                    storedPayment,
                    fieldIsDisplayed,
                ) => {
                    // eslint-disable-next-line no-param-reassign
                    fieldIsDisplayed = isFieldDisplayed(
                        displayedBillingFields,
                        fieldIsDisplayed,
                        FieldType.BILLING_ADDRESS,
                    );

                    if (isP2P) {
                        // eslint-disable-next-line no-param-reassign
                        fieldIsDisplayed = true;
                    }

                    if (storedPayment) {
                        return false;
                    }
                    return (
                        paymentType !== PaymentType.APPLEPAY &&
                        !billingAddressUseMailing &&
                        fieldIsDisplayed
                    );
                },
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            },
        );
        return billingAddressValidation;
    };

    // eslint-disable-next-line no-shadow
    const getBillingCityFieldValidation = (displayedBillingFields) => {
        const billingCityValidation = Yup.string().when(
            ["Billing_Address_Use_Mailing", "Payment_Type", "Stored_Payment"],
            {
                is: (
                    billingAddressUseMailing,
                    paymentType,
                    storedPayment,
                    fieldIsDisplayed,
                ) => {
                    // eslint-disable-next-line no-param-reassign
                    fieldIsDisplayed = isFieldDisplayed(
                        displayedBillingFields,
                        fieldIsDisplayed,
                        FieldType.BILLING_CITY,
                    );

                    if (isP2P) {
                        // eslint-disable-next-line no-param-reassign
                        fieldIsDisplayed = true;
                    }

                    if (storedPayment) {
                        return false;
                    }

                    return (
                        paymentType !== PaymentType.APPLEPAY &&
                        !billingAddressUseMailing &&
                        fieldIsDisplayed
                    );
                },
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            },
        );
        return billingCityValidation;
    };
    // Country
    const getBillingCountryFieldValidation = () => {
        const billingCountryValidation = Yup.string().when(
            ["Billing_Address_Use_Mailing", "Payment_Type", "Stored_Payment"],
            {
                is: (
                    billingAddressUseMailing,
                    paymentType,
                    storedPayment,
                    fieldIsDisplayed,
                ) => {
                    // eslint-disable-next-line no-param-reassign
                    fieldIsDisplayed = isFieldDisplayed(
                        displayedBillingFields,
                        fieldIsDisplayed,
                        FieldType.BILLING_COUNTRY,
                    );
                    if (storedPayment) {
                        return false;
                    }
                    return (
                        paymentType !== PaymentType.APPLEPAY &&
                        !billingAddressUseMailing &&
                        fieldIsDisplayed
                    );
                },
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            },
        );
        return billingCountryValidation;
    };
    // The validation for state and zip fields should match the validation of a
    // typical Billing Address field for all instances other than when an
    // international address is used as sometimes state and zip fields do not
    // apply to international addresses
    // eslint-disable-next-line no-shadow
    const getBillingStateFieldValidation = (displayedBillingFields) => {
        const billingStateValidation = Yup.string().when(
            [
                "Billing_Address_Use_Mailing",
                "Payment_Type",
                "Billing_Country",
                "Stored_Payment",
            ],
            {
                is: (
                    billingAddressUseMailing,
                    paymentType,
                    billingCountry,
                    storedPayment,
                    fieldIsDisplayed,
                ) => {
                    // eslint-disable-next-line no-param-reassign
                    fieldIsDisplayed = isFieldDisplayed(
                        displayedBillingFields,
                        fieldIsDisplayed,
                        FieldType.BILLING_STATE,
                    );

                    if (isP2P) {
                        // eslint-disable-next-line no-param-reassign
                        fieldIsDisplayed = true;
                    }

                    if (storedPayment) {
                        return false;
                    }

                    return (
                        paymentType !== PaymentType.APPLEPAY &&
                        !billingAddressUseMailing &&
                        billingCountry === "US" &&
                        fieldIsDisplayed
                    );
                },
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            },
        );
        return billingStateValidation;
    };

    // Zip. Not required when billing country is non US.
    const getBillingZipFieldValidation = () => {
        const billingZipValidation = Yup.string().when(
            [
                "Billing_Address_Use_Mailing",
                "Payment_Type",
                "Billing_Country",
                "Stored_Payment",
            ],
            {
                is: (
                    billingAddressUseMailing,
                    paymentType,
                    billingCountry,
                    storedPayment,
                    fieldIsDisplayed,
                ) => {
                    // eslint-disable-next-line no-param-reassign
                    fieldIsDisplayed = isFieldDisplayed(
                        displayedBillingFields,
                        fieldIsDisplayed,
                        FieldType.BILLING_ZIP,
                    );
                    if (storedPayment) {
                        return false;
                    }
                    return (
                        paymentType !== PaymentType.APPLEPAY &&
                        !billingAddressUseMailing &&
                        billingCountry === "US" &&
                        fieldIsDisplayed
                    );
                },
                then: Yup.string()
                    .required(VALIDATION_ERROR_MESSAGE.REQUIRED)
                    .matches(/^[0-9]+$/, VALIDATION_ERROR_MESSAGE.ZIP)
                    .min(5, VALIDATION_ERROR_MESSAGE.ZIP)
                    .max(5, VALIDATION_ERROR_MESSAGE.ZIP),
                otherwise: Yup.string(),
            },
        );
        return billingZipValidation;
    };

    // Individual validation schemas
    const billingAddressSchema = getBillingAddressFieldValidation(
        displayedBillingFields,
    );
    const billingCitySchema = getBillingCityFieldValidation(
        displayedBillingFields,
    );
    const billingStateSchema = getBillingStateFieldValidation(
        displayedBillingFields,
    );
    const billingZipSchema = getBillingZipFieldValidation(
        displayedBillingFields,
    );
    const billingCountrySchema = getBillingCountryFieldValidation(
        displayedBillingFields,
    );

    const getBillingAddressValidation = () => ({
        Billing_Address: billingAddressSchema,
    });

    // The only billing address field that is never required
    const getBillingAddress2Validation = () => ({
        Billing_Address_2: Yup.string(),
    });

    const getBillingCityValidation = () => ({
        Billing_City: billingCitySchema,
    });

    const getBillingStateValidation = () => ({
        Billing_State: billingStateSchema,
    });

    const getBillingZipValidation = () => ({
        Billing_Zip: billingZipSchema,
    });

    const getBillingCountryValidation = () => ({
        Billing_Country: billingCountrySchema,
    });

    return {
        // billing address
        ...getBillingAddressValidation(),
        // billing address 2
        ...getBillingAddress2Validation(),
        // billing city
        ...getBillingCityValidation(),
        // billing state
        ...getBillingStateValidation(),
        // billing country
        ...getBillingCountryValidation(),
        // billing zip
        ...getBillingZipValidation(),
    };
};
// Gift Assist Validations
export const getGiftAssistValidations = () => ({
    Gift_Assist: Yup.bool().required(),
});
// Address_2 Validations
export const getAddress2Validations = () => ({
    Address_2: Yup.string().validateIsNotACreditCardNumberString(),
});
// Email Validations
export const getEmailValidations = () => ({
    Email: Yup.string()
        .required(VALIDATION_ERROR_MESSAGE.REQUIRED)
        .email(VALIDATION_ERROR_MESSAGE.EMAIL_FORMAT),
});

// Password Validations
export const getPasswordValidations = () => {
    const {
        ENUMS: { GlobalAccountAction },
    } = constants;
    return {
        // If a user is attempting to activate an account or reset a password
        // the password is required and the password requirements should be
        // displayed. If a user is logging in a password is required. Else a
        // user is generating a reset password email, in which case a password
        // is not required.
        Password: Yup.string()
            .when("Global_Account_Action", {
                is: (Global_Account_Action) =>
                    Global_Account_Action ===
                        GlobalAccountAction.ACTIVATE_ACCOUNT ||
                    Global_Account_Action ===
                        GlobalAccountAction.RESET_PASSWORD,
                then: Yup.string()
                    .required(VALIDATION_ERROR_MESSAGE.REQUIRED)
                    .validatePasswordString(),
            })
            .when("Global_Account_Action", {
                is: (Global_Account_Action) =>
                    Global_Account_Action === GlobalAccountAction.SIGN_IN,
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            }),
    };
};
// Password_Confirm Validations
export const getConfirmPasswordValidations = () => {
    const {
        ENUMS: { GlobalAccountAction },
    } = constants;
    return {
        Password_Confirm: Yup.string().when("Global_Account_Action", {
            is: (Global_Account_Action) =>
                Global_Account_Action ===
                    GlobalAccountAction.SEND_RESET_PASSWORD_EMAIL ||
                Global_Account_Action === GlobalAccountAction.SIGN_IN,
            then: Yup.string(),
            otherwise: Yup.string()
                .required(VALIDATION_ERROR_MESSAGE.REQUIRED)
                .validateConfirmPasswordString(),
        }),
    };
};
// Account Validations
export const getAccountValidations = () => ({
    // email
    ...getEmailValidations(),
    // password
    ...getPasswordValidations(),
    // confirm password
    ...getConfirmPasswordValidations(),
});
// Donation_Amount Validations
export const getDonationAmountValidations = () => ({
    Donation_Amount: Yup.string().validateDonationAmountString(
        "Please enter a valid donation amount.",
    ),
});
// --------------------------------------------------------------------------
// MARK: Store specific validation Only Present if item is added the cart
// --------------------------------------------------------------------------
export const getShippingSameAsMailingValidations = (
    validateShippingDetails,
) => {
    if (validateShippingDetails) {
        return {
            Shipping_Address_Use_Mailing: Yup.bool().required(),
        };
    }
    return {};
};

export const getShippingAddressValidations = (
    validateShippingDetails,
    shippingPhoneRequired,
) => {
    const getShippingFieldValidation = () => {
        const shippingFieldValidation = Yup.string().when(
            ["Shipping_Address_Use_Mailing"],
            {
                is: (shippingingAddressUseMailing) =>
                    !shippingingAddressUseMailing,
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            },
        );

        return shippingFieldValidation;
    };

    const getShippingPhoneFieldValidation = () =>
        Yup.string().test(
            "shipping phone test",
            "Please enter a valid phone number.",
            (phoneValue, context) => {
                const currentValues = context?.from[0].value;
                const { Shipping_Address_Use_Mailing, Shipping_Country } =
                    currentValues;
                const phoneValidationBasedOnCountry =
                    Shipping_Country === "US"
                        ? validatePhone
                        : validateInternationalPhone;
                // do not validate phone field when using mailing as shipping
                if (Shipping_Address_Use_Mailing) {
                    return true;
                }
                // required logic
                if (shippingPhoneRequired) {
                    if (!phoneValue) {
                        return false;
                    }
                    return phoneValidationBasedOnCountry(phoneValue);
                }

                // not required
                if (!phoneValue) {
                    return true;
                }
                return phoneValidationBasedOnCountry(phoneValue);
            },
        );

    // The validation for state and zip fields should match the validation of a
    // typical Shipping Address field for all instances other than when an
    // international address is used as sometimes state and zip fields do not
    // apply to international addresses
    const getShippingZipFieldValidation = () => {
        const shippingFieldValidation = Yup.string().when(
            ["Shipping_Address_Use_Mailing", "Shipping_Country"],
            {
                is: (shippingAddressUseMailing, shippingCountry) => {
                    if (
                        !shippingAddressUseMailing &&
                        shippingCountry === "US"
                    ) {
                        return true;
                    }
                    return false;
                },
                then: Yup.string()
                    .required(VALIDATION_ERROR_MESSAGE.REQUIRED)
                    .matches(/^[0-9]+$/, VALIDATION_ERROR_MESSAGE.ZIP)
                    .min(5, VALIDATION_ERROR_MESSAGE.ZIP)
                    .max(5, VALIDATION_ERROR_MESSAGE.ZIP),
                otherwise: Yup.string(),
            },
        );
        return shippingFieldValidation;
    };

    // The validation for state and zip fields should match the validation of a
    // typical Shipping Address field for all instances other than when an
    // international address is used as sometimes state and zip fields do not
    // apply to international addresses
    const getShippingStateFieldValidation = () => {
        const shippingFieldValidation = Yup.string().when(
            ["Shipping_Address_Use_Mailing", "Shipping_Country"],
            {
                is: (shippingAddressUseMailing, shippingCountry) => {
                    if (
                        !shippingAddressUseMailing &&
                        shippingCountry === "US"
                    ) {
                        return true;
                    }
                    return false;
                },
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            },
        );
        return shippingFieldValidation;
    };

    // Individual validation schemas
    const shippingFirstNameSchema = getShippingFieldValidation();
    const shippingLastNameSchema = getShippingFieldValidation();
    const shippingAddressSchema = getShippingFieldValidation();
    const shippingCitySchema = getShippingFieldValidation();
    const shippingStateSchema = getShippingStateFieldValidation();
    const shippingZipSchema = getShippingZipFieldValidation();
    const shippingCountrySchema = getShippingFieldValidation();
    const shippingPhoneSchema = getShippingPhoneFieldValidation();

    const getShippingFirstNameValidations = () => ({
        Shipping_First_Name: shippingFirstNameSchema,
    });

    const getShippingLastNameValidations = () => ({
        Shipping_Last_Name: shippingLastNameSchema,
    });

    const getShippingAddressValidation = () => ({
        Shipping_Address: shippingAddressSchema,
    });

    // The only shipping address field that is never required
    const getShippingAddress2Validation = () => ({
        Shipping_Address_2: Yup.string(),
    });

    const getShippingCityValidation = () => ({
        Shipping_City: shippingCitySchema,
    });

    const getShippingStateValidation = () => ({
        Shipping_State: shippingStateSchema,
    });

    const getShippingZipValidation = () => ({
        Shipping_Zip: shippingZipSchema,
    });

    const getShippingCountryValidation = () => ({
        Shipping_Country: shippingCountrySchema,
    });

    const getShippingPhoneValidation = () => ({
        Shipping_Phone: shippingPhoneSchema,
    });

    if (validateShippingDetails) {
        return {
            // Shipping first name
            ...getShippingFirstNameValidations(),
            // Shipping last name
            ...getShippingLastNameValidations(),
            // Shipping address
            ...getShippingAddressValidation(),
            // Shipping address 2
            ...getShippingAddress2Validation(),
            // Shipping city
            ...getShippingCityValidation(),
            // Shipping state
            ...getShippingStateValidation(),
            // Shipping country
            ...getShippingCountryValidation(),
            // Shipping zip
            ...getShippingZipValidation(),
            // Shipping Phone
            ...getShippingPhoneValidation(),
        };
    }
    return {};
};

export const getShippingMethodValidations = (validateShippingMethods) => {
    if (validateShippingMethods) {
        return {
            Shipping_Method: Yup.string().required(
                VALIDATION_ERROR_MESSAGE.REQUIRED,
            ),
        };
    }
    return {};
};
// -------------------------------------------------------------------------
// NOTE: The values returned by the Matching Gifts APIs are unpredictable.
//       Because of this there is no guarantee that the API will give you
//       back data in a particular shape that can be verified reliably. As
//       a result of this Yup.mixed() is used with the unpredictable
//       matching gift fields in order to ensure that the validation for
//       these fields is as flexible as possible.
// -------------------------------------------------------------------------
// Matching Gift Validations
export const getMatchingGiftValidations = (matchingRatio, subtotal) => {
    // Automated matching gift validations
    const getAutomatedMatchingGiftValidations = () => {
        // The only visible field
        // Work email input
        const getWorkEmailValidation = () => ({
            Work_Email: Yup.string().email(
                VALIDATION_ERROR_MESSAGE.EMAIL_FORMAT,
            ),
        });

        // Hidden fields
        // Because the exact combination of the values that are filled in and
        // what those values are is too difficult to predict so to ensure that
        // the validation doesn't fail all of these fields are optional
        const getMatchingCompanyIdValidation = () => ({
            Matching_Company_ID: Yup.mixed(),
        });

        const getMatchingCompanyNameValidation = () => ({
            Matching_Company_Name: Yup.mixed(),
        });

        const getMatchingContactAddressLine1Validation = () => ({
            Matching_Contact_Address_Line_1: Yup.mixed(),
        });

        const getMatchingContactAddressLine2Validation = () => ({
            Matching_Contact_Address_Line_2: Yup.mixed(),
        });

        const getMatchingContactCityValidation = () => ({
            Matching_Contact_City: Yup.mixed(),
        });

        const getMatchingContactEmailValidation = () => ({
            Matching_Contact_Email: Yup.mixed(),
        });

        const getMatchingContactPhoneValidation = () => ({
            Matching_Contact_Phone: Yup.mixed(),
        });

        const getMatchingContactStateValidation = () => ({
            Matching_Contact_State: Yup.mixed(),
        });

        const getMatchingContactZipValidation = () => ({
            Matching_Contact_Zip: Yup.mixed(),
        });

        const getMatchingDonorFoundValidation = () => ({
            Matching_Donor_Found: Yup.mixed(),
        });

        const getMatchingDonorVerifiedValidation = () => ({
            Matching_Donor_Verified: Yup.mixed(),
        });

        const getMatchingParentRawIdValidation = () => ({
            Matching_Parent_Raw_ID: Yup.mixed(),
        });

        const getMatchingGiftValidation = () => ({
            Matching_Gift: Yup.object().shape({
                Matching_Donation_ID: Yup.mixed(),
                Company_ID: Yup.mixed(),
                Company_Name: Yup.mixed(),
            }),
        });

        return {
            // work email
            ...getWorkEmailValidation(),
            // matching company id
            ...getMatchingCompanyIdValidation(),
            // matching company name
            ...getMatchingCompanyNameValidation(),
            // matching contact address line 1
            ...getMatchingContactAddressLine1Validation(),
            // matching contact address line 2
            ...getMatchingContactAddressLine2Validation(),
            // matching contact city
            ...getMatchingContactCityValidation(),
            // matching contact email
            ...getMatchingContactEmailValidation(),
            // matching contact phone
            ...getMatchingContactPhoneValidation(),
            // matching contact state
            ...getMatchingContactStateValidation(),
            // matching contact zip
            ...getMatchingContactZipValidation(),
            // matching donor found
            ...getMatchingDonorFoundValidation(),
            // matching donor verified
            ...getMatchingDonorVerifiedValidation(),
            // matching parent raw id
            ...getMatchingParentRawIdValidation(),
            // matching gift
            ...getMatchingGiftValidation(),
        };
    };

    // Manual matching gift validations
    const getManualMatchingGiftValidations = () => {
        // This gift can be matched checkbox. Not required even though its a
        // boolean as this field isn't always present in the form
        const getMatchGiftValidation = () => ({
            Match_Gift: Yup.bool(),
        });

        // Matching Company field
        const getEmployerValidation = () => ({
            Employer: Yup.string().when(["Match_Gift"], {
                is: evaluateIfFieldValueIsTrue,
                then: Yup.string().required(VALIDATION_ERROR_MESSAGE.REQUIRED),
                otherwise: Yup.string(),
            }),
        });

        // Matching Amount field
        const getMatchingGiftAmountValidation = () => ({
            Matching_Gift_Amount: Yup.string().when(["Match_Gift"], {
                is: evaluateIfFieldValueIsTrue,
                then: Yup.string()
                    .required(VALIDATION_ERROR_MESSAGE.REQUIRED)
                    .validateMatchingGiftAmountString(matchingRatio, subtotal),
                otherwise: Yup.string(),
            }),
        });

        return {
            // match gift
            ...getMatchGiftValidation(),
            // employer
            ...getEmployerValidation(),
            // matching gift amount
            ...getMatchingGiftAmountValidation(),
        };
    };

    return {
        // manual matching gift fields
        ...getManualMatchingGiftValidations(),
        // automated matching gift fields
        ...getAutomatedMatchingGiftValidations(),
    };
};

export const getCaptchaFieldValidation = (shouldCaptchaBeRequired) => {
    if (shouldCaptchaBeRequired) {
        return {
            G_Recaptcha_Response: Yup.string().required("Captcha is required."),
        };
    }
    return {};
};

// -------------------------------------------------------------------------
// MARK: Validation after form values have been updated
// -------------------------------------------------------------------------
/**
 * @public
 * @function validateFormAfterFormValuesHaveUpdated
 * @param {Function} validateForm Formik's validateForm function.
 * @param {Function} handleValidationSuccess Callback to run on validation success.
 * @param {Function} handleValidationError Callback to run on validation failure.
 * @description Runs the form validation after a slight delay. This function is
 * intended to be used when validating the form after imperatively setting the
 * values in a Formik form via setFieldValue() or setValues(). The reason for
 * this is that both of these functions rely on setState() internally and calls
 * to setState() update form values asynchronously. This means that if form
 * validation is run immediately, it will generate an errors object that does
 * not represent the form value that has just been set. However, if we use
 * setTimeout() to push the call to validateForm() until the call stack is
 * clear, this ensures that the form values have been updated and the errors
 * object will reflect the form values that were just set.
 * @returns {undefined}
 */
export const validateFormAfterFormValuesHaveUpdated = (
    validateForm,
    handleValidationSuccess,
    handleValidationError,
) => {
    setTimeout(() => {
        validateForm()
            .then((validationErrors) => {
                if (handleValidationSuccess) {
                    handleValidationSuccess(validationErrors);
                }
            })
            .catch((error) => {
                if (handleValidationError) {
                    handleValidationError(error);
                }
            });
    }, 0);
};

// -------------------------------------------------------------------------
// MARK: Redux total and subtotal values.
// Since Field Validation for system fields we tightly knit with
// Year Round Form's Donation_Amount formik value, Event Redesign + Donation Form
// needed to have separate validations. In respective `fieldsSlice.js`, work is done
// on fields state data to be able to get their validation using these functions.
// This means that the required value passed on the field does the amt req eval
// against subtotal before it reaches this function.
// -------------------------------------------------------------------------

export const getFieldIsRequired = ({ required, amtReq = 0, total }) => {
    const passesAmtReq = amtReq === 0 || total >= amtReq;
    if (required && passesAmtReq) {
        return true;
    }
    return false;
};

export const getFieldValidation = (
    field,
    total,
    validation,
    baseValidation = null,
) => {
    if (field.exists === false) return;
    const { required, amtReq } = field;
    const fieldIsRequired = getFieldIsRequired({ required, amtReq, total });
    if (fieldIsRequired) {
        // eslint-disable-next-line consistent-return
        return validation;
    }
    if (baseValidation !== null) {
        // eslint-disable-next-line consistent-return
        return baseValidation;
    }
};

export const getBillingNameValidation = (billingNameFieldData) => {
    const {
        ENUMS: {
            PaymentType: { CREDITCARD, ECHECK },
        },
    } = constants;

    const validation = {
        Billing_Name: Yup.string().when("Payment_Type", {
            is: (paymentType) =>
                paymentType === CREDITCARD || paymentType === ECHECK,
            then: Yup.string().required("This field is required"),
            otherwise: Yup.string(),
        }),
    };
    // Billing_Name field can not be conditionally required based on total
    return getFieldValidation(billingNameFieldData, 0, validation);
};

export const getCustomFieldBaseAndRequiredValidation = (currentField) => {
    const {
        ENUMS: { FieldType },
    } = constants;
    const { type, required, display_text } = currentField;
    const requiredMessage = `${display_text} is ${VALIDATION_ERROR_MESSAGE.REQUIRED}`;
    let baseValidation;
    let requiredValidation;
    switch (Number(type)) {
        case FieldType.SHORT_TEXT:
        case FieldType.PARAGRAPH_TEXT:
        case FieldType.HIDDEN_FIELD:
        case FieldType.DATE:
        case FieldType.NUMBER:
        case FieldType.DROPDOWN:
        case FieldType.RADIO_BUTTON:
            baseValidation = Yup.string();
            if (required) {
                requiredValidation = baseValidation.required(requiredMessage);
            }
            break;
        case FieldType.WEBSITE:
            baseValidation = Yup.string().validateWebsiteString();
            if (required) {
                requiredValidation = baseValidation.required(requiredMessage);
            }
            break;
        case FieldType.PHONE_NUMBER:
            baseValidation = Yup.string().validatePhoneString();
            if (required) {
                requiredValidation = baseValidation.required(requiredMessage);
            }
            break;
        case FieldType.EMAIL_ADDRESS:
            baseValidation = Yup.string().email("This must be a valid email.");
            if (required) {
                requiredValidation = baseValidation.required(requiredMessage);
            }
            break;
        case FieldType.MULTIPLE_SELECTION:
            baseValidation = Yup.array().of(Yup.string());
            if (required) {
                // empty arrays are truthy, use .min
                requiredValidation = baseValidation.min(1, requiredMessage);
            }
            break;
        case FieldType.CHECKBOX:
            baseValidation = Yup.boolean();
            if (required) {
                requiredValidation = baseValidation.oneOf(
                    [true],
                    requiredMessage,
                );
            }
            break;
        default:
            break;
    }
    return {
        baseValidation,
        requiredValidation,
    };
};

export const getCustomFieldValidation = ({ visibleCustomFields, total }) => {
    const validationObject = visibleCustomFields.reduce(
        (totalValidationObject, currentField) => {
            const { required, id, amtReq } = currentField;
            const isRequired =
                (required && amtReq === 0) || (required && total > amtReq);

            const { baseValidation, requiredValidation } =
                getCustomFieldBaseAndRequiredValidation(currentField);

            if (isRequired) {
                return {
                    ...totalValidationObject,
                    [id]: requiredValidation,
                };
            }
            return {
                ...totalValidationObject,
                [id]: baseValidation,
            };
            // end reduce
        },
        {},
    );
    return validationObject;
};
export const getDedicationFieldValidation = ({
    displayableDedicationFields,
    total,
}) => {
    const validationObject = displayableDedicationFields.reduce(
        (totalValidationObject, currentField) => {
            const { required, fieldNamePath, amtReq } = currentField;
            const isRequired =
                (required && amtReq === 0) || (required && total > amtReq);

            const { baseValidation, requiredValidation } =
                getCustomFieldBaseAndRequiredValidation(currentField);

            if (isRequired) {
                return {
                    ...totalValidationObject,
                    [fieldNamePath]: requiredValidation,
                };
            }
            return {
                ...totalValidationObject,
                [fieldNamePath]: baseValidation,
            };
            // end reduce
        },
        {},
    );
    return validationObject;
};

const getDedicationFieldValidations = ({
    dedicationFields = [],
    currentDisplay,
}) => {
    // Gets the complete validation for a dedication field accounting for
    // required settings
    const displayableDedicationFields = dedicationFields.filter(
        (dedicationField) => {
            const dedicationFieldIsDisplayable = isDisplayable(
                dedicationField,
                currentDisplay,
            );
            return dedicationFieldIsDisplayable;
        },
    );
    const dedicationFieldValidations = getDedicationFieldValidation({
        displayableDedicationFields,
        // Total is being passed as 0 because Dedication Fields do not have
        // 'amount required' logic.
        total: 0,
    });
    return dedicationFieldValidations;
};

// Dedication
export const getDedicationValidations = ({
    currentDisplay,
    dedicationSettings,
    Has_Dedication,
}) => {
    const dedicationFields = dedicationSettings?.fields || [];
    const dedicationValidation = {
        Has_Dedication: Yup.bool(),
        Dedication_Type: Yup.string().when("Has_Dedication", {
            is: true,
            then: Yup.string().required("Dedication type is required."),
            otherwise: Yup.string(),
        }),
        Dedication_Name: Yup.string().when("Has_Dedication", {
            is: true,
            then: Yup.string().required("Dedication name is required."),
            otherwise: Yup.string(),
        }),
        Dedication_Email: Yup.string().when(
            ["Has_Dedication", "Dedication_Message"],
            {
                is: (hasDedication, dedicationMessage) =>
                    hasDedication && dedicationMessage,
                then: Yup.string()
                    .email(VALIDATION_ERROR_MESSAGE.EMAIL_FORMAT)
                    .required(
                        "Oops! Please enter an email address where we can send this message.",
                    ),
                otherwise: Yup.string().email(
                    VALIDATION_ERROR_MESSAGE.EMAIL_FORMAT,
                ),
            },
        ),

        ...(Has_Dedication && {
            ...getDedicationFieldValidations({
                dedicationFields,
                currentDisplay,
            }),
        }),
    };
    return dedicationValidation;
};

// -------------------------------------------------------------------------
// MARK: Export Custom Yup instance
// -------------------------------------------------------------------------
export { Yup };
