import { sortArrayAlphabeticallyByKey } from "@qgiv/core-js";

export const testValuesParticipant = [
    {
        id: 102241,
        team: 86149,
        form: 11700,
        teamCaptain: 1,
        activityRole: 1,
        avatar: "",
        fundraisingGoal: 50,
        totalRaised: 10,
        firstName: "Donald",
        lastName: "Cundy",
        teamId: 86149,
        teamName: "Banana Pancakes",
        blocked: 0,
    },
    {
        id: 102244,
        team: 0,
        form: 11700,
        teamCaptain: 0,
        activityRole: 1,
        avatar: "",
        fundraisingGoal: 50,
        totalRaised: 10,
        firstName: "Gene",
        lastName: "Wilder",
        teamId: 0,
        teamName: "",
        blocked: 0,
    },
];

export const testValuesTeam = [
    {
        id: 86149,
        teamName: "Banana Pancakes",
        captains: [{ firstName: "John", lastName: "Doe" }],
        avatar: "",
        form: 11700,
        fundraisingGoal: 100,
        totalRaised: 10,
        firstName: "John",
        lastName: "Doe",
        classification: 57022,
        classificationName: "New York",
    },
    {
        id: 86140,
        captains: [],
        teamName: "Banana Pancakes No Captain",
        avatar: "",
        form: 11700,
        fundraisingGoal: 100,
        totalRaised: 10,
        firstName: "",
        lastName: "",
        classification: 57022,
        classificationName: "New York",
    },
];

export const testValuesClassification = [
    {
        id: 57022,
        captains: [],
        classificationName: "New York",
        avatar: "",
        fundraisingGoal: 500,
        totalRaised: 10,
    },
    {
        id: 57023,
        classificationName: "New York",
        avatar: "",
        fundraisingGoal: 500,
        totalRaised: 10,
        captains: [{ firstName: "Jane", lastName: "Doe" }],
    },
    {
        id: 57024,
        classificationName: "New York",
        avatar: "",
        fundraisingGoal: 500,
        totalRaised: 10,
        captains: [
            { firstName: "John", lastName: "Doe" },
            { firstName: "Jane", lastName: "Doe" },
        ],
    },
];

export const expectedResultsClassification = [
    {
        id: 57022,
        captains: [],
        classificationName: "New York",
        title: "New York",
        avatar: "",
        fundraisingGoal: 500,
        totalRaised: 10,
        type: "classification",
        key: "classification-57022",
        hasCaptain: false,
        iconGlyph: "sitemap-duotone",
    },
    {
        id: 57023,
        title: "New York",
        avatar: "",
        fundraisingGoal: 500,
        totalRaised: 10,
        type: "classification",
        key: "classification-57023",
        hasCaptain: true,
        captainName: "Jane Doe",
        iconGlyph: "sitemap-duotone",
        captains: [{ firstName: "Jane", lastName: "Doe" }],
        classificationName: "New York",
    },
    {
        id: 57024,
        title: "New York",
        avatar: "",
        fundraisingGoal: 500,
        totalRaised: 10,
        type: "classification",
        key: "classification-57024",
        hasCaptain: true,
        captainName: "John Doe, Jane Doe",
        iconGlyph: "sitemap-duotone",
        captains: [
            { firstName: "John", lastName: "Doe" },
            { firstName: "Jane", lastName: "Doe" },
        ],
        classificationName: "New York",
    },
];

export const expectedResultsTeam = [
    {
        id: 86149,
        avatar: "",
        form: 11700,
        fundraisingGoal: 100,
        totalRaised: 10,
        firstName: "John",
        lastName: "Doe",
        classification: 57022,
        classificationName: "New York",
        type: "team",
        key: "team-86149",
        hasCaptain: true,
        captainName: "John Doe",
        iconGlyph: "users-duotone",
        teamName: "Banana Pancakes",
        title: "Banana Pancakes",
        captains: [{ firstName: "John", lastName: "Doe" }],
    },
    {
        id: 86140,
        avatar: "",
        form: 11700,
        fundraisingGoal: 100,
        totalRaised: 10,
        firstName: "",
        lastName: "",
        classification: 57022,
        classificationName: "New York",
        type: "team",
        key: "team-86140",
        hasCaptain: false,
        iconGlyph: "users-duotone",
        captains: [],
        teamName: "Banana Pancakes No Captain",
        title: "Banana Pancakes No Captain",
    },
];

export const expectedResultsParticipant = [
    {
        id: 102241,
        team: 86149,
        form: 11700,
        teamCaptain: 1,
        activityRole: 1,
        avatar: "",
        fundraisingGoal: 50,
        totalRaised: 10,
        firstName: "Donald",
        lastName: "Cundy",
        teamId: 86149,
        teamName: "Banana Pancakes",
        blocked: 0,
        type: "participant",
        title: "Donald Cundy",
        key: "participant-102241",
        hasTeam: true,
        iconGlyph: "user-solid",
    },
    {
        id: 102244,
        team: 0,
        form: 11700,
        teamCaptain: 0,
        activityRole: 1,
        avatar: "",
        fundraisingGoal: 50,
        totalRaised: 10,
        firstName: "Gene",
        lastName: "Wilder",
        teamId: 0,
        teamName: "",
        blocked: 0,
        type: "participant",
        title: "Gene Wilder",
        key: "participant-102244",
        hasTeam: false,
        iconGlyph: "user-solid",
    },
];

const expectedReturnFromAllData = [
    ...expectedResultsTeam,
    ...expectedResultsParticipant,
    ...expectedResultsClassification,
];

export const expectedSortedReturnFromAllData = sortArrayAlphabeticallyByKey(
    expectedReturnFromAllData,
    "title",
);
