import { constants } from "@qgiv/core-js";

const {
    ENUMS: { FormTemplate },
} = constants;

/**
 * @function getFormSpecificPathForSettingCookie
 * @description Determines the specific path for setting a cookie
 * @param {object} params
 * @param {number} params.embedId embed id from config, forced to be 0 for local embed
 * @param {object} params.eventSettings will be empty object when year round form
 * @param {object} params.formSettings
 * @param {string} params.path path value from config
 * @returns {string} The specific path for setting the cookie.
 */
const getFormSpecificPathForSettingCookie = ({
    embedId = 0,
    eventSettings = {},
    formSettings = {},
    path = "",
}) => {
    const { template = 0 } = formSettings;
    const { alias = "" } = eventSettings;
    const isEmbeddedExternally = embedId > 0;
    const isStandardYearRoundForm = template === FormTemplate.NEW_STANDARD_FORM;
    const isP2PForm = template === FormTemplate.P2P_STANDARD;

    // we don't want to do anything specific with setting the cookie path on external sites
    if (isEmbeddedExternally) {
        return "/";
    }
    if (isStandardYearRoundForm) {
        return `/for/${path}`;
    }
    if (isP2PForm) {
        return `/event/${alias}`;
    }
    // default case of empty string will cause cookie to not be set
    // in elements.development.js
    return "";
};

export default getFormSpecificPathForSettingCookie;
