import React, { useEffect } from "react";
import {
    getSkipAdditionalDetailsPageValue,
    sortSystemAndCustomFieldsFromValues,
} from "@qgiv/donation-form";
import { useDispatch } from "react-redux";
import {
    updateDonationMatching,
    updateDonationDedication,
    updateDonationCustomFields,
    updateDonorDetails,
} from "../../../redux/slices/donationDetailsSlice";
import { useDonorDetailsPageData } from "../../PageDataHooks";
import {
    getInitialTouched,
    getInitialValues,
    getValidationSchema,
} from "./formik";
import {
    setCurrentPageNextPage,
    updateSkipAdditionalDetailsPage,
} from "../../../redux/slices/appSettingsSlice";
import { setAbandonedGiftDataFirstName } from "../../../redux/slices/abandonedGiftSlice";
import DetailsPage from "./DetailsPage";

const ConnectedDetailsPage = () => {
    // -------------------------------------------------------------------------
    // NOTE: Single Step form uses a different component set than these Connected Pages.
    // It does share the custom getPageData hooks, but if you make changes in the Connected Pages
    // make sure to make the same changes in the ConnectedSinglePage.js file.
    // -------------------------------------------------------------------------
    const donorDetailsPageData = useDonorDetailsPageData();

    const dispatch = useDispatch();

    const {
        additionalDetailsPageCustomFields,
        allFieldsAndGroups,
        conditionalLogicOptions,
        displayCaptcha,
        documentTitle,
        formikSettings,
        hasActiveFulfillment,
        hasDisplayableDedicationOnAdditionalDetailsPage,
        hasDisplayableDedicationOnThisPage,
        hasDisplayableMatchingGiftOnAdditionalDetailsPage,
        hasDisplayableMatchingGiftOnThisPage,
    } = donorDetailsPageData;

    const onSubmit = (values, actions) => {
        setTimeout(() => {
            // Store values in redux store via dispatching an action

            const { systemFieldValues, customFieldValues } =
                sortSystemAndCustomFieldsFromValues(values);

            dispatch(updateDonorDetails(systemFieldValues));
            dispatch(updateDonationCustomFields(customFieldValues));

            if (hasDisplayableMatchingGiftOnThisPage) {
                dispatch(updateDonationMatching(systemFieldValues));
            }
            if (hasDisplayableDedicationOnThisPage) {
                dispatch(updateDonationDedication(systemFieldValues));
            }

            // -------------------------------------------------------------------------
            // TODO: When pages can be moved into different orders, the newSkipAdditionalDetailsPageValue
            // will probably have to be re-evaluated on every page submit (expect Additional Details page)
            // -------------------------------------------------------------------------
            const newSkipAdditionalDetailsPageValue =
                getSkipAdditionalDetailsPageValue({
                    additionalDetailsPageCustomFields,
                    allFieldsAndGroups,
                    conditionalLogicOptions,
                    hasDisplayableDedicationOnAdditionalDetailsPage,
                    hasDisplayableMatchingGiftOnAdditionalDetailsPage,
                    values,
                    hasActiveFulfillment,
                });
            dispatch(
                updateSkipAdditionalDetailsPage(
                    newSkipAdditionalDetailsPageValue,
                ),
            );
            // wait to make sure redux is updated
            setTimeout(() => {
                actions.setSubmitting(false);
                dispatch(setCurrentPageNextPage());
            }, 250);
        }, 350);
    };

    const {
        initialTouchedSettings,
        initialValuesSettings,
        validationSettings,
    } = formikSettings;

    const formikProps = {
        initialTouched: getInitialTouched(initialTouchedSettings),
        initialValues: getInitialValues(initialValuesSettings),
        validationSchema: getValidationSchema(validationSettings),
        onSubmit,
    };

    // Donor account can populate First_Name field without field onChange
    useEffect(() => {
        const initialFirstName = formikProps?.initialValues?.First_Name || "";
        if (initialFirstName) {
            dispatch(setAbandonedGiftDataFirstName(initialFirstName));
        }
        // we only want this useEffect to run on details page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const detailsPageProps = {
        displayCaptcha,
        documentTitle,
        formikProps,
    };
    return <DetailsPage {...detailsPageProps} />;
};

export default ConnectedDetailsPage;
