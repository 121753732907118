import React from "react";
import { Provider } from "react-redux";
import store from "../redux";
import {
    ConnectedFormInjectionBody,
    ConnectedFormInjectionHead,
    ConnectedSkinInjection,
} from "./common/FormInjection";

const Injections = () => (
    <Provider store={store}>
        <ConnectedFormInjectionHead />
        <ConnectedFormInjectionBody />
        <ConnectedSkinInjection />
    </Provider>
);

export default Injections;
